import { useState } from 'react';
import { debounce } from 'lodash';
import { LayoutGrid, List, Plus, Search } from 'lucide-react';
import { BaseLoader, Button, NoDataFoundScreen, SearchInput, TourButton } from '@common/components';
import {
  ConnectDatasource,
  CreateDashboardDialog,
  DashboardGridView,
  DashboardTabularView,
  useDashboardsListing,
  useCreateDashboard,
  EmptyDashboard,
} from '@pages/dashboard';
import { useManageOrganization } from '@pages/manageOrganization';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  COLORS,
  DASHBOARD_VIEW_TYPE,
  ROLES,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  ORGANIZATION_ID_BY_ONBOARDING,
  CREATE_DASHBOARD_BUTTON_ID,
} from '@constants';
const DEBOUNCE_VALUE = 600;

export function DashboardListing() {
  const { manageOrganization, manageOrganizationFetching } = useManageOrganization();
  const {
    dashboardsListing,
    dashboardsListingFetching,
    dashboardPage,
    setDashboardPage,
    setDashboardId,
    selectedDashboard,
    setSelectedDashboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    handleDeleteDashboard,
    handleDuplicateDashboard,
    setDeleteDashboardLoading,
    viewType,
    setViewType,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
    setSearch,
  } = useDashboardsListing();

  const [createDashboard, setCreateDashboard] = useState(false);
  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const { createDashboardTour, setIsTourActive, isTourActive } = useCreateDashboard(createDashboard);

  removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
  removeLocalStorageItem(ORGANIZATION_ID_BY_ONBOARDING);

  const isMember = manageOrganization?.data?.userRole === ROLES.MEMBER;

  const handleCreateDashboard = () => setCreateDashboard(!createDashboard);

  const handleSearchChange = debounce(e => setSearch(e.target.value), DEBOUNCE_VALUE);

  const noDashboards = dashboardsListing?.data?.results?.length === 0;
  const isFirstVisit = !manageOrganization?.data?.haveDashboards && !manageOrganizationFetching;

  return (
    <div className='h-full p-8'>
      {noDashboards && manageOrganization?.data?.haveDataSources === 0 && !isMember ? (
        <ConnectDatasource />
      ) : isFirstVisit ? (
        <div className='h-[calc(100vh-256px)] min-h-[224px] overflow-y-auto scrollbar-none'>
          <ShowFirstVisitScreen isMember={isMember} handleCreateDashboard={handleCreateDashboard} />
        </div>
      ) : (
        <>
          {manageOrganizationFetching ? (
            <BaseLoader />
          ) : (
            <div className='rounded-xl border border-secondaryStrokeButton bg-white'>
              <div className='flex h-[4.75rem] w-full items-center justify-between border-b border-secondaryStrokeButton px-6 py-4'>
                <h1 className='text-lg font-semibold leading-[1.625rem] text-mainText'>All Dashboards</h1>
                <div className='flex items-center gap-4'>
                  <SearchInput
                    placeholder='Search'
                    onChange={handleSearchChange}
                    parentClassName='w-[16.25rem]'
                    size='lg'
                    className='pl-11'
                    leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                  />
                  <div className='ant-tabs-nav flex gap-4'>
                    <div className='ant-tabs-nav-list flex items-center'>
                      <div
                        onClick={() => {
                          setViewType('grid');
                          setLocalStorageItem('dashboardViewType', 'grid');
                        }}
                        className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.GRID ? 'ant-tabs-tab-active-left' : ''}`}
                      >
                        <div className='ant-tabs-tab-btn'>
                          <LayoutGrid
                            className='h-5 w-5'
                            color={viewType === DASHBOARD_VIEW_TYPE.GRID ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setViewType('table');
                          setLocalStorageItem('dashboardViewType', 'table');
                        }}
                        className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.TABLE ? 'ant-tabs-tab-active-right' : ''}`}
                      >
                        <div className='ant-tabs-tab-btn'>
                          <List
                            className='h-5 w-5'
                            color={viewType === DASHBOARD_VIEW_TYPE.TABLE ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isMember && (
                    <Button
                      id={CREATE_DASHBOARD_BUTTON_ID}
                      onClick={handleCreateDashboard}
                      disabled={manageOrganization?.data?.haveDataSources === 0}
                      size='lg'
                      className='w-[8.75rem]'
                    >
                      <Plus className='h-5 w-5' color='white' />
                      Create New
                    </Button>
                  )}
                </div>
              </div>
              <div>
                {viewType === DASHBOARD_VIEW_TYPE.TABLE && (
                  <DashboardTabularView
                    noDashboards={noDashboards}
                    dashboardsListing={dashboardsListing}
                    dashboardsListingFetching={dashboardsListingFetching}
                    setDashboardId={setDashboardId}
                    selectedDashboard={selectedDashboard}
                    setSelectedDashboard={setSelectedDashboard}
                    showDeleteDialog={showDeleteDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                    deleteDashboardLoading={deleteDashboardLoading}
                    handleDeleteDashboard={handleDeleteDashboard}
                    handleDuplicateDashboard={handleDuplicateDashboard}
                    isMember={isMember}
                    setDeleteDashboardLoading={setDeleteDashboardLoading}
                    viewType={viewType}
                    page={dashboardPage}
                    setPage={setDashboardPage}
                    data={dashboardsListing}
                    totalItems={totalPagesPerItem}
                    setTotalItems={setTotalPagesPerItem}
                    paginationPage={paginationPage}
                    setPaginationPage={setPaginationPage}
                  />
                )}
                {viewType === DASHBOARD_VIEW_TYPE.GRID && (
                  <DashboardGridView
                    dashboardsListing={dashboardsListing}
                    dashboardsListingFetching={dashboardsListingFetching}
                    setDashboardId={setDashboardId}
                    selectedDashboard={selectedDashboard}
                    setSelectedDashboard={setSelectedDashboard}
                    showDeleteDialog={showDeleteDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                    deleteDashboardLoading={deleteDashboardLoading}
                    handleDeleteDashboard={handleDeleteDashboard}
                    handleDuplicateDashboard={handleDuplicateDashboard}
                    isMember={isMember}
                    setDeleteDashboardLoading={setDeleteDashboardLoading}
                    viewType={viewType}
                    page={dashboardPage}
                    setPage={setDashboardPage}
                    data={dashboardsListing}
                    totalItems={totalPagesPerItem}
                    setTotalItems={setTotalPagesPerItem}
                    paginationPage={paginationPage}
                    setPaginationPage={setPaginationPage}
                    noDashboards={noDashboards}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
      {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
        <div className='mt-7 flex items-end sm:mt-0'>
          <CreateDashboardDialog
            open={createDashboard}
            onClose={handleCreateDashboard}
            isTourActive={isTourActive}
            createDashboardTour={createDashboardTour}
          />
        </div>
      )}
      {manageOrganization?.data?.haveDataSources !== 0 &&
        userOrganizationRole !== ROLES.MEMBER &&
        !manageOrganizationFetching && (
        <TourButton
          Label='“Create Dashboard”'
          onClick={() => {
            createDashboardTour().drive();
            setIsTourActive(true);
          }}
        />
      )}
    </div>
  );
}

const ShowFirstVisitScreen = ({ isMember, handleCreateDashboard }) => {
  return <>{!isMember ? <EmptyDashboard onClick={handleCreateDashboard} /> : <NoDataFoundScreen />}</>;
};
