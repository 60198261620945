import { debounce } from 'lodash';
import { Plus, Search } from 'lucide-react';
import { Button, SearchInput } from '@common/components';
import { DomainInstruction } from '@pages/whiteLabel';
import { COLORS } from '@constants';

const DEBOUNCE_VALUE = 600;

const DomainsTabHeader = ({ setShowAddDomain, setSearch }) => {
  const handleSearchChange = debounce(e => setSearch(e.target.value), DEBOUNCE_VALUE);

  return (
    <div className='flex flex-row justify-between border-b border-secondaryStrokeButton px-6 py-4'>
      <div className='flex items-center justify-center gap-1'>
        <p className='py-2 text-lg font-semibold'>Email Domains</p>
        <DomainInstruction parentClass='!static' showDomainTabInstruction={true} />
      </div>
      <div className='flex items-center justify-center gap-[18px]'>
        <SearchInput
          onChange={handleSearchChange}
          placeholder='Search'
          parentClassName='w-[16.25rem]'
          className='h-11 pl-11'
          leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        />
        <Button onClick={() => setShowAddDomain(true)} size='lg' className='w-[10.75rem]'>
          <Plus className='h-5 w-5' color='white' />
          Add New
        </Button>
      </div>
    </div>
  );
};

export default DomainsTabHeader;
