import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { useManageOrganization } from '@pages/manageOrganization';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { getLocalStorageItem } from '@utils';
import { FIELD_REQUIRED, INVALID_PAGE, ROLES, SUCCESSFUL_MESSAGE, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function useGoalsProgress(onCreateGoalSuccess = () => null, onAddToDashboardSuccess = () => null) {
  const { organizationId } = useParams();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteGoalLoading, setDeleteGoalLoading] = useState(false);
  const [goalDetail, setGoalDetail] = useState({});
  const [dashboardGoal, setDashboardGoal] = useState({});
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);
  const userRole = getLocalStorageItem('userOrganizationRole');
  const isNotMember = ROLES.MEMBER !== userRole;

  const { manageOrganizationRefetch } = useManageOrganization();

  const { data: goalsProgress, isLoading: goalsProgressLoading } = useGetQuery(
    'goals-progress',
    apiEndpoints.GOALS_PROGRESS(organizationId),
    {},
    {
      enabled: isNotMember,
    }
  );

  const {
    data: goalsListing,
    refetch: goalsListingRefetch,
    isLoading: goalsListingLoading,
    isFetching: goalListingFetching,
  } = useGetQuery(
    'goals-listing',
    apiEndpoints.ORGANIZATION_GOALS(organizationId),
    { page, pageSize: totalPagesPerItem, search },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setPage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { data: dashboardsListing } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId)
  );

  const goalInitialValues = {
    name: '',
    dashboard: '',
    timePeriod: '',
    description: '',
    widget: '',
    condition: '',
    metric: '',
  };

  const goalValidationSchema = Yup.object({
    name: Yup.string().required(FIELD_REQUIRED('Goal name')),
    dashboard: Yup.string().required(FIELD_REQUIRED('Dashboard')),
    timePeriod: Yup.string().required(FIELD_REQUIRED('Time period')),
    description: Yup.string(),
    widget: Yup.object().required(FIELD_REQUIRED('Widget')),
    condition: Yup.string().required(FIELD_REQUIRED('Condition')),
    metric: Yup.string().required(FIELD_REQUIRED('Metric')),
  });

  const handleSubmit = () => {
    const {
      name,
      dashboard,
      description,
      widget: { widgetId, widgetSource, widgetLabel, widgetPrefix },
      timePeriod,
      metric,
      condition,
    } = values;
    const payload = {
      name,
      dashboard,
      description,
      widgetId,
      widgetSource,
      widgetLabel,
      widgetPrefix,
      periodInDays: timePeriod,
      target: parseInt(metric),
      condition,
    };
    handleCreateGoal({ payload });
  };

  const { ...formik } = useFormikForm(goalInitialValues, handleSubmit, goalValidationSchema);
  const { values, resetForm, setErrors } = formik;

  const { data: widgetListing, refetch: widgetListingRefetch } = useGetQuery(
    'goal-widget-listing',
    apiEndpoints.WIDGET_LIST(organizationId, values?.dashboard),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { mutate: handleCreateGoal, isLoading: createGoalLoading } = usePostMutation(
    apiEndpoints.CREATE_GOAL(organizationId),
    res => {
      resetForm();
      setShowCreateDialog(false);
      goalsListingRefetch();
      onCreateGoalSuccess(res);
      toast.success(SUCCESSFUL_MESSAGE('Goal created'));
      manageOrganizationRefetch();
    },
    ({ response: { data } }) => {
      setErrors({
        name: data.name[0] && data.name[0],
      });
    }
  );

  const { mutate: handleAddToDashboard } = usePostMutation(
    apiEndpoints.ADD_TO_DASHBOARD(organizationId, dashboardGoal?.dashboard, dashboardGoal?.id),
    () => {
      setDashboardGoal({});
      onAddToDashboardSuccess();
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleGoalDelete } = useDeleteMutation(
    apiEndpoints.DELETE_GOAL(organizationId, goalDetail?.id),
    () => {
      setShowDeleteDialog(false);
      setDeleteGoalLoading(false);
      goalsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Goal deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteGoalLoading(false);
      setShowDeleteDialog(false);
    }
  );

  const debouncedSearch = useCallback(
    debounce(() => goalsListingRefetch(), 500),
    []
  );

  useEffect(() => {
    values?.dashboard && widgetListingRefetch();
  }, [values?.dashboard]);

  useEffect(() => {
    dashboardGoal?.id && handleAddToDashboard({});
  }, [dashboardGoal?.id]);

  useEffect(() => {
    if (isNotMember) goalsListingRefetch();
  }, [page, totalPagesPerItem]);

  useEffect(() => {
    isNotMember && debouncedSearch();
  }, [search]);

  return {
    goalsProgress,
    goalsListing,
    goalDetail,
    setGoalDetail,
    handleGoalDelete,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteGoalLoading,
    setDeleteGoalLoading,
    formik,
    dashboardsListing,
    widgetListingRefetch,
    widgetListing,
    createGoalLoading,
    showCreateDialog,
    setShowCreateDialog,
    goalsProgressLoading,
    goalsListingLoading,
    setDashboardGoal,
    page,
    setPage,
    setSearch,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    goalListingFetching,
    search,
    goalsListingRefetch,
  };
}
