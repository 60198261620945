import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import Chart from 'react-apexcharts';
import { Loader } from '@common/components';
import { EmptyWidget } from '@common/widgets';
import { COLORS, THEME_STYLE, DEFAULT_VALUE } from '@constants';

export function ProgressWidget({ widget, onSlideMode }) {
  const chartRef = useRef(null);
  const [chartAnimation, setChartAnimation] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const labels = widget?.widgetData?.data?.map(xAxis => xAxis?.label);

  const series1 = {
    name: 'Series 1',
    data: widget?.widgetData?.data?.map(xAxis => xAxis?.data),
  };

  const series2 = {
    name: 'Series 2',
    data: widget?.widgetData?.data?.map(xAxis => xAxis?.data2),
  };

  const series = series2.data?.some(value => value !== null && value !== undefined) ? [series1, series2] : [series1];

  const chartOptions = {
    chart: {
      type: 'bar',
      animations: {
        speed: 400,
      },
      toolbar: {
        show: false,
      },
      offsetX: 15,
      offsetY: 0,
      clipOverflow: false,
    },
    xaxis: {
      categories: labels,
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: '-65',
        style: {
          fontSize: onSlideMode ? '4px' : '12px',
          colors: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: onSlideMode ? '4px' : '12px',
          colors: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true, // Horizontal bar chart
        borderRadius: 10,
        columnWidth: '70%', // Reduces bar width (try adjusting this value to your preference)
        barHeight: '80%', // Creates spacing between the bars
        dataLabels: {
          position: 'top',
        },
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    colors: [
      widget?.themeColor === DEFAULT_VALUE
        ? widget.themeStyle === THEME_STYLE.DARK
          ? COLORS.WHITE_COLOR
          : COLORS.DARK_PRIMARY
        : widget.foreground,
      COLORS.SECONDARY_GREEN_COLOR,
    ],
    grid: {
      show: true,
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const label = w.globals.labels[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];

        return `
            <div style="padding: 10px; color: #fff; background: #000; border-radius: 4px;">
              Label: ${label !== undefined ? label : 'N/A'}
              <br/>
              Value: ${value}
            </div>
          `;
      },
      followCursor: true,
    },
    dataLabels: {
      enabled: true,
      offsetX: 30,
      style: {
        colors: [widget.themeStyle === THEME_STYLE.DARK ? COLORS.TEXT_MAIN_COLOR : COLORS.TEXT_MAIN_COLOR],
        fontSize: onSlideMode ? '4px' : '12px',
        fontWeight: 'thin',
      },
    },
  };

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    const timer = setTimeout(() => {
      setChartAnimation(true);
    }, 1100);

    return () => {
      clearTimeout(timer);
      if (chartRef.current) {
        observer.unobserve(chartRef.current);
      }
    };
  }, []);

  return (
    <div className='flex h-full w-full items-center justify-center' ref={chartRef}>
      {chartAnimation ? (
        isEmpty(labels) ? (
          <EmptyWidget widget={widget} />
        ) : (
          <Chart options={chartOptions} series={series} type='bar' width={dimensions.width} height='100%' />
        )
      ) : (
        <Loader className='h-8 w-8' strokeColorClass={COLORS.MAP_WIDGET_HOVER_COLOR} />
      )}
    </div>
  );
}
