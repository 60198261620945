import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Drawer, Dropdown, Modal, Skeleton } from 'antd';
import {
  Share2,
  FileText,
  Save,
  ChevronLeft,
  Layers,
  PencilLine,
  ChevronDown,
  Goal,
  MessageCircle,
  X,
  Copy,
  Check,
  ArrowLeftToLine,
  Plus,
} from 'lucide-react';
import { useAddWidgetTour } from '../hooks/useAddWidgetTour';
import {
  BaseLoader,
  BaseToolTip,
  Button,
  DatePicker,
  FormInput,
  GoalTrackingProgress,
  Loader,
  NoDataFoundScreen,
  TourButton,
} from '@common/components';
import { useGoalWidgets } from '@pages/dashboard';
import { CreateEditGoalDrawer, useGoalsProgress } from '@pages/goalTracking';
import { ROUTES } from '@routes';
import { getLocalStorageItem, setLocalStorageItem, truncateString } from '@utils';
import { ADD_WIDGETS_BUTTON_ID, COLORS, EDIT_DASHBOARD_BUTTON_ID, ROLES } from '@constants';
import { ImageUploaderIcon } from '@assets/svgs';

export function DashboardHeader({
  uploadedLogo,
  isUploadedLogo,
  dashboardName,
  isCommentMode,
  isEditMode,
  dashboard,
  setIsEditMode,
  setIsCommentMode,
  setIsEditWidgetMode,
  setEditWidget,
  setIsDateCleared,
  dateRange,
  handleDateRange,
  handleRangeDateFilter,
  showDateTimeFilter,
  setShowDateTimeFilter,
  handleClearRangeDateFilter,
  dateRangeSet,
  handleSelectDateRange,
  dateRangeStart,
  setDateRangeStart,
  dateRangeEnd,
  setDateRangeEnd,
  compareRangeSet,
  handleSelectCompareRange,
  compareRangeEnd,
  compareRangeStart,
  setCompareRangeEnd,
  setCompareRangeStart,
  resetForm,
  shareDashboardUrlRefetch,
  shareDashboardUrlFetching,
  copyToClipboard,
  isCopied,
  sharedDashboardURL,
  organizationId,
  dashboardId,
  setCommentedWidget,
  setShowTemplateDialog,
  isGoalTrackingMode,
  setIsGoalTrackingMode,
  page,
  viewType,
  saveAsTemplateFormik,
  widgetListing,
  dashboardsListing,
  handleOnDrag,
}) {
  const params = useParams();
  const queryClient = useQueryClient();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showShareDashboardModal, setSharedDashboardModal] = useState(false);

  const onCreateGoalSuccess = () => queryClient.invalidateQueries({ queryKey: ['goal-widgets'] });

  const { formik, createGoalLoading, showCreateDialog, setShowCreateDialog } = useGoalsProgress(onCreateGoalSuccess);

  const { goalWidgets, goalWidgetsFetching } = useGoalWidgets(isGoalTrackingMode);

  const navigate = useNavigate();
  const { addWidgetTour } = useAddWidgetTour();

  const items = [
    {
      key: 'COMMENT',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => {
            setIsCommentMode(true);
            setCommentedWidget(null);
            resetForm();
          }}
        >
          <MessageCircle color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Comments
        </div>
      ),
    },
  ];

  const adminOnlyDropdownItems = [
    {
      key: 'GOAL',
      label: (
        <div
          onClick={() => {
            setIsGoalTrackingMode(!isGoalTrackingMode);
            const openedDashboard =
              dashboardsListing?.data?.results?.find(option => option?.id == params.dashboardId) || '';
            formik.setFieldValue('dashboard', openedDashboard?.id);
          }}
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
        >
          <Goal color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Goal Tracking
        </div>
      ),
    },
    {
      key: 'REPORT',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => navigate(generatePath(ROUTES.REPORTS, { organizationId, dashboardId }))}
        >
          <FileText color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Reports
        </div>
      ),
    },
    {
      key: 'SHARE',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => {
            setSharedDashboardModal(true);
            shareDashboardUrlRefetch();
          }}
        >
          <Share2 color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Share
        </div>
      ),
    },
    {
      key: 'TEMPLATE',
      label: (
        <div
          onClick={() => {
            setShowTemplateDialog(true);
            saveAsTemplateFormik.resetForm();
          }}
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
        >
          <Save color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Save as Template
        </div>
      ),
    },
  ];

  const isMember = getLocalStorageItem('userOrganizationRole') === ROLES.MEMBER;
  const dropdownItems = isMember ? items : [...adminOnlyDropdownItems, ...items];

  return (
    <div className='flex items-center justify-between gap-0 border-b border-b-secondaryStrokeButton bg-white px-8 py-4'>
      <div className='inline-flex items-center'>
        <span
          onClick={() => {
            navigate(generatePath(ROUTES.DASHBOARD, { organizationId }), {
              state: { page, viewType },
            });
            setLocalStorageItem('dashboardCurrentPage', page);
            setLocalStorageItem('dashboardViewType', viewType);
          }}
          className='mr-3 cursor-pointer'
        >
          <ChevronLeft color={COLORS.ICON_COLOR} />
        </span>
        <div className={'flex h-[3rem] w-[3rem] items-center rounded-full bg-lightGrayColor'}>
          {isUploadedLogo ? (
            <Skeleton active title={false} avatar={{ shape: 'circle', size: 48 }} />
          ) : (
            <>
              {uploadedLogo ? (
                <img src={uploadedLogo} alt='logo' className='h-full w-full object-contain' />
              ) : (
                <ImageUploaderIcon />
              )}
            </>
          )}
        </div>
        <p className='ml-4 text-lg font-semibold leading-[1.625rem] text-mainTextColor'>{dashboardName}</p>
      </div>
      <div>
        <div className='flex items-center'>
          {!(isEditMode || isCommentMode) && (
            <DatePicker
              placeholder='Filter by date'
              setIsDateCleared={setIsDateCleared}
              date={dateRange}
              setDate={handleDateRange}
              handleRangeDateFilter={handleRangeDateFilter}
              showDateTimeFilter={showDateTimeFilter}
              setShowDateTimeFilter={setShowDateTimeFilter}
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              dateRangeSet={dateRangeSet}
              handleSelectDateRange={handleSelectDateRange}
              dateRangeStart={dateRangeStart}
              setDateRangeStart={setDateRangeStart}
              dateRangeEnd={dateRangeEnd}
              setDateRangeEnd={setDateRangeEnd}
              compareRangeSet={compareRangeSet}
              handleSelectCompareRange={handleSelectCompareRange}
              compareRangeEnd={compareRangeEnd}
              compareRangeStart={compareRangeStart}
              setCompareRangeEnd={setCompareRangeEnd}
              setCompareRangeStart={setCompareRangeStart}
              isComparable={true}
              className='me-6'
            />
          )}
          <div className="relative flex gap-3 pl-6 before:absolute before:left-0 before:top-[-18px] before:h-[5rem] before:w-[1px] before:bg-secondaryStrokeButton before:content-['']">
            <Dropdown
              menu={{ items: dropdownItems }}
              placement='bottomLeft'
              open={dropdownVisible}
              onOpenChange={() => setDropdownVisible(false)}
            >
              <Button
                variant='outline'
                className='inline-flex w-[12.25rem] items-center justify-between px-4'
                size='lg'
                onClick={() => setDropdownVisible(true)}
              >
                <span className='flex items-center gap-2'>
                  <Layers color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Default Actions{' '}
                </span>
                <ChevronDown color={COLORS.ICON_COLOR} className='h-4 w-4' />
              </Button>
            </Dropdown>
            {dashboard?.data?.canEdit && (
              <Button
                id={EDIT_DASHBOARD_BUTTON_ID}
                onClick={() => {
                  setIsEditMode(true);
                  setIsEditWidgetMode(false);
                  setIsGoalTrackingMode(false);
                  setEditWidget({});
                }}
                size='lg'
                className='inline-flex w-[11.25rem] items-center gap-2 px-4'
              >
                <PencilLine color='#fff' className='h-5 w-5' />
                Edit Dashboard
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={showShareDashboardModal}
        onCancel={() => setSharedDashboardModal(false)}
        centered={true}
      >
        {shareDashboardUrlFetching ? (
          <div className='flex min-h-[10rem] items-center justify-center'>
            <Loader strokeColorClass={COLORS.SECONDARY_GREEN_COLOR} className='h-12 w-12' />
          </div>
        ) : (
          <div>
            <h3 className='mb-6 text-2xl font-bold leading-[2.125rem] text-mainText'>Share Dashboard</h3>
            <FormInput labelText='URL' value={sharedDashboardURL} placeholder='www.example.com' />
            <Button
              size='lg'
              disabled={!sharedDashboardURL}
              variant={isCopied ? 'secondary' : 'default'}
              className='mt-6'
              onClick={copyToClipboard}
            >
              {isCopied ? <Check color='#fff' className='h-5 w-5' /> : <Copy color='#fff' className='h-5 w-5' />}{' '}
              {isCopied ? 'Copied' : 'Copy URL'}
            </Button>
          </div>
        )}
      </Modal>

      <Drawer
        open={isGoalTrackingMode}
        onClose={() => setIsGoalTrackingMode(false)}
        closeIcon={null}
        className='goalTrackingDrawer'
        width='28.25rem'
        mask={false}
      >
        <div className='bg-tableHeaderBackground px-8 py-6'>
          <span className='mb-6 inline-flex cursor-pointer items-center gap-4 text-lg font-semibold leading-[1.625rem] text-mainText'>
            <ArrowLeftToLine
              className='h-5 w-5'
              color={COLORS.ICON_COLOR}
              onClick={() => setIsGoalTrackingMode(false)}
            />
            Goal Tracking
          </span>
          <Button
            className='w-[24.25rem]'
            onClick={() => {
              setShowCreateDialog(true);
              const openedDashboard =
                dashboardsListing?.data?.results?.find(option => option?.id == params.dashboardId) || '';
              formik.setFieldValue('dashboard', openedDashboard?.id);
            }}
          >
            <Plus className='h-5 w-5' color='white' />
            Create New
          </Button>
        </div>

        <div className='relative flex h-[calc(100vh-212px)] flex-col gap-4 overflow-y-auto px-8 py-6'>
          {goalWidgetsFetching ? (
            <BaseLoader />
          ) : goalWidgets?.data?.count === 0 ? (
            <div>
              <NoDataFoundScreen
                title='No Goals Listed Yet'
                message="It looks like you haven't started tracking any goals yet. Click 'Create New' to set your goals and begin tracking your progress!"
              />
            </div>
          ) : (
            goalWidgets?.data?.results.map(goal => (
              <div
                key={goal.id}
                draggable
                onDragStart={e => handleOnDrag(e, goal)}
                className='h-[15rem] rounded-xl border p-5'
              >
                <h3 className='text-[1.125rem] font-semibold leading-[1.625rem]' id={`name-${goal?.id}`}>
                  {goal?.name?.length > 20 && <BaseToolTip id={`name-${goal?.id}`} content={goal?.name} />}
                  {truncateString(goal.name, 20)}
                </h3>
                <div className='mt-3 flex flex-col gap-3 border-secondaryStrokeButton'>
                  <div className='flex items-center justify-between'>
                    <span className='text-sm font-normal text-bodyText'>Widget</span>
                    <h5 id={`label-${goal?.id}`} className='text-sm font-normal'>
                      {goal?.widgetLabel?.length > 20 && (
                        <BaseToolTip id={`label-${goal?.id}`} content={goal?.widgetLabel} />
                      )}
                      {truncateString(goal.widgetLabel, 20)}
                    </h5>
                  </div>
                  <div className='flex items-center justify-between'>
                    <span className='text-sm font-normal text-bodyText'>Time period</span>
                    <h5 className='text-sm font-normal'>{goal.periodInDays}</h5>
                  </div>
                </div>
                <div className='mb-5 mt-5 border-b' />
                <GoalTrackingProgress showBadge={true} goal={goal} className='!w-full' />
              </div>
            ))
          )}
        </div>
      </Drawer>
      {!isCommentMode && dashboard?.data?.canEdit && (
        <TourButton
          className='bottom-[7.125rem] right-[2.4375rem] h-12 p-3 md:bottom-[9.75rem] md:right-12 md:h-16 md:p-4 lg:bottom-[6.25rem] lg:right-[2.875rem] lg:!h-10 lg:p-2.5 xxl:bottom-[8.5rem] xxl:right-[4.1875rem] xxl:!h-12 xxl:p-3'
          iconClass='h-6 w-6 md:h-8 md:w-8 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'
          Label='"Add Widgets"'
          id={ADD_WIDGETS_BUTTON_ID}
          onClick={() => {
            addWidgetTour().drive();
            setIsEditWidgetMode(false);
            setEditWidget({});
          }}
        />
      )}
      <CreateEditGoalDrawer
        title='Create Goal'
        formik={formik}
        dashboardsListing={dashboardsListing}
        widgetListing={widgetListing}
        isLoading={createGoalLoading}
        showDrawer={showCreateDialog}
        setShowDrawer={setShowCreateDialog}
        disableDashboardField={true}
      />
    </div>
  );
}
