import * as React from 'react';
import { cn } from '@shadcnUtils';
import { cva } from 'class-variance-authority';

const searchVariants = cva('w-full rounded-full', {
  variants: {
    variant: {
      default:
        'text-badgeGray border border-secondaryStrokeButton bg-white text-mainText placeholder:text-placeholderText focus:border-highlighterText2 focus-visible:outline-none',
    },
    size: {
      default: 'px-4 py-2.5 h-10 text-sm leading-5',
      lg: 'h-11 px-4 py-3 text-sm leading-5',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

const SearchInput = React.forwardRef(({ className, parentClassName, leadIcon, variant, size, ...props }, ref) => {
  return (
    <div className={cn(`relative w-full ${parentClassName}`)}>
      <input type='search' className={cn(searchVariants({ variant, size, className }))} ref={ref} {...props} />
      {leadIcon && <span className='absolute inset-y-0 start-0 flex cursor-pointer items-center ps-4'>{leadIcon}</span>}
    </div>
  );
});
SearchInput.displayName = 'SearchInput';

export { SearchInput };
