import { Modal } from 'antd';
import { X } from 'lucide-react';
import { BaseLoader, CustomPagination, DeleteDialog, NoDataFoundScreen } from '@common/components';
import { DataSourceCard } from '@pages/dashboard';
import { COLORS } from '@constants';

export function DashboardGridView({
  dashboardsListing,
  dashboardsListingFetching,
  setDashboardId,
  handleDeleteDashboard,
  selectedDashboard,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDashboardLoading,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  viewType,
  page,
  setPage,
  data,
  totalItems,
  setTotalItems,
  paginationPage,
  setPaginationPage,
  noDashboards,
}) {
  return dashboardsListingFetching ? (
    <div className='h-[calc(100vh-215px)] min-h-[23.25rem] overflow-y-auto scrollbar-none'>
      <BaseLoader className='!h-[inherit]' />
    </div>
  ) : (
    <div className='h-full overflow-y-auto'>
      {noDashboards ? (
        <div className='h-[calc(100vh-215px)] min-h-[23.25rem] overflow-y-auto scrollbar-none'>
          <NoDataFoundScreen />
        </div>
      ) : (
        <div className='flex flex-col'>
          <div className='h-[calc(100vh-256px)] min-h-[23.25rem] overflow-y-auto scrollbar-none'>
            <div className='grid grid-cols-4 gap-6 p-6'>
              {dashboardsListing?.data?.results.map(dashboard => (
                <DataSourceCard
                  key={dashboard?.id}
                  dashboard={dashboard}
                  setDashboardId={setDashboardId}
                  handleDeleteDashboard={handleDeleteDashboard}
                  selectedDashboard={selectedDashboard}
                  setSelectedDashboard={setSelectedDashboard}
                  showDeleteDialog={showDeleteDialog}
                  setShowDeleteDialog={setShowDeleteDialog}
                  deleteDashboardLoading={deleteDashboardLoading}
                  handleDuplicateDashboard={handleDuplicateDashboard}
                  isMember={isMember}
                  page={page}
                  viewType={viewType}
                />
              ))}
            </div>
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            data={data}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
        </div>
      )}
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
        open={showDeleteDialog}
      >
        <DeleteDialog
          heading={selectedDashboard?.name}
          text={selectedDashboard?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDashboard();
            setDeleteDashboardLoading(true);
          }}
          isLoading={deleteDashboardLoading}
        />
      </Modal>
    </div>
  );
}
