import { OAuthAccountListing } from '@pages/dataSource';
import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function NoDataDisplay() {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-3 font-Manrope text-xs font-medium leading-4 text-highlighterText2'>
      No data to be displayed here
    </div>
  );
}

export function AccountField({
  showProperties,
  accountPropertiesListing,
  checkedPropertyId,
  setCheckedPropertyId,
  setCheckedProperty,
  oAuthListing,
  linkedInAccounts,
  dataSource,
  fbAccountListing,
  accountListing,
  checkedAccountId,
  setCheckedAccountId,
  setCheckedAccount,
  showStep2,
  instagramPagesListing,
  elementId,
  setElementId,
  tikTokAccountListing,
  googleAdsCustomerListing,
  youtubeAccountListing,
  googleAdsCustomerLoading,
}) {
  const renderOAuthAccountListing = (listing, checkedId, setCheckedId, setCheckedAccount) => (
    <OAuthAccountListing
      listing={listing}
      checkedId={checkedId}
      setCheckedId={setCheckedId}
      setCheckedAccount={setCheckedAccount}
      dataSource={dataSource}
      isLoading={googleAdsCustomerLoading}
    />
  );

  /**
   * Retrieves the appropriate listing based on the provided data source name.
   *
   * This function maps the given data source name to its corresponding listing.
   * If the data source name is not recognized, it returns an empty array.
   *
   * @param {string} sourceName - The name of the data source.
   * @returns {Array} The listing corresponding to the provided data source name, or an empty array if the name is not recognized.
   */

  const getListingBySourceName = sourceName => {
    const listings = {
      [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS]: linkedInAccounts,
    };

    return listings[sourceName] || [];
  };

  const renderContent = () => {
    if (showProperties) {
      if (accountPropertiesListing?.data?.properties?.length > 0) {
        return renderOAuthAccountListing(
          accountPropertiesListing?.data?.properties,
          checkedPropertyId,
          setCheckedPropertyId,
          setCheckedProperty
        );
      } else {
        return <NoDataDisplay />;
      }
    } else if (oAuthListing?.data?.results.length > 0 && !showStep2) {
      const sourceName = capitalizeSourceName(dataSource?.sourceName);

      const listing =
        sourceName === UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING
          ? fbAccountListing?.data?.accounts
          : sourceName === UPPERCASE_DATA_SOURCES.INSTAGRAM
            ? instagramPagesListing?.data?.pages
            : sourceName === UPPERCASE_DATA_SOURCES.YOUTUBE_ANALYTICS
              ? youtubeAccountListing?.data?.results
              : sourceName === UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS
                ? accountListing?.data?.accounts
                : '';

      return renderOAuthAccountListing(listing, checkedAccountId, setCheckedAccountId, setCheckedAccount);
    } else if (showStep2) {
      const sourceName = capitalizeSourceName(dataSource?.sourceName);

      const listing =
        sourceName === UPPERCASE_DATA_SOURCES.LINKEDIN_ADS
          ? getListingBySourceName(sourceName)
          : sourceName === UPPERCASE_DATA_SOURCES.TIKTOK_MARKETING
            ? tikTokAccountListing?.data?.results
            : sourceName === UPPERCASE_DATA_SOURCES.GOOGLE_ADS
              ? googleAdsCustomerListing?.data?.results
              : undefined;

      return renderOAuthAccountListing(listing, elementId, setElementId, setCheckedAccount);
    } else {
      return <NoDataDisplay />;
    }
  };

  return <div className='flex-grow'>{renderContent()}</div>;
}
