import { generatePath, useNavigate } from 'react-router-dom';
import { Table, Modal, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { SquarePen, Pen, X, Building2 } from 'lucide-react';
import {
  AddEditOrgDialog,
  CustomPagination,
  DeleteDialog,
  Loader,
  NoDataFoundScreen,
  TableSkeletonLoader,
} from '@common/components';
import { ROUTES } from '@routes';
import { setLocalStorageItem } from '@utils';
import { COLORS, HOME_ORGANIZATION_TABS, SELECTED_ORGANIZATION_ID } from '@constants';

export function MyOrganizationTab({
  organizations,
  page,
  setPage,
  organizationFetching,
  search,
  editFormik,
  imageRef,
  uploadedLogoEdit,
  setUploadedLogoEdit,
  setIsUploadedLogoEdit,
  isUploadedLogoEdit,
  isEditOrganizationLoading,
  isDeleteOrgLoading,
  handleDeleteOrganizationBtn,
  handleDeleteBtn,
  showAddEditDialog,
  showDeleteOrgDialog,
  setShowDeleteOrgDialog,
  setShowAddEditDialog,
  selectedOrganization,
  setSelectedOrganization,
  userDetail,
  manageOrganizationFetching,
  editHandleImageUpload,
  openEditDialogue,
  totalPagesPerItem,
  setTotalPagesPerItem,
  paginationPage,
  setPaginationPage,
  currentTab,
  editImageLoading,
}) {
  const navigate = useNavigate();

  const defaultOrganizationId = userDetail?.data?.defaultOrganization;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20rem',
      render: (text, record) =>
        organizationFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <span className='inline-block h-10 min-w-10'>
              {record?.logo?.image ? (
                <img src={record?.logo?.image} alt='dashboard' className='h-full w-full' />
              ) : (
                <Building2 color={COLORS.ICON_COLOR} className='h-full w-full' />
              )}
            </span>
            <p className='!text-bodyText'>
              {text}{' '}
              <span className='!text-labelText'>{defaultOrganizationId === record?.organizationId && '(Default)'}</span>
            </p>
          </div>
        ),
    },
    {
      title: 'Dashboards',
      dataIndex: 'dashboardsCount',
      key: 'dashboardsCount',
    },
    {
      title: 'Teams',
      dataIndex: 'teamCount',
      key: 'teamCount',
    },
    {
      title: 'Members',
      dataIndex: 'memberCount',
      key: 'memberCount',
    },
    {
      title: 'Data Sources',
      dataIndex: 'dataSourcesCount',
      key: 'dataSourcesCount',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      key: 'action',
      render: (_, record) =>
        organizationFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='flex gap-1.5'>
            <span
              onClick={e => {
                e.stopPropagation();
                setSelectedOrganization(record);
                setShowAddEditDialog({ ...showAddEditDialog, [record]: true });
              }}
              className={userDetail?.data?.isSubscribed ? 'cursor-pointer' : 'pointer-events-none'}
            >
              <SquarePen
                color={userDetail?.data?.isSubscribed ? COLORS.SECONDARY_GREEN_COLOR : COLORS.BUTTON_DISABLED_COLOR}
                className='h-4 w-4'
              />
            </span>
          </div>
        ),
    },
  ];

  return (
    <>
      <div className='flex flex-col gap-6'>
        <div className='relative z-[1] w-full'>
          {organizationFetching ? (
            <div className='h-[calc(100vh-21.125rem)] min-h-[23.25rem] overflow-auto rounded-b-xl md:h-[calc(100vh-28.875rem)] lg:h-[calc(100vh-34.3125rem)]'>
              <TableSkeletonLoader columns={columns} rowCount={12} />
            </div>
          ) : search && organizations?.data?.count === 0 ? (
            <div className='h-[calc(100vh-21.125rem)] min-h-[23.25rem] overflow-auto md:h-[calc(100vh-28.875rem)] lg:h-[calc(100vh-34.3125rem)]'>
              <NoDataFoundScreen message="Sorry, we couldn't find any matches. Try adjusting your search criteria or explore other categories." />
            </div>
          ) : (
            <div className='flex h-full flex-col justify-between'>
              <Table
                columns={columns}
                dataSource={organizations?.data?.results}
                rowKey='id'
                className='table-thin-bold h-[calc(100vh-23.625rem)] min-h-[23.25rem] overflow-auto md:h-[calc(100vh-36.875rem)]'
                rowClassName='cursor-pointer'
                onRow={record => ({
                  onClick: () => {
                    setLocalStorageItem(SELECTED_ORGANIZATION_ID, record.organizationId);
                    navigate(generatePath(ROUTES.DASHBOARD, { organizationId: record.organizationId }));
                  },
                })}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
              <CustomPagination
                page={page}
                setPage={setPage}
                data={organizations}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            </div>
          )}
        </div>
      </div>
      {!isEmpty(selectedOrganization) && currentTab === HOME_ORGANIZATION_TABS.MY_ORGANIZATION && (
        <Modal
          footer={null}
          className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
          closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
          open={showAddEditDialog[selectedOrganization]}
          onOpenChange={isOpen => {
            setShowAddEditDialog({ ...showAddEditDialog, [selectedOrganization]: isOpen });
            openEditDialogue();
          }}
          centered={true}
          onCancel={() => {
            setShowAddEditDialog(false);
            setUploadedLogoEdit(false);
            setIsUploadedLogoEdit(false);
            editFormik.setFieldValue('logo', '');
          }}
        >
          {manageOrganizationFetching ? (
            <div className='flex h-[15.625rem] items-center justify-center'>
              <Loader strokeColorClass={COLORS.SECONDARY_GREEN_COLOR} className='h-10 w-10' />
            </div>
          ) : (
            <AddEditOrgDialog
              dialogTitle='Edit Organization'
              formik={editFormik}
              imageRef={imageRef}
              uploadedLogo={uploadedLogoEdit}
              handleImageUpload={editHandleImageUpload}
              setUploadedLogo={setUploadedLogoEdit}
              setIsUploadedLogo={setIsUploadedLogoEdit}
              icon={
                <div className='flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-highlighterText1'>
                  <Pen className='h-2.5 w-2.5' color='white' />
                </div>
              }
              isUploadedLogo={isUploadedLogoEdit}
              organizationLoading={isEditOrganizationLoading}
              handleDeleteBtn={handleDeleteBtn}
              organizationId={selectedOrganization?.organizationId}
              defaultOrganizationId={defaultOrganizationId}
              manageOrganizationFetching={manageOrganizationFetching}
              imageLoading={editImageLoading}
            />
          )}
        </Modal>
      )}
      <Modal
        footer={null}
        className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
        closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        centered={true}
        open={showDeleteOrgDialog}
        onOpenChange={setShowDeleteOrgDialog}
        onCancel={() => setShowDeleteOrgDialog(false)}
      >
        <DeleteDialog
          heading={selectedOrganization?.name}
          text={selectedOrganization?.name}
          deleteBtnClick={handleDeleteOrganizationBtn}
          isLoading={isDeleteOrgLoading}
          goBackBtnClick={() => setShowDeleteOrgDialog(false)}
        />
      </Modal>
    </>
  );
}
