import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { UserAction } from '@common/components';
import { useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';

const BACK_BUTTON_URLS = ['/add-data-source', '/white-label', 'reports'];

const shouldShowBackButton = pathname => BACK_BUTTON_URLS.some(urlPattern => pathname.includes(urlPattern));

export function Header({ ActiveText, logo }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { manageOrganizationFetching, userDetail, userDetailRefetch } = useManageOrganization();

  const showBackButton = shouldShowBackButton(pathname);

  return (
    <div className='sticky left-0 top-0 z-[100] flex items-center justify-between border-b border-b-secondaryStrokeButton bg-white px-5 py-2.5 md:px-8 md:py-4'>
      <>
        {logo && <Link to={ROUTES.HOME}>{logo}</Link>}
        <h3 className='hidden lg:flex lg:text-base lg:leading-5 xxl:text-2xl xxl:leading-6'>
          <div className='flex items-center justify-center gap-4'>
            {showBackButton && <ChevronLeft className='h-5 w-5 cursor-pointer' onClick={() => navigate(-1)} />}
            <p>{!manageOrganizationFetching && ActiveText}</p>
          </div>
        </h3>
      </>
      <div className='flex items-center gap-4 md:gap-8'>
        <UserAction userDetail={userDetail} userDetailRefetch={userDetailRefetch} />
      </div>
    </div>
  );
}
