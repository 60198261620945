import { NoDataFoundScreen } from '@common/components/NoDataFoundScreen';
import { Modal, Skeleton, Table } from 'antd';
import { Plus, SquarePen, Trash2, X } from 'lucide-react';
import { MembersPopover } from './MembersPopover';
import { useCreateTeamTour } from '../hooks/useCreateTeamTour';
import { DeleteDialog, TourButton, Button, TableSkeletonLoader, CustomPagination } from '@common/components';
import { AddEditTeamDialog, useEditTeam, useTeamsTab } from '@pages/manageOrganization';
import { getLocalStorageItem } from '@utils';
import { ROLES, CREATE_TEAM_BUTTON_ID, COLORS } from '@constants';

export function TeamsTab({ manageOrganization, currentTab, isMember }) {
  const {
    formik,
    teamsListing,
    showCreateTeamDialog,
    setShowCreateTeamDialog,
    createTeamLoading,
    scrollToRef,
    dashboardsListing,
    organizationMembersListing,
    setTeam,
    team,
    deleteTeamLoading,
    handleDeleteTeam,
    teamsListingFetching,
    showDeleteDialog,
    setShowDeleteDialog,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  } = useTeamsTab(currentTab);

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const { createTeamTour } = useCreateTeamTour();

  const {
    formik: editTeamFomik,
    isEditTeamLoading,
    setTeamId: setEditTeamId,
    setShowEditTeamDialog,
    showEditTeamDialog,
    setIsEditTeamLoading,
    scrollToRef: editScrollRef,
    organizationTeamFetching,
  } = useEditTeam();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: text =>
        teamsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <p className='text-sm font-medium leading-5 text-mainText'>{text}</p>
          </div>
        ),
    },
    {
      title: 'Members',
      dataIndex: 'users',
      key: 'users',
      width: '35%',
      render: record =>
        teamsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <MembersPopover members={record} />
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (_, render) =>
        teamsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='flex items-center gap-3'>
            <SquarePen
              className='h-5 w-5'
              color={COLORS.SECONDARY_GREEN_COLOR}
              onClick={() => {
                setShowEditTeamDialog(true);
                setEditTeamId(render.id);
                setIsEditTeamLoading(false);
                editTeamFomik.resetForm();
              }}
            />
            <Trash2
              className='h-5 w-5'
              color={COLORS.ERROR_COLOR}
              onClick={e => {
                e.stopPropagation();
                setTeam(render);
                setShowDeleteDialog(true);
              }}
            />
          </div>
        ),
    },
  ];

  return (
    <>
      <div className='h-full'>
        {!isMember && (
          <div className='absolute right-7 top-[18px] z-10'>
            <Button
              size='lg'
              id={CREATE_TEAM_BUTTON_ID}
              onClick={() => setShowCreateTeamDialog(true)}
              className='w-[11.25rem]'
            >
              <Plus className='h-5 w-5' color='white' />
              Create New
            </Button>
          </div>
        )}
        {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
          <Modal
            footer={null}
            width={420}
            closeIcon={<X color={COLORS.ICON_COLOR} />}
            open={showCreateTeamDialog}
            centered={true}
            onCancel={() => setShowCreateTeamDialog(false)}
          >
            <AddEditTeamDialog
              label='Create Team'
              formik={formik}
              scrollToRef={scrollToRef}
              teamLoading={createTeamLoading}
              dashboardsListing={dashboardsListing}
              organizationMembersListing={organizationMembersListing}
              createTeamTour={createTeamTour}
            />
          </Modal>
        )}
        {teamsListingFetching ? (
          <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto rounded-b-xl'>
            <TableSkeletonLoader columns={columns} rowCount={12} />
          </div>
        ) : teamsListing?.data?.results?.length === 0 || !teamsListing ? (
          <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto'>
            <NoDataFoundScreen />
          </div>
        ) : (
          <div className='flex h-full flex-col justify-between'>
            <Table
              columns={isMember ? columns.filter(tab => tab?.title !== 'Action') : columns}
              dataSource={teamsListing?.data?.results}
              rowKey='id'
              rowClassName='cursor-pointer'
              className='table-bold-thin h-[calc(100vh-340px)] min-h-[372px] overflow-auto'
              pagination={false}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              data={teamsListing}
              totalItems={totalPagesPerItem}
              setTotalItems={setTotalPagesPerItem}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />
          </div>
        )}
      </div>
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
      >
        <DeleteDialog
          text={team?.name}
          textClass='!mb-4'
          deleteBtnClick={handleDeleteTeam}
          goBackBtnClick={() => setShowDeleteDialog(false)}
          isLoading={deleteTeamLoading}
        />
      </Modal>
      {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
        <Modal
          footer={null}
          width={420}
          closeIcon={<X color={COLORS.ICON_COLOR} />}
          open={showEditTeamDialog}
          onOpenChange={isOpen => {
            setShowEditTeamDialog(isOpen);
            setIsEditTeamLoading(false);
          }}
          centered={true}
          onCancel={() => setShowEditTeamDialog(false)}
        >
          <AddEditTeamDialog
            label='Edit Team'
            formik={editTeamFomik}
            scrollToRef={editScrollRef}
            teamLoading={isEditTeamLoading}
            dashboardsListing={dashboardsListing}
            organizationMembersListing={organizationMembersListing}
            isLoading={organizationTeamFetching}
          />
        </Modal>
      )}
      {userOrganizationRole !== ROLES.MEMBER && (
        <TourButton Label='“Create Team”' onClick={() => createTeamTour().drive()} />
      )}
    </>
  );
}
