import { CustomCheckbox } from '@common/components';
import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function OAuthAccountListing({ listing, checkedId, setCheckedId, setCheckedAccount, dataSource }) {
  return (
    <div className='flex h-[12rem] flex-col overflow-y-auto'>
      {listing?.length > 0 ? (
        listing?.map((item, index) => (
          <div
            key={index + 1}
            className='flex items-center gap-2 border-b border-secondaryStrokeButton px-4 py-3.5 text-sm leading-5'
          >
            {item?.name && (
              <CustomCheckbox
                id={`checkbox-${index + 1}`}
                checked={checkedId === index + 1}
                onChange={e => {
                  const isChecked = e.target.checked;
                  setCheckedId(prev => (prev === index + 1 ? null : index + 1));
                  setCheckedAccount(isChecked ? item : null);
                }}
              />
            )}
            <label htmlFor={`checkbox-${index + 1}`}>
              {capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS
                ? item?.displayName
                : item?.name}
            </label>
          </div>
        ))
      ) : (
        <p className='p-4'>No Data Found.</p>
      )}
    </div>
  );
}
