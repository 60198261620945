import { isEmpty } from 'lodash';
import { Button, Loader } from '@common/components';
import { nonAuthConnectorFields } from '@utils';

export function ConnectButton({
  connectedDataSourceLoading,
  isNonAuthConnector,
  nonAuthSourceName,
  checkedOauthId,
  showAccount,
  showProperties,
  checkedPropertyId,
  checkedAccountId,
  nonAuthFields,
  checkedIds,
  handleConnectNewAccount,
  nameField,
  showConnectorNameScreen,
  validateNameLoading,
  nextConnectBtnEnable,
  // connectNewAccountLoading,
  handleConnectorNameValidation,
  instagramPagesLoading,
  googleAdsCustomerLoading,
  fbAccountListingLoading,
  linkedinAccountListingLoading,
  youtubeAccountListingLoading,
  tikTokAccountLoading,
  accountListingLoading,
  accountPropertiesLoading,
  elementId,
}) {
  const btnLoading =
    connectedDataSourceLoading ||
    validateNameLoading ||
    // connectNewAccountLoading ||
    instagramPagesLoading ||
    googleAdsCustomerLoading ||
    fbAccountListingLoading ||
    linkedinAccountListingLoading ||
    tikTokAccountLoading ||
    youtubeAccountListingLoading ||
    accountListingLoading ||
    accountPropertiesLoading;

  const isButtonDisabled = () => {
    if (btnLoading) return true;

    if (nextConnectBtnEnable) {
      return !nameField && nonAuthConnectorFields(nonAuthFields[nonAuthSourceName]);
    } else {
      const hasCheckedIds = !isEmpty(checkedIds);

      if (!isNonAuthConnector) {
        return (
          !checkedOauthId ||
          (showAccount && (showProperties ? !checkedPropertyId : !hasCheckedIds && !checkedAccountId) && !elementId)
        );
      }

      if (isNonAuthConnector) {
        return nonAuthConnectorFields(nonAuthFields[nonAuthSourceName]);
      }
    }
  };

  return (
    <Button
      disabled={isButtonDisabled()}
      onClick={() =>
        !isNonAuthConnector && !checkedOauthId ? handleConnectNewAccount() : handleConnectorNameValidation()
      }
    >
      {showConnectorNameScreen ? 'Connect' : 'Next'}
      {btnLoading && <Loader />}
    </Button>
  );
}
