import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import { CreateReport, ScheduledReports, useScheduledReport } from '@pages/reports';
import { ROUTES } from '@routes';
import { REPORTING_TABS } from '@constants';

export function Reports() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getActiveTab = path => {
    if (path.endsWith(ROUTES.CREATE_REPORT)) return REPORTING_TABS.CREATE_REPORT;
    if (path.endsWith(ROUTES.SCHEDULED_REPORTS)) return REPORTING_TABS.SCHEDULED_REPORTS;
    return REPORTING_TABS.CREATE_REPORT;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab(pathname));

  const { reportListingRefetch } = useScheduledReport();

  useEffect(() => {
    setActiveTab(getActiveTab(pathname));
  }, [pathname]);

  useEffect(() => {
    if (activeTab === REPORTING_TABS.SCHEDULED_REPORTS) reportListingRefetch();
  }, [activeTab]);

  const handleTabChange = key => {
    navigate(key);
    setActiveTab(key);
  };

  return (
    <div className='h-full p-8'>
      <div className='h-full rounded-xl bg-white'>
        <Tabs
          className='reports-tabs h-full [&>.ant-tabs-nav]:border-b [&>.ant-tabs-nav]:border-secondaryStrokeButton [&>.ant-tabs-nav]:!px-6 [&>.ant-tabs-nav]:!py-4'
          activeKey={activeTab}
          onChange={handleTabChange}
          items={[
            {
              label: 'Create Report',
              key: REPORTING_TABS.CREATE_REPORT,
              children: <CreateReport />,
            },
            {
              label: 'Scheduled Reports',
              key: REPORTING_TABS.SCHEDULED_REPORTS,
              children: <ScheduledReports />,
            },
          ]}
        />
      </div>
    </div>
  );
}
