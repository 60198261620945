import { Calendar } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { Popover } from 'antd';
import { format } from 'date-fns';
import { Button } from '.';
import { DATE_FORMAT } from '@constants';

export function BaseDatePicker({ className, date, setDate, disabled }) {
  return (
    <Popover
      overlayClassName='calendar-popover'
      trigger='click'
      content={<Calendar mode='single' selected={date} onSelect={setDate} initialFocus today={false} />}
    >
      <Button variant='tertiary' size='xs' className={cn(className)} disabled={disabled}>
        {date ? format(date, DATE_FORMAT.CALENDAR_DATE_PICKER) : <span>Pick a date</span>}
      </Button>
    </Popover>
  );
}
