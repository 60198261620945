import { isEmpty } from 'lodash';
import { FormSelect, MultiSelect } from '@common/components';
import { transformedIntoSelectOptions, transformedIntoSelectTableOptions } from '@utils';
import { LIMIT_DROP_DOWN, OPERATIONS_TYPE, SORT_BY_OPTION, WIDGETS_TYPE } from '@constants';

export function DataTab({ editWidget, setEditWidget, widgetDimension }) {
  return (
    <div className='flex flex-col gap-6'>
      {/* {![WIDGETS_TYPE.TABLE, WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0]) &&
        transformedIntoSelectOptions(widgetDimension?.data?.dimensions).length > 0 && ( */}
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Dimension'
          placeholder='Select dimensions'
          value={editWidget?.dimensions}
          handleChange={value => setEditWidget({ ...editWidget, dimensions: value })}
          optionPlaceholder='No dimensions available'
          options={transformedIntoSelectOptions(widgetDimension?.data?.dimensions)}
          disabled={!transformedIntoSelectOptions(widgetDimension?.data?.dimensions).length > 0}
        />
      </div>
      {/* )} */}

      {transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions) && (
        <div className='flex flex-col gap-1.5'>
          {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE ? (
            <MultiSelect
              labelText='Metrics'
              placeholder='Select metrics'
              optionPlaceholder='No metrics available'
              editWidget={editWidget}
              setEditWidget={setEditWidget}
              disabled={
                !transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions)
                  .length > 0
              }
              options={transformedIntoSelectTableOptions(
                widgetDimension?.data?.metrics,
                widgetDimension?.data?.dimensions
              )}
            />
          ) : (
            <FormSelect
              labelText='Metrics'
              placeholder='Select metrics'
              value={editWidget?.metrics}
              handleChange={value => setEditWidget({ ...editWidget, metrics: value })}
              optionPlaceholder='No metrics available'
              options={transformedIntoSelectOptions(widgetDimension?.data?.metrics)}
              disabled={!transformedIntoSelectOptions(widgetDimension?.data?.metrics).length > 0}
            />
          )}
        </div>
      )}

      {/* {!isEmpty(editWidget?.metrics) && editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.TABLE && ( */}
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Operations'
          placeholder='Select operation'
          value={editWidget?.operationType}
          handleChange={value => setEditWidget({ ...editWidget, operationType: value })}
          options={OPERATIONS_TYPE}
          disabled={!isEmpty(editWidget?.metrics) && editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE}
        />
      </div>
      {/* )} */}
      {/* {editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.COUNTER && ( */}
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Limits'
          placeholder='Select limit'
          value={editWidget?.limit}
          handleChange={value => setEditWidget({ ...editWidget, limit: value })}
          options={LIMIT_DROP_DOWN}
          disabled={editWidget?.id?.split('-')[0] === WIDGETS_TYPE.COUNTER}
        />
      </div>
      {/* )} */}
      {/* {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.TABLE &&
        transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions).length >
          0 && ( */}
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Sort metrics'
          placeholder='Select sort metrics'
          value={editWidget?.sortMetrics}
          handleChange={value => setEditWidget({ ...editWidget, sortMetrics: value })}
          options={transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions)}
          disabled={
            editWidget?.id?.split('-')[0] !== WIDGETS_TYPE.TABLE ||
            !transformedIntoSelectTableOptions(widgetDimension?.data?.metrics, widgetDimension?.data?.dimensions)
              .length > 0
          }
        />
      </div>
      {/* )} */}
      {/* {![WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0]) && ( */}
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Sort by'
          placeholder='Select sort'
          value={editWidget?.sort}
          handleChange={value => setEditWidget({ ...editWidget, sort: value })}
          options={SORT_BY_OPTION}
          disabled={[WIDGETS_TYPE.COUNTER, WIDGETS_TYPE.MAP].includes(editWidget?.id?.split('-')[0])}
        />
      </div>
      {/* )} */}
    </div>
  );
}
