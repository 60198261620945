import { Popover } from 'antd';

export function DataSourcePopover({ dashboard }) {
  const visibleDataSources = dashboard?.dataSources?.slice(0, 3);
  const remainingCount = dashboard?.dataSourceCount - 3;

  return (
    <div className='flex'>
      {visibleDataSources?.map((dataSource, index) => {
        const isLastVisible = index === visibleDataSources.length - 1;

        return isLastVisible && dashboard?.dataSourceCount <= 3 ? (
          <Popover
            key={dataSource?.id}
            placement='right'
            content={
              <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[13.875rem] overflow-y-auto'>
                <ul className='flex flex-col'>
                  {dashboard?.dataSources.map(dataSource => (
                    <li
                      key={dataSource?.id}
                      onClick={e => e.stopPropagation()}
                      className='flex cursor-pointer items-center gap-1 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                    >
                      <span className='h-6 w-6 overflow-hidden p-1'>
                        <img
                          src={dataSource?.connector?.logo?.image}
                          alt='DataSource Logo'
                          className='h-full w-full object-contain'
                        />
                      </span>
                      {dataSource?.connector?.sourceName}
                    </li>
                  ))}
                </ul>
              </div>
            }
            trigger='hover'
          >
            <div
              onClick={event => event.stopPropagation()}
              className='-ml-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-white bg-tableHeaderBackground'
              title={dataSource?.connector?.sourceName}
            >
              <span className='h-[0.875rem] w-[0.875rem]'>
                <img src={dataSource?.connector?.logo?.image} alt='DataSource Logo' className='h-full w-full' />
              </span>
            </div>
          </Popover>
        ) : (
          <div
            key={dataSource?.id}
            className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-tableHeaderBackground'
            title={dataSource?.connector?.sourceName}
          >
            <span className='h-[0.875rem] w-[0.875rem]'>
              <img src={dataSource?.connector?.logo?.image} alt='DataSource Logo' className='h-full w-full' />
            </span>
          </div>
        );
      })}

      {dashboard?.dataSourceCount === 0 && (
        <p className='font-Manrope text-sm leading-5 text-labelText'>No data source connected</p>
      )}

      {dashboard?.dataSourceCount > 3 && (
        <Popover
          placement='right'
          content={
            <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[13.875rem] overflow-y-auto'>
              <ul className='flex flex-col'>
                {dashboard?.dataSources.map(dataSource => (
                  <li
                    key={dataSource?.id}
                    onClick={e => e.stopPropagation()}
                    className='flex cursor-pointer items-center gap-1 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                  >
                    <span className='h-6 w-6 overflow-hidden p-1'>
                      <img
                        src={dataSource?.connector?.logo?.image}
                        alt='DataSource Logo'
                        className='h-full w-full object-contain'
                      />
                    </span>
                    {dataSource?.connector?.sourceName}
                  </li>
                ))}
              </ul>
            </div>
          }
          trigger='hover'
        >
          <span
            className='-ml-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-white bg-shade3 text-xs leading-4 !text-highlighterText2'
            onClick={e => e.stopPropagation()}
          >
            +{remainingCount}
          </span>
        </Popover>
      )}
    </div>
  );
}
