import { Modal } from 'antd';
import { X } from 'lucide-react';
import DataSourceCard from './DataSourceCard';
import { BaseLoader, CustomPagination, DeleteDialog } from '@common/components';
import { COLORS } from '@constants';

export function DataSourceGridView({
  connectedDataSourceFetching,
  connectedDataSource,
  handleDeleteDataSource,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDataSourceLoading,
  setDeleteDataSourceLoading,
  page,
  setPage,
  totalItems,
  setTotalItems,
  paginationPage,
  setPaginationPage,
}) {
  return connectedDataSourceFetching ? (
    <div className='h-[calc(100vh-215px)] min-h-[23.25rem] overflow-y-auto rounded-b-xl scrollbar-none'>
      <BaseLoader className='h-[inherit]' />
    </div>
  ) : (
    <div className='h-full overflow-y-auto'>
      <div className='flex flex-col'>
        <div className='h-[calc(100vh-256px)] min-h-[23.25rem] overflow-y-auto scrollbar-none'>
          <div className='grid grid-cols-4 gap-6 p-6'>
            {connectedDataSource?.data?.results.map(dataSource => (
              <DataSourceCard
                key={dataSource.id}
                data={dataSource}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
              />
            ))}
          </div>
        </div>
        <CustomPagination
          page={page}
          setPage={setPage}
          data={connectedDataSource}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
        />
      </div>
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
        open={showDeleteDialog}
      >
        <DeleteDialog
          text={showDeleteDialog?.connector?.sourceName}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDataSource();
            setDeleteDataSourceLoading(true);
          }}
          isLoading={deleteDataSourceLoading}
          goBackBtnClick={() => setShowDeleteDialog(false)}
        />
      </Modal>
    </div>
  );
}
