import { Popover } from 'antd';
import { isEmpty } from 'lodash';
import { Trash2 } from 'lucide-react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  BarChartWidget,
  CounterWidget,
  CsvWidget,
  GoalWidget,
  ImageWidget,
  LineChartWidget,
  MapWidget,
  PieChartWidget,
  ProgressWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetTopHead,
} from '@common/widgets';
import { COLORS, DEFAULT_VALUE, STATIC, THEME_STYLE, WIDGETS_TYPE } from '@constants';
import { MenuDots } from '@assets/svgs';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardSlideWidgets({
  dashboard,
  page,
  csvPage,
  selectSliderWidget,
  setSelectSliderWidget,
  handleRemoveSlide,
  isSharedDashboard,
  removeWidgetSlide,
  setRemoveWidgetSlide,
  compareRangeSet,
  dateRangeSet,
  isFilterApply,
}) {
  return (
    <>
      {dashboard?.map((slide, index) => (
        <div
          className={`flex cursor-pointer gap-2 px-2 py-3 ${selectSliderWidget === index ? 'rounded-bl-xl rounded-tl-xl bg-highlightSelectedColor' : ''}`}
          key={index}
        >
          <span className='text-sm font-bold leading-5 text-white'>{index + 1}</span>
          <div className='relative h-[9.25rem] w-[15.25rem]' onClick={() => setSelectSliderWidget(index)}>
            {dashboard.length > 1 && !isSharedDashboard && (
              <Popover
                open={removeWidgetSlide[index]}
                onOpenChange={isOpen => {
                  setRemoveWidgetSlide({
                    ...removeWidgetSlide,
                    [index]: isOpen,
                  });
                }}
                placement='bottomRight'
                trigger='click'
                overlayClassName='custom-popover'
                content={
                  <ul className='w-[8.75rem] cursor-pointer p-0 text-sm font-normal leading-5 text-errorTooltip'>
                    <li
                      className='flex items-center gap-2 px-3 py-[0.8125rem] text-sm font-normal leading-5'
                      onClick={e => {
                        e.stopPropagation();
                        handleRemoveSlide(index);
                        setRemoveWidgetSlide({});
                      }}
                    >
                      <Trash2 width={16} height={16} color='#EF4444' />
                      Remove
                    </li>
                  </ul>
                }
              >
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setRemoveWidgetSlide({
                      ...removeWidgetSlide,
                      [index]: true,
                    });
                  }}
                  className='absolute right-0 top-0 z-[1] flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-sidebar-gradient'
                >
                  <MenuDots strokeColor={COLORS.WHITE_COLOR} className='h-4 w-4' />
                </div>
              </Popover>
            )}
            {!isEmpty(slide) && (
              <ResponsiveGridLayout
                className='layout flex !h-full w-full flex-wrap items-start gap-2.5 overflow-hidden scrollbar-none'
                layouts={{ xxs: slide }}
                rowHeight={0.5}
                isResizable={false}
                isDraggable={false}
                cols={{ xxs: 6 }}
                breakpoints={{ xxs: 0 }}
              >
                {slide?.map(widget => (
                  <div
                    key={widget?.i}
                    className={`${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'text-widget' : ''} pointer-events-none`}
                  >
                    <div
                      className={`h-full w-full overflow-hidden overflow-y-auto border border-widgetBorderColor p-1 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none ${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'mb-2 !h-[0.875rem] rounded-[0.375rem]' : ''}`}
                      style={
                        widget?.themeColor === DEFAULT_VALUE
                          ? {
                            backgroundColor:
                                widget.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.WHITE_COLOR,
                          }
                          : { backgroundColor: widget?.background }
                      }
                    >
                      {!widget?.connectorName?.includes(STATIC) && (
                        <WidgetTopHead
                          className='!mb-0 gap-0'
                          titleText='text-[4px] text-left leading-[unset]'
                          widgetImage='h-[6px] w-[6px] mr-0'
                          widgetWrapper='!gap-0'
                          accountName='text-[4px] ml-0.5 pl-0.5'
                          widget={widget}
                          hideTooltip={true}
                        />
                      )}
                      {widget?.widgetData?.error ? (
                        <p
                          className={'mt-4 flex h-[calc(100%-3.4375rem)] w-full text-[4px] font-bold'}
                          style={
                            widget?.themeColor === DEFAULT_VALUE
                              ? {
                                color:
                                    widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                              }
                              : {
                                color:
                                    widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                              }
                          }
                        >
                          {widget?.widgetData?.error}
                        </p>
                      ) : (
                        <>
                          {widget?.id?.includes(WIDGETS_TYPE.GOAL) && (
                            <div className='h-[calc(100%-6rem)] w-full'>
                              <GoalWidget widget={widget} className='text-[0.5rem]' onSlideMode={true} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.COUNTER) && (
                            <div className='w-full'>
                              <CounterWidget
                                value={widget?.widgetData?.data}
                                widget={widget}
                                isComparisonFilterApply={
                                  ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                  widget?.isWidgetComparisonFilterApply
                                }
                                className='py-0'
                                dataText='text-[8px] justify-start'
                                onSlideMode={true}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.BAR_CHART) && (
                            <div className='-mt-7 h-[100px] w-full scale-[0.5]'>
                              <BarChartWidget widget={widget} onSlideMode={true} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.LINE_CHART) && (
                            <div className='-mt-7 h-[100px] w-full scale-[0.5]'>
                              <LineChartWidget widget={widget} onSlideMode={true} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PIE_CHART) && (
                            <div
                              className={
                                ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                widget?.isWidgetComparisonFilterApply
                                  ? 'flex h-[100px] w-full'
                                  : 'mt-1 flex h-[200px] w-full'
                              }
                            >
                              <PieChartWidget
                                widget={widget}
                                isComparisonFilterApply={
                                  ((!!compareRangeSet || !!dateRangeSet) && isFilterApply) ||
                                  widget?.isWidgetComparisonFilterApply
                                }
                                onSlideMode={true}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PROGRESS_BAR) && (
                            <div className='-mt-7 h-[100px] w-full scale-[0.5]'>
                              <ProgressWidget widget={widget} onSlideMode={true} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.TABLE) && (
                            <div className='w-full overflow-auto scrollbar-none'>
                              <TableWidget
                                tableBodyClass='pt-0'
                                tableHeadClass='p-0'
                                tableCellClass='!p-0 !text-[0.375rem]'
                                textClass='text-[0.375rem]'
                                widget={widget}
                                page={page}
                                onSlideMode={true}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.MAP) && (
                            <MapWidget mapImage='h-[unset]' widget={widget} onSlideMode={true} />
                          )}
                        </>
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.TEXT) && (
                        <TextWidget textClass='text-[0.5rem]' widget={widget} hideTooltip={true} onSlideMode={true} />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.VIDEO) && (
                        <VideoWidget
                          titleText='text-[4px] text-left leading-[unset]'
                          headingText='text-[6px] leading-[unset]'
                          inputText='text-[0.375rem] p-0 rounded-[0.0625rem]'
                          widget={widget}
                          hideTooltip={true}
                          onSlideMode={true}
                        />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.IMAGE) && (
                        <ImageWidget
                          className='!gap-0'
                          titleText='text-[6px]'
                          inputText='text-[6px] gap-0 rounded-[2px]'
                          uploadIcon='h-[6px] w-[6px]'
                          inputWrapper='h-1 rounded-none'
                          widget={widget}
                          hideTooltip={true}
                          onSlideMode={true}
                        />
                      )}
                      {widget?.id?.includes(WIDGETS_TYPE.CSV) && (
                        <CsvWidget
                          className='!gap-0'
                          titleText='text-[6px]'
                          inputText='text-[6px] gap-0.5 rounded-[2px]'
                          uploadIcon='h-[6px] w-[6px]'
                          inputWrapper='h-3.5 rounded-none'
                          widget={widget}
                          page={csvPage}
                          hideTooltip={true}
                          onSlideMode={true}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </ResponsiveGridLayout>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
