import { useState } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { Modal, Skeleton, Table } from 'antd';
import { LayoutGrid, List, PlugZap, X } from 'lucide-react';
import { BaseLoader, BaseToolTip, Button, CustomPagination } from '@common/components';
import {
  DataSourceSearch,
  OAuthUrlListing,
  useAddDataSource,
  NameField,
  AccountField,
  NonAuthField,
  NoDataDisplay,
  AddConnectButton,
  ConnectButton,
  BackButton,
  SelectField,
  AddDataSourceCard,
} from '@pages/dataSource';
import { ROUTES } from '@routes';
import { capitalizeSourceName, truncateString } from '@utils';
import { COLORS, DASHBOARD_VIEW_TYPE, DATA_SOURCE_SEARCH_NOT_MATCHED, UPPERCASE_DATA_SOURCES } from '@constants';
import { EmptyDashboardScreenIcon } from '@assets/svgs';

const dataSourcesWithFields = [
  UPPERCASE_DATA_SOURCES.WOO_COMMERCE,
  UPPERCASE_DATA_SOURCES.PIPE_DRIVE,
  UPPERCASE_DATA_SOURCES.KLAVIYO,
  UPPERCASE_DATA_SOURCES.STRIPE,
  UPPERCASE_DATA_SOURCES.TYPEFORM,
  UPPERCASE_DATA_SOURCES.ZOHO_CRM,
  UPPERCASE_DATA_SOURCES.ACTIVE_CAMPAIGN,
  UPPERCASE_DATA_SOURCES.CLICK_UP,
];

// const forConnectTextButtonConnectors = [UPPERCASE_DATA_SOURCES.HUBSPOT, ...dataSourcesWithFields];

export function AddDataSource() {
  const {
    addDataSourceListing,
    setSearch,
    showConnectDataSourceDialog,
    setShowConnectDataSourceDialog,
    addDataSourceLoading,
    connectedDataSourceLoading,
    handleConnectNewAccount,
    oAuthListing,
    setOAuthListing,
    oAuthListingLoading,
    checkedOauthId,
    setCheckedOauthId,
    setConnectDataSourceLoading,
    organizationId,
    showNameField,
    clearDialogue,
    handleBackClick,
    showAccount,
    accountListing,
    checkedAccountId,
    setCheckedAccountId,
    setCheckedAccount,
    showProperties,
    accountPropertiesListing,
    checkedPropertyId,
    setCheckedPropertyId,
    setCheckedProperty,
    fbAccountListing,
    nameField,
    handleNameChange,
    nonAuthFields,
    handleNonAuthFields,
    isNonAuthConnector,
    linkedInAccounts,
    showStep2,
    checkedIds,
    showSelectField,
    instagramPagesListing,
    tikTokAccountListing,
    youtubeAccountListing,
    elementId,
    setElementId,
    googleAdsCustomerListing,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    showConnectorNameScreen,
    validateNameLoading,
    nextConnectBtnEnable,
    // connectNewAccountLoading,
    handleConnectorNameValidation,
    instagramPagesLoading,
    googleAdsCustomerLoading,
    fbAccountListingLoading,
    linkedinAccountListingLoading,
    youtubeAccountListingLoading,
    tikTokAccountLoading,
    accountListingLoading,
    accountPropertiesLoading,
  } = useAddDataSource();

  const { sourceName: nonAuthSourceName = null } = showConnectDataSourceDialog || {};

  const [viewType, setViewType] = useState('grid');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'sourceName',
      key: 'sourceName',
      render: (text, record) =>
        addDataSourceLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <div className='flex h-10 w-10 items-center justify-center rounded-full bg-tableHeaderBackground'>
              <img src={record?.logo?.image} alt='dashboard' className='h-6 w-6 object-contain' />
            </div>
            <p className='text-sm font-medium leading-5 text-mainText'>{record?.sourceName}</p>
          </div>
        ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) =>
        addDataSourceLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p id={`dataSource-${record?.id}`} className='text-sm leading-5 !text-bodyText [&>span]:!text-bodyText'>
            {truncateString(text, 50)}
            {text?.length > 50 && (
              <BaseToolTip className='max-w-[18.75rem]' id={`dataSource-${record?.id}`} content={text} />
            )}
          </p>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) =>
        addDataSourceLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <>
            <Button
              className='w-[11.25rem] font-semibold text-highlighterText2'
              variant='tertiary'
              onClick={() => {
                setShowConnectDataSourceDialog({
                  show: true,
                  ...record,
                });
                setConnectDataSourceLoading(false);
              }}
            >
              <PlugZap className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
              Connect
            </Button>
          </>
        ),
    },
  ];

  return (
    <div className='h-full p-8'>
      {addDataSourceLoading ? (
        <div className='p-6'>
          <BaseLoader />
        </div>
      ) : (
        <div className='rounded-xl border border-secondaryStrokeButton bg-white'>
          <div className='flex items-center justify-between border-b border-secondaryStrokeButton px-6 py-4'>
            <NavLink
              to={generatePath(ROUTES.DATA_SOURCE, { organizationId })}
              className='flex items-center gap-4 lg:gap-1.5 xxl:gap-4'
            >
              <h4 className=' font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText'>All Data Sources</h4>
            </NavLink>
            <div className='flex items-center gap-4'>
              <DataSourceSearch setSearch={setSearch} />
              <div className='ant-tabs-nav'>
                <div className='ant-tabs-nav-list flex items-center'>
                  <div
                    onClick={() => {
                      setViewType('grid');
                    }}
                    className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.GRID ? 'ant-tabs-tab-active-left' : ''}`}
                  >
                    <div className='ant-tabs-tab-btn'>
                      <LayoutGrid
                        className='h-5 w-5'
                        color={viewType === DASHBOARD_VIEW_TYPE.GRID ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setViewType('table');
                    }}
                    className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.TABLE ? 'ant-tabs-tab-active-right' : ''}`}
                  >
                    <div className='ant-tabs-tab-btn'>
                      <List
                        className='h-5 w-5'
                        color={viewType === DASHBOARD_VIEW_TYPE.TABLE ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-9'>
            <div className=''>
              <div className='relative h-full'>
                <div className='overflow-hidden'>
                  {addDataSourceListing?.data?.results.length === 0 ? (
                    <div className='mx-auto flex h-[calc(100vh-208px)] max-w-[25rem] flex-col items-center justify-center gap-10'>
                      <EmptyDashboardScreenIcon />
                      <div className='flex flex-col items-center gap-3'>
                        <h3 className='font-Manrope text-2xl font-semibold text-mainText'>
                          {DATA_SOURCE_SEARCH_NOT_MATCHED}
                        </h3>
                        <p className='text-center font-Manrope text-sm leading-5 text-bodyText'>
                          Sorry, we couldn&apos;t find any matches. Try adjusting your search criteria or explore other
                          categories.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {viewType === DASHBOARD_VIEW_TYPE.GRID && (
                        <div className='h-[calc(100vh-256px)] min-h-[294px] overflow-auto p-6'>
                          <div className='grid grid-cols-4 gap-6'>
                            {addDataSourceListing?.data?.results.map(dataSource => (
                              <div key={dataSource?.id}>
                                <AddDataSourceCard
                                  dataSource={dataSource}
                                  setShowConnectDataSourceDialog={setShowConnectDataSourceDialog}
                                  setConnectDataSourceLoading={setConnectDataSourceLoading}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {viewType === DASHBOARD_VIEW_TYPE.TABLE && (
                        <>
                          <Table
                            columns={columns}
                            dataSource={addDataSourceListing?.data?.results}
                            rowKey='id'
                            rowClassName='cursor-pointer'
                            className='table-bold-thin h-[calc(100vh-300px)] min-h-[256px] overflow-auto'
                            pagination={false}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <CustomPagination
            page={page}
            setPage={setPage}
            data={addDataSourceListing}
            totalItems={totalPagesPerItem}
            setTotalItems={setTotalPagesPerItem}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
          <Modal
            footer={null}
            width={420}
            closeIcon={<X color={COLORS.ICON_COLOR} />}
            open={showConnectDataSourceDialog.show}
            onCancel={clearDialogue}
            centered
          >
            <div className='min-h-[376px]'>
              {oAuthListingLoading ? (
                <BaseLoader className='left-0 top-0 w-full' />
              ) : (
                <>
                  <div className='mb-6 flex flex-col items-center justify-center gap-3'>
                    <span className='flex h-20 w-20 items-center justify-center rounded-full bg-tableHeaderBackground'>
                      <img src={showConnectDataSourceDialog?.logo?.image} alt='logo' />
                    </span>
                    <span className='font-Manrope text-2xl font-bold leading-[2.125rem] text-darkTextColor'>
                      {showConnectDataSourceDialog?.sourceName}
                    </span>
                  </div>
                  <div className='flex h-[15rem] flex-col justify-between rounded-lg border border-secondaryStrokeButton'>
                    <div className='flex h-full flex-col justify-between'>
                      {!showConnectorNameScreen && (
                        <>
                          {showNameField ? (
                            <NameField
                              dataSource={showConnectDataSourceDialog}
                              nameField={nameField}
                              handleNameChange={handleNameChange}
                            />
                          ) : showAccount ? (
                            <AccountField
                              showProperties={showProperties}
                              accountPropertiesListing={accountPropertiesListing}
                              checkedPropertyId={checkedPropertyId}
                              setCheckedPropertyId={setCheckedPropertyId}
                              setCheckedProperty={setCheckedProperty}
                              oAuthListing={oAuthListing}
                              linkedInAccounts={linkedInAccounts}
                              dataSource={showConnectDataSourceDialog}
                              fbAccountListing={fbAccountListing}
                              accountListing={accountListing}
                              checkedAccountId={checkedAccountId}
                              setCheckedAccountId={setCheckedAccountId}
                              setCheckedAccount={setCheckedAccount}
                              showStep2={showStep2}
                              checkedIds={checkedIds}
                              instagramPagesListing={instagramPagesListing}
                              tikTokAccountListing={tikTokAccountListing}
                              elementId={elementId}
                              setElementId={setElementId}
                              googleAdsCustomerListing={googleAdsCustomerListing}
                              googleAdsCustomerLoading={googleAdsCustomerLoading}
                              youtubeAccountListing={youtubeAccountListing}
                            />
                          ) : isNonAuthConnector ? (
                            nonAuthSourceName && (
                              <NonAuthField
                                nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                                nonAuthFields={nonAuthFields}
                                handleNonAuthFields={handleNonAuthFields}
                              />
                            )
                          ) : showSelectField ? (
                            <SelectField
                              nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                              nonAuthFields={nonAuthFields}
                              handleNonAuthFields={handleNonAuthFields}
                            />
                          ) : (
                            <div className='flex-grow'>
                              {oAuthListing?.data?.results.length > 0 ? (
                                <OAuthUrlListing
                                  listing={oAuthListing}
                                  checkedId={checkedOauthId}
                                  setCheckedId={setCheckedOauthId}
                                />
                              ) : (
                                <NoDataDisplay />
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {showConnectorNameScreen && (
                        <NameField
                          dataSource={showConnectDataSourceDialog}
                          nameField={nameField}
                          handleNameChange={handleNameChange}
                          showConnectorNameScreen={showConnectorNameScreen}
                        />
                      )}
                      {!showAccount && !isNonAuthConnector && (
                        <AddConnectButton
                          handleConnectNewAccount={handleConnectNewAccount}
                          connectedDataSourceLoading={connectedDataSourceLoading}
                          showNameField={showNameField}
                          nameField={nameField}
                          dataSource={showConnectDataSourceDialog}
                          dataSourcesWithFields={dataSourcesWithFields}
                          setConnectDataSourceLoading={setConnectDataSourceLoading}
                          checkedOauthId={checkedOauthId}
                          nextConnectBtnEnable={nextConnectBtnEnable}
                        />
                      )}
                    </div>
                  </div>
                  <div className='mt-6 flex items-center justify-between gap-3'>
                    <BackButton
                      showSelectField={showSelectField}
                      showNameField={showNameField}
                      showAccount={showAccount}
                      showProperties={showProperties}
                      isNonAuthConnector={isNonAuthConnector}
                      handleBackClick={handleBackClick}
                      setShowConnectDataSourceDialog={setShowConnectDataSourceDialog}
                      connectedDataSourceLoading={connectedDataSourceLoading}
                      setOAuthListing={setOAuthListing}
                      showConnectorNameScreen={showConnectorNameScreen}
                      nextConnectBtnEnable={nextConnectBtnEnable}
                      clearDialogue={clearDialogue}
                    />
                    <ConnectButton
                      connectedDataSourceLoading={connectedDataSourceLoading}
                      isNonAuthConnector={isNonAuthConnector}
                      nonAuthSourceName={capitalizeSourceName(nonAuthSourceName)}
                      checkedOauthId={checkedOauthId}
                      showAccount={showAccount}
                      showProperties={showProperties}
                      checkedPropertyId={checkedPropertyId}
                      checkedAccountId={checkedAccountId}
                      nonAuthFields={nonAuthFields}
                      checkedIds={checkedIds}
                      handleConnectNewAccount={handleConnectNewAccount}
                      nameField={nameField}
                      showConnectorNameScreen={showConnectorNameScreen}
                      validateNameLoading={validateNameLoading}
                      nextConnectBtnEnable={nextConnectBtnEnable}
                      // connectNewAccountLoading={  // connectNewAccountLoading}
                      handleConnectorNameValidation={handleConnectorNameValidation}
                      instagramPagesLoading={instagramPagesLoading}
                      googleAdsCustomerLoading={googleAdsCustomerLoading}
                      fbAccountListingLoading={fbAccountListingLoading}
                      linkedinAccountListingLoading={linkedinAccountListingLoading}
                      youtubeAccountListingLoading={youtubeAccountListingLoading}
                      tikTokAccountLoading={tikTokAccountLoading}
                      accountListingLoading={accountListingLoading}
                      accountPropertiesLoading={accountPropertiesLoading}
                      elementId={elementId}
                    />
                  </div>
                </>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
