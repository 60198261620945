import { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';
import { CopyPlus, Goal, MessageCircle, Pencil, Trash2 } from 'lucide-react';
import { useOutsideClick } from '@common/hooks';
import { getLocalStorageItem } from '@utils';
import { COLORS, DEFAULT_VALUE, ROLES, THEME_STYLE, WIDGETS_TYPE } from '@constants';
import { MenuDots } from '@assets/svgs';

export function WidgetMenu({
  widget,
  handleEditWidget,
  handleClone,
  onRemoveClick,
  handleEditCommentMode,
  handleGoalTrackingMode,
  isSharedDashboard,
  isCommentMode,
  setIsPopUpMenuOpen,
}) {
  const widgetMenuRef = useRef();

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const [visible, setVisible] = useState(false);

  useOutsideClick(widgetMenuRef, () => setVisible(false));
  const closePopover = () => setVisible(false);

  const popoverContent = (
    <ul className='w-[8.75rem] cursor-pointer p-0 text-sm leading-5' ref={widgetMenuRef}>
      <li
        className='flex items-center gap-2 rounded-lg p-3 text-sm leading-5 hover:bg-shade3'
        onMouseDown={() => {
          handleEditWidget(widget);
          closePopover();
        }}
      >
        <Pencil className='h-4 w-4' />
        Edit
      </li>
      <li
        className='flex items-center gap-2 rounded-lg p-3 text-sm leading-5 hover:bg-shade3'
        onMouseDown={() => {
          handleClone(widget?.i);
          closePopover();
        }}
      >
        <CopyPlus className='h-4 w-4' />
        Clone
      </li>
      {!isSharedDashboard &&
        widget?.id?.split('-')[0] === WIDGETS_TYPE.COUNTER &&
        userOrganizationRole !== ROLES.MEMBER && (
        <li
          className='flex items-center gap-2 rounded-lg p-3 text-sm leading-5 hover:bg-shade3'
          onMouseDown={() => {
            handleGoalTrackingMode();
            closePopover();
          }}
        >
          <Goal className='h-4 w-4' />
            Goal tracking
        </li>
      )}
      {!isCommentMode && (
        <li
          className='flex items-center gap-2 rounded-lg p-3 text-sm leading-5 hover:bg-shade3'
          onMouseDown={() => {
            handleEditCommentMode();
            closePopover();
          }}
        >
          <MessageCircle className='h-4 w-4' />
          Comment
        </li>
      )}
      <li
        className='flex items-center gap-2 rounded-lg p-3 text-sm leading-5 hover:bg-shade3'
        onMouseDown={() => {
          onRemoveClick(widget);
          closePopover();
        }}
      >
        <Trash2 className='h-4 w-4' color={COLORS.ERROR_TOOLTIP} />
        Remove
      </li>
    </ul>
  );

  useEffect(() => {
    !!setIsPopUpMenuOpen && setIsPopUpMenuOpen(visible);
  }, [visible]);

  return (
    <Popover
      content={popoverContent}
      trigger='click'
      open={visible}
      placement='bottomRight'
      overlayClassName='custom-popover'
    >
      <div
        onMouseDown={e => {
          e.stopPropagation();
          setVisible(!visible);
        }}
        className={`widget-menu ml-auto cursor-pointer ${visible && '!opacity-[1]'}`}
      >
        <MenuDots
          strokeColor={
            widget?.themeColor === DEFAULT_VALUE
              ? widget.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.DARK_SECONDARY
              : widget.themeStyle === THEME_STYLE.DARK
                ? COLORS.WHITE_COLOR
                : COLORS.COLOR_PRIMARY
          }
        />
      </div>
    </Popover>
  );
}
