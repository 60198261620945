import { Modal } from 'antd';
import { X } from 'lucide-react';
import { BaseLoader, DeleteDialog, GoalTrackingProgress } from '@common/components';
import { CreateEditGoalDrawer, GoalActionPopover } from '@pages/goalTracking';
import { COLORS } from '@constants';

export function GoalTrackGridView({
  goalsListing,
  goalDetail,
  setGoalDetail,
  setEditGoalDetail,
  handleGoalDelete,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteGoalLoading,
  setDeleteGoalLoading,
  showEditDialog,
  setShowEditDialog,
  widgetListing,
  formik,
  dashboardsListing,
  isLoading,
  setSelectedDashboardId,
  setDashboardGoal,
  goalListingFetching,
  showBaseLoader,
}) {
  return (
    <div className='h-[calc(100vh-420px)] min-h-[320px] overflow-y-auto scrollbar-none'>
      <div className='relative grid h-full grid-cols-4 gap-6 p-6'>
        {goalListingFetching ? (
          <BaseLoader className='h-[inherit]' />
        ) : (
          goalsListing?.data?.results.map(goal => (
            <div
              key={goal?.id}
              className='relative h-[18rem] overflow-hidden overflow-y-auto rounded-xl border p-5 scrollbar-none'
            >
              <div>
                <h3 className='text-[1.125rem] font-semibold leading-[1.625rem]'>{goal?.name}</h3>
                <GoalActionPopover
                  goal={goal}
                  setShowDeleteDialog={setShowDeleteDialog}
                  showDeleteDialog={showDeleteDialog}
                  setGoalDetail={setGoalDetail}
                  showEditDialog={showEditDialog}
                  setShowEditDialog={setShowEditDialog}
                  setEditGoalDetail={setEditGoalDetail}
                  setDashboardGoal={setDashboardGoal}
                  optionClass={'absolute h-[0.875rem] text-icon1 top-[0.75rem] right-[10px]'}
                />
                <div className='mt-3 flex flex-col gap-3 border-secondaryStrokeButton'>
                  <div className='flex items-start justify-between gap-3'>
                    <span className='text-sm font-normal text-bodyText'>Dashboard</span>
                    <h5 className='text-sm font-normal'>{goal.dashboardName}</h5>
                  </div>
                  <div className='flex items-start justify-between gap-3'>
                    <span className='text-sm font-normal text-bodyText'>Widget</span>
                    <h5 id={`label-${goal?.id}`} className='text-sm font-normal'>
                      {goal?.widgetLabel}
                    </h5>
                  </div>
                  <div className='flex items-start justify-between gap-3'>
                    <span className='text-sm font-normal text-bodyText'>Time period</span>
                    <h5 className='text-sm font-normal'>{goal.periodInDays}</h5>
                  </div>
                </div>
                <div className='mb-5 mt-5 border-b' />
                <GoalTrackingProgress showBadge={true} goal={goal} className='!w-full' />
              </div>
            </div>
          ))
        )}

        <Modal
          footer={null}
          width={420}
          closeIcon={<X color={COLORS.ICON_COLOR} />}
          open={showDeleteDialog}
          onCancel={() => setShowDeleteDialog(false)}
          centered={true}
        >
          <DeleteDialog
            text={goalDetail?.name}
            textClass='!mb-4'
            deleteBtnClick={() => {
              handleGoalDelete();
              setDeleteGoalLoading(true);
            }}
            goBackBtnClick={() => setShowDeleteDialog(false)}
            isLoading={deleteGoalLoading}
          />
        </Modal>

        <CreateEditGoalDrawer
          title='Edit Goal'
          formik={formik}
          widgetListing={widgetListing}
          dashboardsListing={dashboardsListing}
          isLoading={isLoading}
          setSelectedDashboardId={setSelectedDashboardId}
          showDrawer={showEditDialog}
          setShowDrawer={setShowEditDialog}
          showBaseLoader={showBaseLoader}
        />
      </div>
    </div>
  );
}
