import { NoDataFoundScreen } from '@common/components/NoDataFoundScreen';
import { Skeleton, Table } from 'antd';
import { Badge, Button, CustomPagination, Loader, TableSkeletonLoader } from '@common/components';
import { useInvitesTab } from '@pages/manageOrganization';
import { capitalizeString } from '@utils';
import { COLORS, POLL_STATUS_TYPE, STATUS } from '@constants';

export function InvitesTab({ currentTab }) {
  const {
    inviteListing,
    handleResendInvite,
    organizationId,
    inviteListingFetching,
    resendLoading,
    setResendLoading,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  } = useInvitesTab(currentTab);

  const statusCheck = {
    [POLL_STATUS_TYPE.SUCCEEDED]: 'success',
    [POLL_STATUS_TYPE.FAILED]: 'failed',
    [POLL_STATUS_TYPE.PENDING]: 'pending',
    [POLL_STATUS_TYPE.RUNNING]: 'running',
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'invitedEmail',
      key: 'invitedEmail',
      render: text =>
        inviteListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <p className='text-sm font-medium leading-5 text-mainText'>{text}</p>
          </div>
        ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text =>
        inviteListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='text-sm leading-5 text-bodyText'>{capitalizeString(text)}</p>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text =>
        inviteListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <Badge variant={statusCheck[text.toLowerCase()]}>
            <svg width='5' height='5'>
              <circle cx='2.5' cy='2.5' r='10' fill='current-color' stroke='black' strokeWidth='2' />
            </svg>
            {capitalizeString(text)}
          </Badge>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>
        inviteListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <>
            {record?.status !== STATUS.PENDING ? (
              '-'
            ) : (
              <div className='w-[3.75rem] xxl:w-[6rem]'>
                <Button
                  variant='tertiary'
                  size='sm'
                  disabled={resendLoading[record?.id]}
                  className='w-[6.25rem]'
                  onClick={() => {
                    handleResendInvite({
                      payload: {
                        invite: record?.id,
                        organization: organizationId,
                      },
                    });
                    setResendLoading({
                      ...resendLoading,
                      [record.id]: true,
                    });
                  }}
                >
                  Resend{' '}
                  {resendLoading[record?.id] ? (
                    <Loader className='ml-0 h-5 w-5' strokeColorClass={COLORS.ICON_COLOR} />
                  ) : (
                    ''
                  )}
                </Button>
              </div>
            )}
          </>
        ),
    },
  ];

  return (
    <div>
      {inviteListingFetching ? (
        <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto rounded-b-xl'>
          <TableSkeletonLoader columns={columns} rowCount={12} />
        </div>
      ) : inviteListing?.data?.results?.length === 0 || !inviteListing ? (
        <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto'>
          <NoDataFoundScreen />
        </div>
      ) : (
        <div className='flex h-full flex-col justify-between'>
          <Table
            columns={columns}
            dataSource={inviteListing?.data?.results}
            rowKey='id'
            rowClassName='cursor-pointer'
            className='table-bold-thin h-[calc(100vh-340px)] min-h-[372px] overflow-auto'
            pagination={false}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            data={inviteListing}
            totalItems={totalPagesPerItem}
            setTotalItems={setTotalPagesPerItem}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
        </div>
      )}
    </div>
  );
}
