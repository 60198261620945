import { isEqual } from 'lodash';
import { Pen } from 'lucide-react';
import { Button, FormInput, ImageCrop, ImageUpload, Loader } from '@common/components';
import { useProfileTab } from '@pages/profile';
import { getFile, handleSave } from '@utils';
import { ImageUploaderIcon } from '@assets/svgs';

export function ProfileTab() {
  const {
    formik,
    imageRef,
    uploadedImage,
    setIsUploadedImage,
    isUploadedImage,
    profileUpdateLoading,
    userDetailFetching,
    handleImageUpload,
  } = useProfileTab();

  const { initialValues, values, handleChange, touched, errors, submitForm, setFieldValue } = formik;

  const isActionDisabled =
    isEqual(initialValues, values) || isUploadedImage || profileUpdateLoading || userDetailFetching;

  return (
    <div className='flex h-[calc(100vh-228px)] min-h-[372px] justify-center overflow-auto overflow-y-auto rounded-b-xl bg-white px-[1.1875rem] py-[7.75rem] scrollbar-none'>
      <div className='flex w-full max-w-[26rem] flex-col'>
        {!isUploadedImage && (
          <div className='mb-10'>
            <ImageUpload
              id='profileImage'
              imageRef={imageRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='h-[7.625rem] w-[7.625rem]'
              profileImage={uploadedImage?.image || values?.profileImage}
              handleChange={e => getFile(e.target.files[0], 'profileImage', setFieldValue, setIsUploadedImage)}
              showError={touched.profileImage && errors.profileImage}
              errorMessage={errors.profileImage}
              inputWrapperClassName='w-6 h-6 bg-highlighterText1'
              icon={<Pen color='#fff' className='h-3 w-3' />}
              showSkeletonLoader={isUploadedImage}
            />
          </div>
        )}
        <div className='flex w-[26rem] flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <FormInput
              id='firstName'
              labelText='First Name'
              placeholder='Enter your first name'
              value={values.firstName}
              handleChange={handleChange}
              showError={touched.firstName && errors.firstName}
            />
            <FormInput
              id='lastName'
              labelText='Last Name'
              placeholder='Enter your last name'
              value={values.lastName}
              handleChange={handleChange}
              showError={touched.lastName && errors.lastName}
            />
            <FormInput
              id='email'
              labelText='Email'
              placeholder='Enter your email'
              type='email'
              value={values.email}
              handleChange={handleChange}
              showError={touched.email && errors.email}
              disabled={true}
            />
          </div>
          <Button size='lg' onClick={submitForm} disabled={isActionDisabled}>
            Save {profileUpdateLoading ? <Loader /> : ''}
          </Button>
        </div>
      </div>
      {isUploadedImage && (
        <ImageCrop
          uploadedImage={values?.profileImage}
          handleCancel={() => {
            setIsUploadedImage(false);
            setFieldValue('profileImage', initialValues.profileImage);
          }}
          applyRef={false}
          handleCloseDialog={() => setFieldValue('profileImage', initialValues.profileImage)}
          setIsUploaded={setIsUploadedImage}
          handleSave={file => handleSave(file, handleImageUpload)}
          isDialog
        />
      )}
    </div>
  );
}
