import { FormInput } from '@common/components';
import { capitalizeSourceName } from '@utils';
import { BING_ADS_TEXT, CONNECTOR_NAME_SCREEN, UPPERCASE_DATA_SOURCES, WOO_COMMERCE_TEXT } from '@constants';

export function NameField({ dataSource, nameField, handleNameChange, showConnectorNameScreen }) {
  const labelText = dataSource => {
    switch (capitalizeSourceName(dataSource?.sourceName)) {
    case UPPERCASE_DATA_SOURCES.WOO_COMMERCE:
      return 'Store Name';
    case UPPERCASE_DATA_SOURCES.BING_ADS:
      return 'Account Name';
    default:
      return 'Name';
    }
  };

  return (
    <div className='flex h-[12rem] w-full flex-col justify-center gap-1.5 px-2.5'>
      <FormInput
        id='name'
        autoFocus
        labelText={showConnectorNameScreen ? 'Name' : labelText(dataSource)}
        placeholder={`Enter ${showConnectorNameScreen ? 'Name' : labelText(dataSource).toLocaleLowerCase()}`}
        value={nameField}
        handleChange={handleNameChange}
        showInfoIcon={true}
        infoContent={
          showConnectorNameScreen
            ? { text: CONNECTOR_NAME_SCREEN }
            : capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.WOO_COMMERCE
              ? { text: WOO_COMMERCE_TEXT }
              : { text: BING_ADS_TEXT }
        }
      />
    </div>
  );
}
