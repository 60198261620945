import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { isEmpty } from 'lodash';
import { ChevronRight, LogOut, Settings } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { Button, CircleProgress, SharedTooltip } from '@common/components';
import { ROUTES } from '@routes';
import { COLORS, ROLES } from '@constants';
import { SubscriptionIcon, TokenCoin } from '@assets/svgs';

export const SidebarFooter = ({
  userDetail,
  userDetailFetching,
  membershipPlan,
  consumePercentage,
  showSubscriptionText,
  showUpgradeBtn,
  showAiToken,
  openSidebar,
  visible,
  setVisible,
  handleLogout,
  menuRef,
  userOrganizationRole,
  closePopover,
  calculateTrialDays,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      {userOrganizationRole !== ROLES.MEMBER && (
        <div className={`flex flex-col gap-6 border-b border-secondaryStrokeButton py-6 ${openSidebar && 'pb-28'}`}>
          {!isEmpty(membershipPlan?.data?.results) && (
            <>
              {openSidebar ? (
                <div className='mx-6 flex w-[14.5rem] flex-col items-center rounded-lg border border-secondaryStrokeButton p-4'>
                  <SubscriptionIcon className='h-10 w-[3.25rem]' />
                  <h4 className='pt-2 text-base font-medium leading-[1.375rem] text-mainText'>
                    {userDetail?.data?.subscriptionName}
                  </h4>
                  <p className='pt-1 text-center text-xs leading-5 text-bodyText'>
                    {membershipPlan?.data?.results[0].trialEndDate ? (
                      <>
                        Free trial ends in{' '}
                        <span className='end-subscription-gradient font-bold'>
                          {calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate)}{' '}
                          {calculateTrialDays(membershipPlan?.data?.results[0].trialEndDate) === 1 ? 'day!' : 'days!'}
                        </span>
                        <br />
                        For more features upgrade your plan.
                      </>
                    ) : (
                      showSubscriptionText(userDetail, membershipPlan?.data?.results)
                    )}
                  </p>
                  {showUpgradeBtn(userDetail, membershipPlan?.data?.results) ? (
                    <Link className='pt-3' to={`${ROUTES.PROFILE}/${ROUTES.MEMBERSHIPS_TAB}`}>
                      <Button className='w-[10rem]'>Manage</Button>
                    </Link>
                  ) : null}
                </div>
              ) : (
                <SharedTooltip title={'Membership'}>
                  <div
                    className='mx-auto flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-secondaryStrokeButton'
                    onClick={() => navigate(`${ROUTES.PROFILE}/${ROUTES.MEMBERSHIPS_TAB}`)}
                  >
                    <SubscriptionIcon className='h-5 w-[1.625rem]' />
                  </div>
                </SharedTooltip>
              )}
            </>
          )}
          {showAiToken && (
            <>
              {openSidebar ? (
                <div
                  className='mx-6 flex w-[14.5rem] cursor-pointer items-center gap-2.5 rounded-2xl bg-shade3 px-4 py-3'
                  onClick={() => navigate(`${ROUTES.PROFILE}/${ROUTES.AI_TOKENS_TAB}`)}
                >
                  <div className='flex w-full flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                      <TokenCoin />
                      <h6 className='font-Manrope text-sm font-bold leading-5 text-mainText'>AI Credits</h6>
                    </div>
                    <span className='font-Manrope text-xs leading-4 text-bodyText'>
                      {Math.min(consumePercentage, 100) || 0}% used
                    </span>
                  </div>
                  <CircleProgress value={Math.min(consumePercentage, 100) || 0} />
                </div>
              ) : (
                <SharedTooltip title={'AI Tokens'}>
                  <div
                    className='relative mx-auto cursor-pointer'
                    onClick={() => navigate(`${ROUTES.PROFILE}/${ROUTES.AI_TOKENS_TAB}`)}
                  >
                    <CircleProgress value={Math.min(consumePercentage, 100) || 0} />
                    <TokenCoin className='absolute left-1/2 top-1/2 m-auto -translate-x-1/2 -translate-y-1/2' />
                  </div>
                </SharedTooltip>
              )}
            </>
          )}
        </div>
      )}
      <Popover
        placement='rightBottom'
        overlayStyle={{ bottom: '24px', left: openSidebar ? '264px' : '70px' }}
        trigger='click'
        open={visible}
        onOpenChange={status => setVisible(status)}
        content={
          <div ref={menuRef} className='flex w-[172px] flex-col gap-0.5 rounded-xl'>
            <NavLink onClick={closePopover} className='flex w-full items-center gap-2' to={ROUTES.PROFILE}>
              <div className='flex w-full cursor-pointer items-center gap-2 rounded-lg px-3 py-2 hover:bg-shade4'>
                <Settings className='h-5 w-5' color={COLORS.ICON_COLOR} />
                Settings
              </div>
            </NavLink>
            <div
              onClick={handleLogout}
              className='flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 hover:bg-shade4'
            >
              <LogOut className='h-5 w-5' color={COLORS.ERROR_COLOR} />
              Logout
            </div>
          </div>
        }
        arrow={false}
      >
        <div
          className={`px-[1.125rem] py-6 ${openSidebar && 'fixed bottom-0 w-[17.4375rem] border-t border-secondaryStrokeButton bg-white'}`}
        >
          <div className='h-10 w-10 rounded-full'>
            <div className='cursor-pointer' onClick={() => setVisible(true)}>
              <div className='flex items-center gap-3'>
                {userDetailFetching ? (
                  <Skeleton circle={true} className='h-10 w-10' />
                ) : (
                  <img
                    src={userDetail?.data?.profileImage?.image}
                    alt='profile'
                    className='h-full w-full rounded-full object-cover'
                  />
                )}
                {openSidebar && (
                  <>
                    <div className='flex min-w-[9.25rem] flex-col gap-0.5'>
                      <span className='whitespace-nowrap font-Manrope text-xs leading-4 text-bodyText'>
                        Welcome back 👋
                      </span>
                      <h6 className='whitespace-nowrap font-Manrope text-sm font-bold leading-5 text-mainText'>
                        {`${userDetail?.data?.firstName} ${userDetail?.data?.lastName}`}
                      </h6>
                    </div>
                    <ChevronRight className='h-5 min-w-5' color={COLORS.ICON_COLOR} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};
