import { NoDataFoundScreen } from '@common/components/NoDataFoundScreen';
import { Modal, Skeleton, Table } from 'antd';
import { Plus, Trash2, X } from 'lucide-react';
import { useInviteMembersTour } from '../hooks/useInviteMembersTour';
import { TourButton, Button, TableSkeletonLoader, DeleteDialog, CustomPagination } from '@common/components';
import { InviteMemberDialog, useMembersTab } from '@pages/manageOrganization';
import { capitalizeString } from '@utils';
import { COLORS, INVITE_MEMBER_BUTTON } from '@constants';

export function MembersTab({ currentTab }) {
  const {
    teamListing,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    showInviteMemberDialog,
    setShowInviteMemberDialog,
    emailFieldErrorMessage,
    organizationMembers,
    showDeleteMemberDialog,
    setShowDeleteMemberDialog,
    setMember,
    member,
    openInviteMemberDialogue,
    inviteMemberLoading,
    deleteMemberLoading,
    setDeleteMemberLoading,
    invalidEmails,
    organizationMembersFetching,
    handleOrganizationMemberDelete,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  } = useMembersTab(currentTab);

  const { inviteMemberTour } = useInviteMembersTour();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        organizationMembersFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <img src={record?.profileImage?.image} alt='dashboard' className=' h-10 w-10 rounded-full object-contain' />
            <p className='text-sm font-medium leading-5 text-mainText'>{text}</p>
          </div>
        ),
    },
    {
      title: 'Account',
      dataIndex: 'email',
      key: 'email',
      render: text =>
        organizationMembersFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='font text-sm leading-5 text-bodyText'>{text}</p>
        ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text =>
        organizationMembersFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='text-sm leading-5 text-bodyText'>{capitalizeString(text)}</p>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) =>
        organizationMembersFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <Trash2
            className='h-5 w-5'
            color={COLORS.ERROR_COLOR}
            onClick={e => {
              e.stopPropagation();
              setMember(record);
              setShowDeleteMemberDialog(true);
              setDeleteMemberLoading(false);
            }}
          />
        ),
    },
  ];

  return (
    <div className=''>
      <div>
        <div className='absolute right-7 top-[18px] z-10'>
          <Button onClick={openInviteMemberDialogue} id={INVITE_MEMBER_BUTTON} className='w-[11.25rem]' size='lg'>
            <Plus className='h-5 w-5' color='white' />
            Invite Members
          </Button>
          <Modal
            footer={null}
            width={420}
            closeIcon={<X color={COLORS.ICON_COLOR} />}
            open={showInviteMemberDialog}
            centered={true}
            onCancel={() => setShowInviteMemberDialog(false)}
          >
            <InviteMemberDialog
              inviteMemberLoading={inviteMemberLoading}
              teamListing={teamListing}
              formik={formik}
              onEnter={handleInvitedEmailKeyDown}
              invitedEmails={invitedEmails}
              onCrossBtnClick={handleRemoveInvitedEmail}
              emailFieldErrorMessage={emailFieldErrorMessage}
              invalidEmails={invalidEmails}
            />
          </Modal>
        </div>
        <div className=''>
          {organizationMembersFetching ? (
            <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto rounded-b-xl'>
              <TableSkeletonLoader columns={columns} rowCount={12} />
            </div>
          ) : organizationMembers?.data?.results?.length === 0 || !organizationMembers ? (
            <div className='h-[calc(100vh-300px)] min-h-[372px] overflow-auto'>
              <NoDataFoundScreen />
            </div>
          ) : (
            <div className='flex h-full flex-col'>
              <Table
                columns={columns}
                dataSource={organizationMembers?.data?.results}
                rowKey='id'
                rowClassName='cursor-pointer'
                className='table-bold-thin h-[calc(100vh-340px)] min-h-[372px] overflow-auto'
                pagination={false}
              />
              <CustomPagination
                page={page}
                setPage={setPage}
                data={organizationMembers}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        open={showDeleteMemberDialog}
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        centered={true}
        onCancel={() => setShowDeleteMemberDialog(false)}
      >
        <DeleteDialog
          text={member?.name}
          textClass='!mb-4'
          deleteBtnClick={e => {
            e.stopPropagation();
            setDeleteMemberLoading(true);
            handleOrganizationMemberDelete();
          }}
          goBackBtnClick={() => setShowDeleteMemberDialog(false)}
          isLoading={deleteMemberLoading}
        />
      </Modal>
      <TourButton Label='“Invite Members”' onClick={() => inviteMemberTour().drive()} />
    </div>
  );
}
