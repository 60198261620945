import { BaseLoader, Button, Loader } from '@common/components';
import { useAIToken } from '@pages/profile';
import { INSTRUCTION_DATA } from '@constants';

export function AiTokensTab() {
  const {
    aiTokenListing,
    handleAiTokenCheckout,
    aiTokenListingFetching,
    aiTokenCheckoutLoading,
    setSelectedTokenId,
    selectedTokenId,
  } = useAIToken();
  const planNames = ['Basic', 'Growth', 'Premium'];

  return (
    <div className='h-[calc(100vh-228px)] min-h-[372px] overflow-auto overflow-y-auto rounded-b-xl bg-white scrollbar-none'>
      {aiTokenListingFetching ? (
        <BaseLoader />
      ) : (
        <>
          <div className='flex items-center justify-between border-b p-6 font-Manrope'>
            <div className='flex flex-col gap-1'>
              <h2 className='text-2xl font-bold'>AI Credits</h2>
              <p className='text-sm font-normal text-bodyText'>
                Unlock Your Potential: Choose the Perfect Plan for You!
              </p>
            </div>
          </div>
          <div className='flex justify-between gap-6 px-6 pt-6 font-Manrope'>
            {aiTokenListing?.data?.results?.map((token, index) => (
              <div key={token.id} className='w-full rounded-xl border p-6'>
                <div className='flex flex-col gap-2'>
                  <p className='text-[1rem] font-medium leading-[1.375rem]'>{planNames[index]}</p>
                  <span className='text-xl font-semibold'>{token.nickname}</span>
                  <h3 className='text-[2rem] font-extrabold leading-[2.75rem] text-highlighterText1'>
                    ${token?.unitAmount / 100}
                  </h3>
                </div>
                <p className='pt-3 text-[0.875rem] font-normal leading-5'>
                  {JSON.parse(token?.metadata.key).description}
                </p>
                <div className='pt-6 font-semibold'>
                  <Button
                    size='sm'
                    className='gradientButton'
                    onClick={() => {
                      setSelectedTokenId(token?.id);
                      handleAiTokenCheckout({ payload: { priceId: token?.id } });
                    }}
                    disabled={aiTokenCheckoutLoading}
                  >
                    {aiTokenCheckoutLoading && selectedTokenId === token?.id ? <Loader size='sm' /> : 'Buy Now'}
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className='px-6 font-Manrope'>
            {!aiTokenListingFetching && (
              <div className='mb-5 mt-6 w-full'>
                <h2 className='mb-4 text-[1.5rem] font-bold leading-[2.125rem]'>Instruction</h2>
                <ul className='flex flex-col gap-3 pl-4'>
                  {INSTRUCTION_DATA.map(data => (
                    <li key={data.id} className='instruction-list-item text-sm font-normal text-bodyText'>
                      {data.instruction}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
