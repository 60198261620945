import { WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import zendashDashVideoPlaceholder from '@assets/images/zendashVideoPlaceholder.png';

export function VideoWidget({
  widget,
  onRemoveClick,
  handleVideoInputChange,
  isSharedDashboard,
  handleEditWidget,
  handleClone,
  isReadOnly,
  titleText,
  headingText,
  inputText,
  handleEditCommentMode,
  hideTooltip,
  onSlideMode,
  setIsPopUpMenuOpen,
}) {
  return (
    <div className={`flex h-full flex-col ${onSlideMode ? 'gap-0' : 'gap-2'}`}>
      <div
        className={`flex items-center justify-between gap-3 ${onSlideMode && 'h-2 leading-[unset]'} ${isSharedDashboard ? 'h-10' : 'h-6'}`}
      >
        {widget?.showLabel && (
          <WidgetTitle
            titleText={titleText}
            widget={widget}
            hideTooltip={hideTooltip}
            isSharedDashboard={isSharedDashboard}
          />
        )}
        {!isSharedDashboard && !onSlideMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
            handleEditCommentMode={() => handleEditCommentMode(widget)}
            setIsPopUpMenuOpen={setIsPopUpMenuOpen}
          />
        )}
      </div>
      {!widget.url && !isSharedDashboard && !onSlideMode && (
        <>
          <h6
            className={`text-left ${headingText}`}
            style={
              widget?.themeColor === DEFAULT_VALUE
                ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
            }
          >
            Add Link
          </h6>
          <input
            type='text'
            placeholder='Paste your link here'
            onChange={e => handleVideoInputChange(e, widget)}
            className={`${isReadOnly ? 'pointer-events-none' : ''} ${onSlideMode ? 'mt-2 px-1 py-0 text-[0.375rem]' : ' px-3 py-2 text-xs'} mt-2 rounded border border-highlighterText1  leading-[0.9075rem] focus:outline-none focus:ring-0
              ${inputText}`}
          />
        </>
      )}
      {!!widget.url && ![isSharedDashboard, onSlideMode].includes(true) && (
        <div className='h-full'>
          <iframe
            width='100%'
            height='100%'
            src={widget.url}
            title='Zendash video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>
      )}
      {[isSharedDashboard, onSlideMode].includes(true) && (
        <div className='h-full w-full'>
          <img src={zendashDashVideoPlaceholder} className='h-full w-full' />
        </div>
      )}
    </div>
  );
}
