import { useState } from 'react';
import { Popover } from 'antd';
import { Copy, Edit, EllipsisVertical, Trash2 } from 'lucide-react';
import { COLORS } from '@constants';

export function GoalActionPopover({
  setShowDeleteDialog,
  showDeleteDialog,
  setGoalDetail,
  goal,
  showEditDialog,
  setShowEditDialog,
  setEditGoalDetail,
  setDashboardGoal,
  optionClass,
}) {
  const [open, setOpen] = useState(false);
  const hide = () => setOpen(false);
  const handleOpenChange = newOpen => setOpen(newOpen);

  const popoverContent = (
    <div onClick={e => e.stopPropagation()} className='max-h-[9.25rem] w-[12.75rem] overflow-y-auto'>
      <ul className='flex cursor-pointer flex-col'>
        <li
          className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
          onClick={() => {
            hide();
            setEditGoalDetail(goal);
            setShowEditDialog({ ...showEditDialog, [goal.id]: true });
          }}
        >
          <Edit color={COLORS.BODY_TEXT_COLOR} className='h-5 w-5' />
          Edit
        </li>
        <li
          className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
          onClick={() => {
            hide();
            setDashboardGoal(goal);
          }}
        >
          <Copy color={COLORS.BODY_TEXT_COLOR} className='h-5 w-5' />
          Add to Dashboard
        </li>
        <li
          className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
          onClick={() => {
            hide();
            setGoalDetail(goal);
            setShowDeleteDialog({ ...showDeleteDialog, [goal.id]: true });
          }}
        >
          <Trash2 className='h-5 w-5' color={COLORS.ERROR_TOOLTIP} />
          Delete
        </li>
      </ul>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger='click'
      placement='bottomRight'
      overlayClassName='h-[9.25rem] w-[13.75rem]'
      open={open}
      onOpenChange={handleOpenChange}
    >
      <EllipsisVertical className={`${optionClass} cursor-pointer`} />
    </Popover>
  );
}
