import { Modal, Skeleton, Table } from 'antd';
import { X } from 'lucide-react';
import { DeleteDialog, GoalTrackingProgress, NoDataFoundScreen } from '@common/components';
import { CreateEditGoalDrawer, GoalActionPopover } from '@pages/goalTracking';
import { COLORS, TIME_PERIOD } from '@constants';

export function GoalTrackTabularView({
  goalsListing,
  goalDetail,
  setGoalDetail,
  setEditGoalDetail,
  handleGoalDelete,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteGoalLoading,
  setDeleteGoalLoading,
  showEditDialog,
  setShowEditDialog,
  widgetListing,
  formik,
  dashboardsListing,
  isLoading,
  setSelectedDashboardId,
  setDashboardGoal,
  goalListingFetching,
  showBaseLoader,
  noSearchResults,
}) {
  const skeletonRows = Array.from({ length: 7 }).map((_, index) => ({
    key: index,
    name: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
    dashboard: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
    widget: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
    progress: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
    timePeriod: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
    actions: <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />,
  }));

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: text =>
        goalListingFetching ? <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} /> : <p>{text}</p>,
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      dataIndex: 'dashboardName',
      render: text =>
        goalListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='text-bodyText'>{text}</p>
        ),
    },
    {
      title: 'Widget',
      key: 'widget',
      dataIndex: 'widgetLabel',
      render: (text, record) =>
        goalListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p id={`label-${record?.id}`} className='text-bodyText'>
            {text}
          </p>
        ),
    },
    {
      title: 'Progress',
      key: 'progress',
      render: (text, record) =>
        goalListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <GoalTrackingProgress goal={record} />
        ),
    },
    {
      title: 'Time Period',
      key: 'timePeriod',
      render: (text, record) =>
        goalListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='font-normal text-bodyText'>
            {TIME_PERIOD.find(time => time.id === record?.periodInDays)?.name || '-'}
          </p>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) =>
        goalListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <GoalActionPopover
            goal={record}
            setShowDeleteDialog={setShowDeleteDialog}
            showDeleteDialog={showDeleteDialog}
            setGoalDetail={setGoalDetail}
            showEditDialog={showEditDialog}
            setShowEditDialog={setShowEditDialog}
            setEditGoalDetail={setEditGoalDetail}
            setDashboardGoal={setDashboardGoal}
            optionClass={'h-[0.875rem] text-icon1 top-[0.75rem] right-[10px]'}
          />
        ),
    },
  ];

  return (
    <div>
      {noSearchResults ? (
        <NoDataFoundScreen />
      ) : (
        <div className='h-full'>
          <Table
            columns={columns}
            dataSource={goalListingFetching ? skeletonRows : goalsListing?.data?.results}
            rowKey='id'
            rowClassName='cursor-pointer'
            className='table-bold-thin h-[calc(100vh-488px)] min-h-[372px] overflow-auto'
            pagination={false}
          />
        </div>
      )}

      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
      >
        <DeleteDialog
          text={goalDetail?.name}
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleGoalDelete();
            setDeleteGoalLoading(true);
          }}
          goBackBtnClick={() => setShowDeleteDialog(false)}
          isLoading={deleteGoalLoading}
        />
      </Modal>

      <CreateEditGoalDrawer
        title='Edit Goal'
        formik={formik}
        widgetListing={widgetListing}
        dashboardsListing={dashboardsListing}
        isLoading={isLoading}
        setSelectedDashboardId={setSelectedDashboardId}
        showDrawer={showEditDialog}
        setShowDrawer={setShowEditDialog}
        showBaseLoader={showBaseLoader}
      />
    </div>
  );
}
