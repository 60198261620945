import { debounce } from 'lodash';
import { Search } from 'lucide-react';
import { SearchInput } from '@common/components';
import { COLORS } from '@constants';

export function DataSourceSearch({ setSearch }) {
  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  return (
    <SearchInput
      placeholder='Search'
      onChange={handleSearchChange}
      size='lg'
      className='pl-11'
      leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
    />
  );
}
