import { Plus } from 'lucide-react';
import { Button } from '@common/components';
import { CREATE_GOAL_BUTTON_ID } from '@constants';
import { NoGoalsImage } from '@assets/svgs';

export const NoGoalsView = ({ handleClick = () => null }) => {
  return (
    <div className='flex h-[33.25rem] items-center justify-center'>
      <div className='flex flex-col items-center gap-6'>
        <NoGoalsImage />
        <div className='flex flex-col items-center gap-3'>
          <h1 className='text-xl font-semibold'>No goals set yet!</h1>
          <p className='text-sm font-normal'>
            Start by creating your first goal to track progress and achieve success.
          </p>
        </div>
        <Button size='lg' className='w-[10.625rem]' onClick={handleClick} id={CREATE_GOAL_BUTTON_ID}>
          <Plus className='h-5 w-5' color='white' />
          Create New
        </Button>
      </div>
    </div>
  );
};
