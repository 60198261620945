import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Popover } from 'antd';
import { Copy, Edit, EllipsisVertical, Trash2 } from 'lucide-react';
import { ROUTES } from '@routes';
import { COLORS } from '@constants';

export function DashboardActionPopover({
  dashboard,
  setDashboardId,
  handleDuplicateDashboard,
  setSelectedDashboard,
  setShowDeleteDialog,
  showDeleteDialog,
  isMember,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div onClick={e => e.stopPropagation()} className='max-h-[10.75rem] w-[9.375rem] overflow-y-auto'>
          <ul className='flex cursor-pointer flex-col'>
            <li
              className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
              onClick={e => {
                e.stopPropagation();
                navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: dashboard?.id }), {
                  state: { showEditMode: true },
                });
              }}
            >
              <Edit color={COLORS.BODY_TEXT_COLOR} className='h-5 w-5' />
              Edit
            </li>
            <li
              className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
              onClick={e => {
                e.stopPropagation();
                handleDuplicateDashboard({});
              }}
            >
              <Copy color={COLORS.BODY_TEXT_COLOR} className='h-5 w-5' />
              Duplicate
            </li>
            <li
              className='inline-flex items-center gap-2 rounded-lg p-3 text-sm leading-5 text-bodyText hover:bg-shade3'
              onClick={e => {
                e.stopPropagation();
                hide();
                setSelectedDashboard(dashboard);
                setShowDeleteDialog({ ...showDeleteDialog, [dashboard]: true });
              }}
            >
              <Trash2 className='h-5 w-5' color={COLORS.ERROR_TOOLTIP} />
              Delete
            </li>
          </ul>
        </div>
      }
    >
      <div
        onClick={e => {
          e.stopPropagation();
          setDashboardId(dashboard?.id);
        }}
        className={isMember ? 'pointer-events-none' : ''}
      >
        <EllipsisVertical color={isMember ? COLORS.TINT_GRAY : COLORS.ICON_COLOR} />
      </div>
    </Popover>
  );
}
