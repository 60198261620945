import { generatePath, Link, useParams } from 'react-router-dom';
import { PlugZap } from 'lucide-react';
import { Button } from '@common/components';
import { ROUTES } from '@routes';
import { DataSourceIntegrationIcon } from '@assets/svgs';

export function ConnectDatasource() {
  const { organizationId } = useParams();
  return (
    <div className='flex flex-col items-center gap-10 py-[214px]'>
      <DataSourceIntegrationIcon />
      <div className='flex flex-col items-center gap-10'>
        <div className='flex w-[25rem] flex-col items-center gap-3'>
          <h3 className='text-2xl font-semibold text-mainText'>Connect Data Source</h3>
          <p className='text-center text-sm leading-5 text-bodyText'>
            Please first connect your data source to create dashboard
          </p>
        </div>
        <Link to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
          <Button size='lg' className='w-[17.875rem]'>
            <PlugZap className='h-5 w-5' color='white' />
            Connect Data Source
          </Button>
        </Link>
      </div>
    </div>
  );
}
