import { useContext, useEffect, useRef } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Shrink } from 'lucide-react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Slider from 'react-slick';
import {
  BarChartWidget,
  CounterWidget,
  CsvWidget,
  GoalWidget,
  ImageWidget,
  LineChartWidget,
  MapWidget,
  PieChartWidget,
  ProgressWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetTopHead,
} from '@common/widgets';
import { useDashboardFullView } from '@pages/dashboard';
import { ROUTES } from '@routes';
import { AppContext } from '@useContext';
import { calculateFullViewHeight } from '@utils';
import { COLORS, DEFAULT_VALUE, STATIC, THEME_STYLE, WIDGETS_TYPE, ARROW_KEYS } from '@constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardFullView() {
  const { dashboard, organizationId, dashboardId } = useDashboardFullView();

  const sliderRef = useRef(null);

  const navigate = useNavigate();

  const { screenHeight } = useContext(AppContext);

  useEffect(() => {
    const sliderElement = sliderRef.current;

    const handleKeyDown = event => {
      if (event.keyCode === ARROW_KEYS.RIGHT) {
        sliderElement.slickNext();
      } else if (event.keyCode === ARROW_KEYS.LEFT) {
        sliderElement.slickPrev();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    infinite: dashboard?.data?.dashboardConfig?.length > 1,
    arrows: false,
  };

  useEffect(() => {
    const handleEscKey = event => {
      if (event.keyCode === ARROW_KEYS.ESCAPE) {
        navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId }));
      }
    };
    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [navigate, organizationId, dashboardId]);

  return (
    <div
      className={`relative z-0 min-h-screen w-[100vw] space-y-[4.5rem] px-10 py-8 ${dashboard?.data?.isTextureApplied ? 'bg-[url("/src/assets/images/SlidesImgBg.png")]' : 'bg-white'}`}
    >
      <div
        className='absolute left-0 top-0 z-[-1] h-full w-full opacity-[.4]'
        style={dashboard?.data?.isTextureApplied ? { backgroundColor: dashboard?.data?.headerColor } : {}}
      />
      <div className='!mt-0 flex w-full justify-end'>
        <Link
          to={generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId })}
          className='flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-secondaryStrokeButton p-1 hover:bg-slateGray'
        >
          <Shrink />
        </Link>
      </div>
      <div className='slider-container'>
        <Slider ref={sliderRef} {...settings} className='bg-transparent'>
          {dashboard?.data?.dashboardConfig?.map((item, i) => (
            <div key={i} className='!max-h-[calc(100vh-15.1rem)] overflow-hidden'>
              {!isEmpty(dashboard?.data?.dashboardConfig) && (
                <ResponsiveGridLayout
                  className='layout flex w-full flex-wrap items-start gap-2.5'
                  layouts={{ lg: item }}
                  rowHeight={calculateFullViewHeight(screenHeight)}
                  isResizable={false}
                  isDraggable={false}
                  cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
                  breakpoints={{ lg: 1440, md: 1024, sm: 768, xs: 500, xxs: 0 }}
                  margin={{ xxs: [0, 16] }}
                >
                  {item?.map((widget, index) => (
                    <div key={widget?.i} className={`${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'text-widget' : ''}`}>
                      <div
                        className={`h-full w-full overflow-hidden overflow-y-auto border border-widgetBorderColor px-4 py-5 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none ${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'rounded-[0.375rem] px-6 py-4' : ''}`}
                        style={
                          widget?.themeColor === DEFAULT_VALUE
                            ? {
                              backgroundColor:
                                  widget.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.WHITE_COLOR,
                            }
                            : { backgroundColor: widget?.background }
                        }
                      >
                        <div
                          className={`h-full w-full ${[WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.TABLE].includes(widget?.id?.split('-')[0]) ? '' : 'pointer-events-none'}`}
                        >
                          {!widget?.connectorName?.includes(STATIC) && <WidgetTopHead widget={widget} />}
                          {widget?.id?.includes(WIDGETS_TYPE.GOAL) && (
                            <div className='h-[calc(100%-6rem)] w-full'>
                              <GoalWidget widget={widget} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.COUNTER) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full'>
                              <CounterWidget index={index} value={widget?.widgetData?.data} widget={widget} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.BAR_CHART) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full'>
                              <BarChartWidget widget={widget} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.LINE_CHART) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full'>
                              <LineChartWidget widget={widget} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PIE_CHART) && (
                            <div className='flex h-[calc(100%-3.4375rem)] w-full flex-col'>
                              <PieChartWidget
                                widget={widget}
                                isComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                              />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.PROGRESS_BAR) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto scrollbar-none'>
                              <ProgressWidget widget={widget} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.TABLE) && (
                            <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto scrollbar-none'>
                              <TableWidget widget={widget} page={widget?.page} />
                            </div>
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.MAP) && <MapWidget widget={widget} />}
                          {widget?.id?.includes(WIDGETS_TYPE.TEXT) && <TextWidget widget={widget} />}
                          {widget?.id?.includes(WIDGETS_TYPE.VIDEO) && (
                            <VideoWidget isReadOnly={true} widget={widget} />
                          )}
                          {widget?.id?.includes(WIDGETS_TYPE.IMAGE) && <ImageWidget widget={widget} />}
                          {widget?.id?.includes(WIDGETS_TYPE.CSV) && <CsvWidget widget={widget} page={widget?.page} />}
                        </div>
                      </div>
                    </div>
                  ))}
                </ResponsiveGridLayout>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
