import { useEffect, useState } from 'react';
import { Calendar } from '@shadcnComponent';
import { Popover, Select } from 'antd';
import { X } from 'lucide-react';
import moment from 'moment';
import { FormInput, BaseDatePicker, FormSelect, Button } from '@common/components';
import { useDebounce } from '@common/hooks';
import {
  CHART_CONVERSION,
  COLORS,
  DATE_FORMAT,
  DATE_RANGE_DROP_DOWN,
  DATE_RANGE_OPTIONS,
  WIDGETS_TYPE,
} from '@constants';

export function GeneralTab({
  editWidget,
  setEditWidget,
  editWidgetDateRange,
  setEditWidgetDateRange,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeSet,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  handleEditWidgetRangeDateFilter,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeEnd,
  setEditWidgetCompareRangeStart,
  handleDateRangeChange,
  showDatePicker,
  setShowDatePicker,
  handleCommentUpdate,
}) {
  const [inputs, setInputs] = useState({});

  const handleInputChange = (key, value) => {
    setInputs(prev => ({ ...prev, [key]: value }));
  };

  const handleWidgetUpdate = (key, value, callback) => {
    if (value) {
      setEditWidget(prev => ({ ...prev, [key]: value }));
      if (callback) {
        callback({ payload: { widgetLabel: value } });
      }
    }
  };

  const debouncedInputs = {
    title: useDebounce(inputs.title, 500),
    tooltip: useDebounce(inputs.tooltip, 500),
  };

  useEffect(() => {
    if (editWidget)
      setInputs({
        title: editWidget?.displayLabel || '',
        tooltip: editWidget?.tooltip || '',
      });
  }, [editWidget]);

  useEffect(() => {
    handleWidgetUpdate('displayLabel', debouncedInputs.title, handleCommentUpdate);
  }, [debouncedInputs.title]);

  useEffect(() => {
    handleWidgetUpdate('tooltip', debouncedInputs.tooltip);
  }, [debouncedInputs.tooltip]);

  return (
    <div className='flex flex-col gap-6'>
      <FormInput
        labelText='Title'
        placeholder='Enter title'
        value={inputs.title}
        onChange={e => handleInputChange('title', e.target.value)}
      />
      <FormInput
        labelText='Tooltip'
        placeholder='Enter tooltip'
        value={inputs.tooltip}
        onChange={e => handleInputChange('tooltip', e.target.value)}
      />
      {[WIDGETS_TYPE.BAR_CHART, WIDGETS_TYPE.LINE_CHART, WIDGETS_TYPE.PROGRESS_BAR, WIDGETS_TYPE.PIE_CHART].includes(
        editWidget?.id?.split('-')[0]
      ) ? (
          <div className='flex flex-wrap gap-2.5'>
            {CHART_CONVERSION.map((chart, index) => (
              <div
                key={index}
                className={`gap-.5 flex w-[4.375rem] cursor-pointer flex-col items-center rounded-xl border border-secondaryStrokeButton p-2 text-xs leading-4 text-darkTextColor ${chart.value === editWidget?.id?.split('-')[0] ? 'border-highlighterText2 bg-shade4' : ''}`}
                onClick={() =>
                  setEditWidget({
                    ...editWidget,
                    id: editWidget?.id.replace(editWidget?.id?.split('-')[0], chart.value),
                  })
                }
              >
                <chart.Icon className='h-8 w-8' />
                {chart.label}
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      {![WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE, WIDGETS_TYPE.TEXT, WIDGETS_TYPE.CSV].includes(
        editWidget?.id?.split('-')[0]
      ) &&
        editWidget?.connectorName !== WIDGETS_TYPE.GOAL && (
        <div className='relative flex flex-col gap-1.5'>
          <label className='relative font-Manrope text-sm leading-5 text-labelText'>Date Range</label>

          <Select
            placeholder='Select an date range'
            value={editWidget?.dateRange || null}
            onChange={handleDateRangeChange}
            popupClassName='form-select-options p-2'
            className='form-select h-[unset] w-full'
            dropdownRender={menu => (
              <div onClick={e => e.stopPropagation()}>
                {DATE_RANGE_DROP_DOWN.length === 0 ? (
                  <div className='rounded-md bg-disableInput p-2 text-left text-sm leading-5 text-icon1'>
                      Select date range
                  </div>
                ) : (
                  menu
                )}
              </div>
            )}
          >
            {DATE_RANGE_DROP_DOWN?.slice(0, 4).map(option => (
              <Select.Option key={option?.id} value={option?.id}>
                <div className='!bg-[unset] p-3 text-left text-sm leading-5 text-bodyText hover:bg-shade4'>
                  {option?.name}
                </div>
              </Select.Option>
            ))}
            <Select.Option
              key={DATE_RANGE_OPTIONS.CUSTOM}
              value={DATE_RANGE_OPTIONS.CUSTOM}
              onMouseDown={e => {
                e.stopPropagation();
                setShowDatePicker(true);
              }}
            >
              <div className='!hover:!bg-[unset] !bg-[unset] p-3 text-left text-sm leading-5 text-bodyText '>
                  Custom
              </div>
            </Select.Option>
          </Select>

          {showDatePicker && (
            <Popover
              open={showDatePicker}
              overlayClassName='calendar-popover'
              content={
                <div className='flex'>
                  <span
                    className='absolute right-[12px] top-[12px] cursor-pointer'
                    onClick={() => setShowDatePicker(false)}
                  >
                    <X className='h-5 w-5' color={COLORS.ICON_COLOR} />
                  </span>

                  <Calendar
                    initialFocus
                    mode='range'
                    today={false}
                    selected={editWidgetDateRange}
                    onSelect={value => {
                      setEditWidgetDateRange(value);
                      if (value?.to) {
                        let payload = {
                          ...editWidget,
                          dateRange: 'CUSTOM',
                          startDate: moment(value?.from).format(DATE_FORMAT.RANGE_DATE),
                          endDate: moment(value?.to).format(DATE_FORMAT.RANGE_DATE),
                        };
                        editWidget?.startDate1 && delete payload['startDate1'];
                        editWidget?.endDate1 && delete payload['endDate1'];
                        editWidget?.startDate2 && delete payload['startDate2'];
                        editWidget?.endDate2 && delete payload['endDate2'];
                        editWidget?.comparisonDateRange && delete payload['comparisonDateRange'];
                        editWidget?.comparisonCompareTo && delete payload['comparisonCompareTo'];
                        editWidget?.isWidgetComparisonFilterApply && delete payload['isWidgetComparisonFilterApply'];
                        setEditWidget(payload);
                        setShowDatePicker(false);
                      }
                    }}
                    numberOfMonths={2}
                  />

                  {/* SIDE CALENDER */}
                  <div className='flex w-[234px] flex-col justify-between border-l border-secondaryStrokeButton pt-3'>
                    <div className='flex flex-col gap-2 px-4'>
                      <p className='font-Manrope text-sm font-bold leading-5 text-mainText'>Date Range</p>
                      <div className='flex flex-col md:flex-row md:items-end md:justify-between md:gap-3 lg:flex-col lg:items-start lg:gap-2 '>
                        <FormSelect
                          placeholder='Select date range'
                          value={editWidgetDateRangeSet}
                          handleChange={handleSelectEditWidgetDateRange}
                          options={DATE_RANGE_DROP_DOWN}
                          className='date-range-select gap-2'
                          triggerClassName='w-[12.625rem]'
                        />
                        <div className='flex w-full gap-1'>
                          <BaseDatePicker
                            className='w-[6.25rem]'
                            date={editWidgetDateRangeStart}
                            setDate={setEditWidgetDateRangeStart}
                            disabled={editWidgetDateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          />
                          <BaseDatePicker
                            className='w-[6.25rem]'
                            date={editWidgetDateRangeEnd}
                            setDate={setEditWidgetDateRangeEnd}
                            disabled={editWidgetDateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col gap-2 px-4'>
                      <p className='font-Manrope text-sm font-bold leading-5 text-mainText'>Compare To</p>
                      <div className='flex flex-col justify-between md:flex-row md:items-end md:gap-3 lg:flex-col lg:items-start lg:gap-2'>
                        <FormSelect
                          placeholder='Select date range'
                          value={editWidgetCompareRangeSet}
                          handleChange={handleSelectEditWidgetCompareRange}
                          options={DATE_RANGE_DROP_DOWN}
                          className='date-range-select gap-2'
                          triggerClassName='w-[12.625rem]'
                        />
                        <div className='mt-3 flex w-full gap-[0.5625rem] md:mt-0 md:w-[15.8125rem] lg:w-[16.25rem] xxl:mt-0'>
                          <BaseDatePicker
                            className='w-[6.25rem]'
                            date={editWidgetCompareRangeStart}
                            setDate={setEditWidgetCompareRangeStart}
                            disabled={editWidgetCompareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          />
                          <BaseDatePicker
                            className='w-[6.25rem]'
                            date={editWidgetCompareRangeEnd}
                            setDate={setEditWidgetCompareRangeEnd}
                            disabled={editWidgetCompareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center gap-2 border-t border-secondaryStrokeButton px-4 py-3'>
                      <Button variant='tertiary' className='w-[6.125rem]' onClick={handleEditClearRangeDateFilter}>
                          Clear Filter
                      </Button>
                      <Button onClick={handleEditWidgetRangeDateFilter} className='w-[6.125rem]'>
                          Apply Filter
                      </Button>
                    </div>
                  </div>
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  );
}
