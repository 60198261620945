import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  CUSTOM_WIDGETS_ID,
  CUSTOM_WIDGETS_TAB_ID_SELECT,
  EDIT_DASHBOARD_BUTTON_ID,
  STATIC_WIDGETS_ID,
  WIDGETS_TAB_ID,
} from '@constants';

export const useAddWidgetTour = () => {
  const { createTour, createClickStep, createStep, closeTour, isTourActive } = useTour();

  const getAddWidgetTourSteps = () => [
    createClickStep(EDIT_DASHBOARD_BUTTON_ID, 'Edit Dashboard', 'Click here to open the widgets tab.'),
    createStep(WIDGETS_TAB_ID, 'Choose Tab', 'Click here to select static widget or custom type.'),
    createClickStep(CUSTOM_WIDGETS_TAB_ID_SELECT, 'Custom Widgets', 'Click here to open custom widgets', {
      showButtons: ['close', 'next'],
    }),
    createStep(
      CUSTOM_WIDGETS_ID,
      'Drag and Drop widgets',
      'Drag a widget from here to the left side to create a widget.',
      {
        showButtons: ['close', 'next'],
        onNextClick: () => {
          closeTour();
        },
      }
    ),
    createStep(
      STATIC_WIDGETS_ID,
      'Drag and Drop widgets',
      'Drag a widget from here to the left side to create a widget.',
      {
        showButtons: ['close', 'next'],
      }
    ),
  ];

  const addWidgetTour = useCallback(() => createTour(getAddWidgetTourSteps()), [createTour]);

  return {
    addWidgetTour,
    isTourActive,
  };
};
