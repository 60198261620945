import { generatePath, useNavigate } from 'react-router-dom';
import { Modal, Skeleton, Table } from 'antd';
import { isEmpty } from 'lodash';
import { Pen, X, SquarePen, Building2 } from 'lucide-react';
import {
  AddEditOrgDialog,
  CustomPagination,
  DeleteDialog,
  NoDataFoundScreen,
  TableSkeletonLoader,
} from '@common/components';
import { ROUTES } from '@routes';
import { capitalizeString, setLocalStorageItem } from '@utils';
import { SELECTED_ORGANIZATION_ID, ROLES, COLORS, TOTAL_ITEMS_PER_PAGE, HOME_ORGANIZATION_TABS } from '@constants';
import { EmptyInvitation } from '@assets/svgs';

export function InvitedOrganizationTab({
  organizations,
  page,
  setPage,
  organizationFetching,
  search,
  editFormik,
  imageRef,
  uploadedLogoEdit,
  setUploadedLogoEdit,
  setIsUploadedLogoEdit,
  isUploadedLogoEdit,
  isEditOrganizationLoading,
  selectedOrganization,
  setSelectedOrganization,
  showAddEditDialog,
  setShowAddEditDialog,
  showDeleteOrgDialog,
  setShowDeleteOrgDialog,
  handleDeleteBtn,
  handleDeleteOrganizationBtn,
  isDeleteOrgLoading,
  userDetail,
  manageOrganizationFetching,
  editHandleImageUpload,
  openEditDialogue,
  totalPagesPerItem,
  setTotalPagesPerItem,
  paginationPage,
  setPaginationPage,
  currentTab,
  editImageLoading,
}) {
  const navigate = useNavigate();

  const defaultOrganizationId = userDetail?.data?.defaultOrganization;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        organizationFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <span className='inline-block h-10 min-w-10'>
              {record?.logo?.image ? (
                <img src={record?.logo?.image} alt='dashboard' className='h-full w-full' />
              ) : (
                <Building2 color={COLORS.ICON_COLOR} className='h-full w-full' />
              )}
            </span>
            <p className='!text-bodyText'>{text}</p>
          </div>
        ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text => (organizationFetching ? text : capitalizeString(text)),
    },
    {
      title: 'Dashboards',
      dataIndex: 'dashboardsCount',
      key: 'dashboardsCount',
    },
    {
      title: 'Teams',
      dataIndex: 'teamCount',
      key: 'teamCount',
    },
    {
      title: 'Members',
      dataIndex: 'memberCount',
      key: 'memberCount',
    },
    {
      title: 'Data Sources',
      dataIndex: 'dataSourcesCount',
      key: 'dataSourcesCount',
    },
    {
      title: 'Action',
      dataIndex: 'actionCount',
      fixed: 'right',
      key: 'action',
      render: (_, record) =>
        organizationFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='flex gap-1.5'>
            <span
              onClick={e => {
                e.stopPropagation();
                setSelectedOrganization(record);
                setShowAddEditDialog({ ...showAddEditDialog, [record]: true });
              }}
              className={
                userDetail?.data?.isSubscribed && record.role === ROLES.ADMIN ? 'cursor-pointer' : 'pointer-events-none'
              }
            >
              <SquarePen
                color={
                  userDetail?.data?.isSubscribed && record.role === ROLES.ADMIN
                    ? COLORS.SECONDARY_GREEN_COLOR
                    : COLORS.BUTTON_DISABLED_COLOR
                }
                className='h-4 w-4'
              />
            </span>
          </div>
        ),
    },
  ];

  return (
    <div className='flex flex-col gap-6'>
      {organizationFetching ? (
        <div className='h-[calc(100vh-21.125rem)] min-h-[23.25rem] overflow-auto rounded-b-xl md:h-[calc(100vh-28.875rem)] lg:h-[calc(100vh-34.3125rem)]'>
          <TableSkeletonLoader columns={columns} rowCount={12} />
        </div>
      ) : search && organizations?.data?.count === 0 ? (
        <div className='h-[calc(100vh-21.125rem)] min-h-[23.25rem] overflow-auto md:h-[calc(100vh-28.875rem)] lg:h-[calc(100vh-34.3125rem)]'>
          <NoDataFoundScreen message="Sorry, we couldn't find any matches. Try adjusting your search criteria or explore other categories." />
        </div>
      ) : organizations?.data?.results.length === 0 ? (
        <div className='flex h-[calc(100vh-21.125rem)] min-h-[23.25rem] flex-col items-center gap-5 overflow-auto py-[6.375rem] md:h-[calc(100vh-28.875rem)] lg:h-[calc(100vh-34.3125rem)]'>
          <EmptyInvitation className='h-[6.25rem] w-[6.25rem]' />
          <div className='flex max-w-[15.25rem] flex-col items-center gap-2 md:max-w-[25rem]'>
            <h4 className='font-Manrope text-lg font-semibold leading-[1.625rem] md:text-xl md:leading-normal'>
              No Invitations Received
            </h4>
            <p className='text-center font-Manrope text-xs font-normal leading-5 text-bodyText'>
              It looks like you haven’t received any invitations from organizations yet. Once you are invited to join an
              organization, you’ll see your invitations here.
            </p>
          </div>
        </div>
      ) : (
        <div className='flex h-full flex-col justify-between'>
          <Table
            columns={columns}
            dataSource={organizations?.data?.results}
            rowKey='id'
            rowClassName='cursor-pointer'
            className='table-thin-bold h-[calc(100vh-23.625rem)] min-h-[23.25rem] overflow-auto md:h-[calc(100vh-36.875rem)]'
            onRow={record => ({
              onClick: () => {
                setLocalStorageItem(SELECTED_ORGANIZATION_ID, record.organizationId);
                navigate(generatePath(ROUTES.DASHBOARD, { organizationId: record.organizationId }));
              },
            })}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            data={organizations}
            totalItems={totalPagesPerItem}
            setTotalItems={setTotalPagesPerItem}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
        </div>
      )}
      {organizations?.data?.count > TOTAL_ITEMS_PER_PAGE && (
        <div className='mb-8 lg:mb-0'>
          <CustomPagination page={page} setPage={setPage} data={organizations} />
        </div>
      )}
      {!isEmpty(selectedOrganization) &&
        selectedOrganization?.role === ROLES.ADMIN &&
        currentTab === HOME_ORGANIZATION_TABS.INVITED_ORGANIZATION && (
        <Modal
          open={showAddEditDialog[selectedOrganization]}
          onOpenChange={isOpen => {
            setShowAddEditDialog({ ...showAddEditDialog, [selectedOrganization]: isOpen });
            openEditDialogue();
          }}
          footer={null}
          width={420}
          closeIcon={<X color={COLORS.ICON_COLOR} />}
          centered={true}
          onCancel={() => {
            setShowAddEditDialog(false);
            setUploadedLogoEdit(false);
            setIsUploadedLogoEdit(false);
            editFormik.setFieldValue('logo', '');
          }}
        >
          <AddEditOrgDialog
            dialogTitle='Edit Organization'
            formik={editFormik}
            imageRef={imageRef}
            uploadedLogo={uploadedLogoEdit}
            handleImageUpload={editHandleImageUpload}
            setUploadedLogo={setUploadedLogoEdit}
            setIsUploadedLogo={setIsUploadedLogoEdit}
            icon={
              <div className='flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-highlighterText1'>
                <Pen className='h-2.5 w-2.5' color='white' />
              </div>
            }
            isUploadedLogo={isUploadedLogoEdit}
            handleDeleteBtn={handleDeleteBtn}
            organizationLoading={isEditOrganizationLoading}
            organizationId={selectedOrganization?.organizationId}
            defaultOrganizationId={defaultOrganizationId}
            manageOrganizationFetching={manageOrganizationFetching}
            isInvitedOrganization={true}
            imageLoading={editImageLoading}
          />
        </Modal>
      )}

      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        centered={true}
        open={showDeleteOrgDialog}
        onOpenChange={setShowDeleteOrgDialog}
        onCancel={() => setShowDeleteOrgDialog(false)}
      >
        <DeleteDialog
          heading={selectedOrganization?.name}
          text={selectedOrganization?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-4 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={handleDeleteOrganizationBtn}
          isLoading={isDeleteOrgLoading}
          goBackBtnClick={() => setShowDeleteOrgDialog(false)}
        />
      </Modal>
    </div>
  );
}
