import { useState } from 'react';
import { Drawer } from 'antd';
import { ArrowLeftToLine, Edit, Pen, Trash2 } from 'lucide-react';
import { Button, FormInput, ImageCrop, ImageUpload } from '@common/components';
import { DashboardTabsSection, EditWidgetsTabs } from '@pages/dashboard';
import { fileResize, handleSave } from '@utils';
import { COLORS, EDIT_WIDGET_TABS } from '@constants';
import { ImageUploaderIcon } from '@assets/svgs';

export function WidgetsSidebar({
  handleOnDrag,
  isEditMode,
  setIsEditMode,
  logoRef,
  uploadedLogo,
  isUploadedLogo,
  setIsUploadedLogo,
  handleLogoUpload,
  dashboardName,
  setDashboardName,
  setShowDeleteDialog,
  isEditWidgetMode,
  editWidget,
  setEditWidget,
  handleDateRangeChange,
  editWidgetDateRange,
  setEditWidgetDateRange,
  showDatePicker,
  setShowDatePicker,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetDateRangeSet,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeStart,
  setEditWidgetCompareRangeEnd,
  handleEditWidgetRangeDateFilter,
  widgetDimension,
  widgetDimensionRefetch,
  handleCommentUpdate,
  setShowAddDataSourceDialog,
  setSelectedDataSourceIds,
  dashboard,
  editWidgetCurrentTab,
  setEditWidgetCurrentTab,
  setIsEditWidgetMode,
  widgetDimensionFetching,
}) {
  const [croppedImage, setCroppedImage] = useState();
  const [activeTab, setActiveTab] = useState(EDIT_WIDGET_TABS.GENERAL);

  const getFile = async file => {
    const resizedFile = await fileResize(file, 300, 300);
    setCroppedImage(resizedFile);
    setIsUploadedLogo(true);
    return resizedFile;
  };

  return (
    <>
      <Drawer
        title={null}
        width={450}
        className='widgetDrawer'
        mask={false}
        onClose={() => {
          setIsEditMode(false);
          setIsEditWidgetMode(false);
        }}
        open={isEditMode}
      >
        <div className='bg-tableHeaderBackground px-8 py-6'>
          <span
            className='mb-6 inline-flex cursor-pointer items-center gap-4 text-lg font-semibold leading-[1.625rem] text-mainText'
            onClick={() => {
              setIsEditMode(false);
              setIsEditWidgetMode(false);
            }}
          >
            <ArrowLeftToLine color={COLORS.ICON_COLOR} className='h-5 w-5' />
            Edit Dashboard
          </span>
          <div className='mb-6 flex items-center gap-4'>
            <ImageUpload
              id='logo'
              icon={
                <div className='flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-highlighterText1'>
                  <Pen className='h-2.5 w-2.5' color='white' />
                </div>
              }
              imageRef={logoRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='h-[2.875rem] w-[2.875rem] border-0'
              profileImage={uploadedLogo}
              handleChange={e => getFile(e.target.files[0])}
              showSkeletonLoader={isUploadedLogo}
              inputWrapperClassName='-right-1 bottom-0'
              avatarSkeletonSize={46}
              inputClassName='w-5 h-5'
            />
            <FormInput
              id='dashboardName'
              placeholder='Dashboard Name'
              value={dashboardName}
              className='w-full'
              handleChange={e => setDashboardName(e.target.value)}
            />
          </div>
          <div className='flex items-center gap-3'>
            <Button
              size='lg'
              onClick={() => {
                setShowAddDataSourceDialog(true);
                setSelectedDataSourceIds(dashboard?.data?.dataSources);
              }}
            >
              <Edit color='#fff' className='h-5 w-5' />
              Edit Data Source
            </Button>
            <Button
              size='lg'
              className='text-errorTooltip'
              variant='tertiary'
              onClick={() => setShowDeleteDialog(true)}
            >
              <Trash2 color={COLORS.ERROR_TOOLTIP} className='h-5 w-5' />
              Delete
            </Button>
          </div>
        </div>
        {isEditWidgetMode ? (
          <EditWidgetsTabs
            editWidget={editWidget}
            setEditWidget={setEditWidget}
            editWidgetDateRange={editWidgetDateRange}
            setEditWidgetDateRange={setEditWidgetDateRange}
            handleDateRangeChange={handleDateRangeChange}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
            handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
            handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
            editWidgetDateRangeStart={editWidgetDateRangeStart}
            editWidgetDateRangeEnd={editWidgetDateRangeEnd}
            editWidgetDateRangeSet={editWidgetDateRangeSet}
            editWidgetCompareRangeStart={editWidgetCompareRangeStart}
            editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
            editWidgetCompareRangeSet={editWidgetCompareRangeSet}
            setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
            setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
            setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
            setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
            handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
            widgetDimension={widgetDimension}
            widgetDimensionRefetch={widgetDimensionRefetch}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleCommentUpdate={handleCommentUpdate}
            editWidgetCurrentTab={editWidgetCurrentTab}
            setEditWidgetCurrentTab={setEditWidgetCurrentTab}
            widgetDimensionFetching={widgetDimensionFetching}
          />
        ) : (
          <div className='flex flex-col gap-3 px-8 py-6'>
            <h4 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText'>Widgets</h4>
            <DashboardTabsSection handleOnDrag={handleOnDrag} isEditMode={isEditMode} />
          </div>
        )}
      </Drawer>
      {isUploadedLogo && (
        <ImageCrop
          uploadedImage={croppedImage}
          handleCancel={() => {
            setIsUploadedLogo(false);
            logoRef.current.value = '';
          }}
          setIsUploaded={setIsUploadedLogo}
          handleSave={file => handleSave(file, handleLogoUpload)}
          imageRef={logoRef}
          isDialog
        />
      )}
    </>
  );
}
