import { Popover } from 'antd';
import { User, Users } from 'lucide-react';
import { COLORS } from '@constants';

export function TeamPopover({ dashboard }) {
  return (
    <div className='flex'>
      {dashboard?.teams?.slice(0, 3).map(team => (
        <Popover
          key={team?.id}
          content={
            <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[16.375rem] overflow-y-auto'>
              <ul className='flex flex-col'>
                {dashboard?.teams?.map(team => (
                  <li
                    onClick={e => e.stopPropagation()}
                    key={team?.id}
                    className='flex cursor-pointer items-center gap-1 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                  >
                    <div className='flex h-6 w-6 items-center justify-center rounded-full'>
                      <User className='h-4 w-4' color={COLORS.ICON_COLOR} />
                    </div>
                    <span className='text-bodyText'>{team?.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          }
          trigger='click'
        >
          <div
            onClick={e => e.stopPropagation()}
            className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-shade4'
            title={team?.name}
          >
            <Users className='h-4 w-4' color={COLORS.ICON_COLOR} />
          </div>
        </Popover>
      ))}

      {dashboard?.teams?.length === 0 && (
        <p className='font-Manrope text-sm leading-5 text-labelText'>No team associated</p>
      )}

      {dashboard?.teamCount > 3 && (
        <Popover
          content={
            <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[16.375rem] overflow-y-auto'>
              <ul className='flex flex-col gap-3'>
                {dashboard?.teams?.map(team => (
                  <li
                    onClick={e => e.stopPropagation()}
                    key={team?.id}
                    className='flex cursor-pointer items-center gap-1 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                  >
                    <div className='flex h-6 w-6 items-center justify-center rounded-full'>
                      <User className='h-4 w-4' color={COLORS.ICON_COLOR} />
                    </div>
                    <span className='text-bodyText'>{team?.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          }
          trigger='hover'
        >
          <div
            onClick={e => e.stopPropagation()}
            className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-shade3 text-xs leading-4 !text-highlighterText2'
          >
            +{dashboard?.teamCount - 3}
          </div>
        </Popover>
      )}
    </div>
  );
}
