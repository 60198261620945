import { useRef } from 'react';
import { Button, FormInput, FormSelect, Loader } from '@common/components';
import { doInfiniteScroll } from '@utils';

export function SaveTemplateDialog({
  isLoading,
  templateCategory,
  templateCategoryFetching,
  templateCategoryNextPage,
  templateCategoryHasNextPage,
  formik,
}) {
  const categoryRef = useRef();

  const { values, handleChange, setFieldValue, submitForm, touched, errors } = formik;

  const handleScroll = e =>
    doInfiniteScroll(e, templateCategoryHasNextPage, templateCategoryFetching, templateCategoryNextPage);

  return (
    <div className=''>
      <h3>
        <h2 className='mb-6 text-2xl font-bold leading-[2.125rem] text-mainText'>Save as Template</h2>
      </h3>
      <div className='flex flex-col gap-6'>
        <FormInput
          id='templateName'
          labelText='Name'
          placeholder='Enter template name'
          value={values?.templateName}
          handleChange={handleChange}
          showError={touched.templateName && errors.templateName}
        />
        <FormSelect
          id='category'
          labelText='Category'
          placeholder='Choose category'
          selectRef={categoryRef}
          value={values?.category?.id}
          options={templateCategory?.pages?.flatMap(page => page?.data?.results)}
          handleChange={value => {
            const selectedTemplate = templateCategory?.pages
              ?.flatMap(page => page?.data?.results)
              ?.find(category => category?.id === value);
            setFieldValue('category', selectedTemplate);
          }}
          triggerClassName={values.category ? '' : 'custom-select'}
          handleScroll={handleScroll}
          selectGroupClassName='h-[16rem] overflow-y-auto'
          showError={touched.category && errors.category}
        />
        <Button onClick={submitForm} size='lg' disabled={isLoading}>
          Save
          {isLoading ? <Loader /> : ''}
        </Button>
      </div>
    </div>
  );
}
