import { Plus } from 'lucide-react';
import { capitalizeSourceName } from '@utils';
import { COLORS, UPPERCASE_DATA_SOURCES } from '@constants';

export function AddConnectButton({
  handleConnectNewAccount,
  connectedDataSourceLoading,
  showNameField,
  nameField,
  dataSource,
  checkedOauthId,
}) {
  const getButtonText = (dataSource, showNameField) => {
    const nameFields = {
      [UPPERCASE_DATA_SOURCES.WOO_COMMERCE]: 'Store Name',
      [UPPERCASE_DATA_SOURCES.BING_ADS]: 'Account Name',
    };

    if (
      [UPPERCASE_DATA_SOURCES.WOO_COMMERCE, UPPERCASE_DATA_SOURCES.BING_ADS].includes(
        capitalizeSourceName(dataSource?.sourceName)
      )
    ) {
      return showNameField
        ? 'Connect New Account'
        : `Add ${nameFields[capitalizeSourceName(dataSource?.sourceName)] || 'Name'}`;
    }

    return 'Connect New Account';
  };

  return (
    <div
      className={`flex h-11 cursor-pointer items-center justify-center gap-2 rounded-b-lg border-t border-secondaryStrokeButton bg-shade1 font-Manrope text-sm leading-5 text-highlighterText2 ${connectedDataSourceLoading ? 'pointer-events-none opacity-[.6]' : (showNameField && !nameField) || !!checkedOauthId ? 'pointer-events-none opacity-[.6]' : ''}`}
      onClick={handleConnectNewAccount}
    >
      <Plus color={COLORS.SECONDARY_GREEN_COLOR} className='h-4 w-4' />
      {getButtonText(dataSource, showNameField)}
    </div>
  );
}
