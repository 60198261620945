import { Popover, Button } from 'antd';
import { Info } from 'lucide-react';
import { COLORS } from '@constants';

export function DomainInstruction({ parentClass, showDomainTabInstruction }) {
  const domainTabInstructions = [
    'To enable email white labeling, first add your email domain.',
    'After adding the domain, you\'ll get corresponding DNS entries.',
    'The domain will initially display a \'pending\' status, meaning it hasn\'t been verified yet.',
    'To complete the verification, add the provided DNS entries to your Domain CNAME records.',
    'Once the CNAME records are updated, click \'verify\' to confirm the domain\'s status.',
    'After verification, you can use any email associated with the domain to send analytics reports.',
    'For adding a custom email, check the organization white labeling section.',
  ];

  const domainWhiteLabellingInstructions = () => {
    return [
      'Create a \'NAME\' record using your desired hostname (eg clients.yourdomain.com)',
      `Point the CNAME to '${import.meta.env.VITE_ENV}.zendash.com'`,
      'Wait an hour or more for it to propagate',
      'To complete the verification, add the provided DNS entries to your Domain CNAME records.',
      'Once complete, accessing this domain should redirect to your client access area',
    ];
  };

  const instructions = showDomainTabInstruction ? domainTabInstructions : domainWhiteLabellingInstructions();

  const content = (
    <div className='popover-content'>
      <p className='mb-2 text-base text-mainText'>
        Please follow these instructions to point your domain to the client access area:
      </p>
      <ul>
        {instructions?.map((instruction, index) => (
          <li key={index} className='text-sm text-mainText'>
            {index + 1}. {instruction}
          </li>
        ))}
      </ul>
      <p className='mt-2 text-base text-mainText'>
        {
          'Note: Cloudflare routing is incompatible with custom domains. Please disable Cloudflare for your custom domain if you\'re using this.'
        }
      </p>
    </div>
  );

  return (
    <div className={`absolute -top-1.5 left-12 ${parentClass}`}>
      <Popover content={content} trigger='click' placement='topLeft' overlayClassName='domain-popover'>
        <Button type='text' icon={<Info className='h-4 w-4' color={COLORS.PRIMARY_GREEN_COLOR} />} />
      </Popover>
    </div>
  );
}
