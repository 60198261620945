import { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ChevronLeft, ChevronRight, Headset } from 'lucide-react';
import { OrganizationsNames, SidebarFooter, SidebarContent } from '@common/components';
import { useOutsideClick } from '@common/hooks';
import { useCreateOrganization, useManageOrganization } from '@pages/manageOrganization';
import { useMembershipTab } from '@pages/profile';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import {
  clearLocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
  showSubscriptionText,
  showUpgradeBtn,
} from '@utils';
import { COLORS, INDIVIDUAL_PLAN } from '@constants';
import { ClosedSidebarIcon, ZendashLogo } from '@assets/svgs';

export function Sidebar() {
  const {
    organizationListing,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    switcherSelectedOrganization,
    handleSelectedOrganization,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    organizationListingLoading,
    handleImageUpload,
    createOrganizationLoading,
  } = useCreateOrganization();

  const { manageOrganization } = useManageOrganization();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [orgDropdown, setOrgDropdown] = useState(false);
  const [visible, setVisible] = useState(false);

  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  useOutsideClick(sidebarRef, () => setOpenSidebar(false), [menuRef]);
  useOutsideClick(dropdownRef, () => setOrgDropdown(false));

  const { membershipPlan, calculateTrialDays } = useMembershipTab();

  const { data: userDetail, isLoading: userDetailFetching } = useGetQuery(
    'user-detail-sidebar',
    apiEndpoints.USER_DETAIL
  );

  const consumePercentage = useMemo(() => {
    const { consumedTokens = 0, totalTokens = 0, additionalTokens = 0 } = userDetail?.data?.tokens || {};
    const totalAvailableTokens = totalTokens + additionalTokens;
    const percentage = totalAvailableTokens > 0 ? (consumedTokens * 100) / totalAvailableTokens : 0;
    return parseFloat(percentage?.toFixed(2));
  }, [userDetail?.data?.tokens]);

  useEffect(() => {
    let findMembershipPlan = membershipPlan?.data?.results?.find(m => m.isActiveMembership === true)?.nickname;
    if (membershipPlan && userDetail)
      setLocalStorageItem('showAiToken', userDetail?.data?.isSubscribed && findMembershipPlan !== INDIVIDUAL_PLAN);
  }, [membershipPlan, userDetail]);

  const showAiToken = getLocalStorageItem('showAiToken');

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const closePopover = () => {
    setVisible(false);
    setOpenSidebar(false);
  };

  const handleLogout = () => {
    clearLocalStorageItem();
    window.location.reload();
  };

  return (
    <div
      ref={sidebarRef}
      className={`fixed left-0 top-0 z-[110] flex h-screen min-h-screen flex-col justify-between border-r border-secondaryStrokeButton bg-white scrollbar-none ${openSidebar ? 'w-[17.5rem] ' : 'w-[4.75rem]'} transition-all duration-150`}
    >
      <div className='absolute -right-3 top-[3.75rem] z-20 cursor-pointer'>
        <div
          className='flex h-6 w-6 items-center justify-center rounded-full border border-secondaryStrokeButton bg-white'
          onClick={() => {
            setOrgDropdown(false);
            setOpenSidebar(!openSidebar);
          }}
        >
          {openSidebar ? (
            <ChevronLeft className='h-4 w-5' color={COLORS.ICON_COLOR} />
          ) : (
            <ChevronRight className='h-4 w-5' color={COLORS.ICON_COLOR} />
          )}
        </div>
      </div>
      <div className='relative flex h-full flex-col justify-between overflow-y-scroll scrollbar-none'>
        <div>
          <div
            className={`mx-auto flex py-5 ${openSidebar ? 'sticky top-0 z-10 w-[17.5rem] border-b border-secondaryStrokeButton bg-white px-6' : 'w-14 justify-center px-4'}`}
          >
            <Link to={ROUTES.HOME}>{openSidebar ? <ZendashLogo className='h-8' /> : <ClosedSidebarIcon />}</Link>
          </div>
          <OrganizationsNames
            dropdownRef={dropdownRef}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            orgDropdown={orgDropdown}
            setOrgDropdown={setOrgDropdown}
            organizationListingLoading={organizationListingLoading}
            switcherSelectedOrganization={switcherSelectedOrganization}
            handleSelectedOrganization={handleSelectedOrganization}
            organizationListing={organizationListing}
            showAiToken={showAiToken}
            setShowAddEditOrgDialog={setShowAddEditOrgDialog}
            createOrganizationLoading={createOrganizationLoading}
            formik={formik}
            imageRef={imageRef}
            uploadedLogo={uploadedLogo}
            handleImageUpload={handleImageUpload}
            setUploadedLogo={setUploadedLogo}
            setIsUploadedLogo={setIsUploadedLogo}
            showAddEditOrgDialog={showAddEditOrgDialog}
            isUploadedLogo={isUploadedLogo}
          />
          <SidebarContent
            manageOrganization={manageOrganization}
            switcherSelectedOrganization={switcherSelectedOrganization}
            openSidebar={openSidebar}
          />
          <div className='flex flex-col gap-1 px-4 py-6'>
            <Link
              to=''
              title='Help Desk'
              className={` flex gap-3 whitespace-nowrap rounded-full p-3 hover:bg-shade4 ${openSidebar ? 'w-full' : 'h-11 w-11'}`}
            >
              <div className='h-5 w-5 [&>svg]:h-5 [&>svg]:w-5 [&>svg]:stroke-icon1'>
                {openSidebar ? (
                  <Headset />
                ) : (
                  <Tooltip
                    placement='right'
                    title={'Help Desk'}
                    color={COLORS.WHITE_COLOR}
                    overlayInnerStyle={{
                      color: 'black',
                    }}
                  >
                    <Headset />
                  </Tooltip>
                )}
              </div>
              <span className='font-Manrope text-sm font-medium leading-5'>{openSidebar && 'Help Desk'}</span>
            </Link>
          </div>
        </div>
        <SidebarFooter
          openSidebar={openSidebar}
          showAiToken={showAiToken}
          consumePercentage={consumePercentage}
          closePopover={closePopover}
          handleLogout={handleLogout}
          setVisible={setVisible}
          visible={visible}
          menuRef={menuRef}
          userDetail={userDetail}
          userDetailFetching={userDetailFetching}
          membershipPlan={membershipPlan}
          showSubscriptionText={showSubscriptionText}
          showUpgradeBtn={showUpgradeBtn}
          userOrganizationRole={userOrganizationRole}
          calculateTrialDays={calculateTrialDays}
        />
      </div>
    </div>
  );
}
