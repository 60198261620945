import { Popover } from 'antd';
import { CalendarDays, TrendingDown, TrendingUp } from 'lucide-react';
import moment from 'moment';
import { WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, DATE_FORMAT, DATE_RANGE_DROP_DOWN, DEFAULT_VALUE, THEME_STYLE, WIDGETS_TYPE } from '@constants';

export function WidgetTopHead({
  widget,
  onRemoveClick,
  dashboardAccess,
  handleEditWidget,
  handleClone,
  className,
  titleText,
  widgetWrapper,
  widgetImage,
  accountName,
  handleEditCommentMode,
  hideTooltip,
  showGoalDialog,
  setShowGoalDialog,
  isSharedDashboard,
  handleGoalTracking = () => null,
  isCommentMode,
  setIsPopUpMenuOpen,
}) {
  const widgetContent = widget => {
    return (
      <div className='flex flex-col gap-2'>
        {widget?.comparisonDateRange ? (
          <>
            <div className='text-xs font-medium leading-4 text-bodyText'>{`${moment(widget?.startDate1).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)} - ${moment(widget?.endDate1).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)}`}</div>
            <div className='text-xs font-medium leading-4 text-bodyText'>{`${moment(widget?.startDate2).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)} - ${moment(widget?.endDate2).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)}`}</div>
          </>
        ) : (
          <div className='text-xs font-medium leading-4 text-bodyText'>{`${moment(widget?.startDate).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)} - ${moment(widget?.endDate).format(DATE_FORMAT.CUSTOM_DATE_FORMAT)}`}</div>
        )}
      </div>
    );
  };

  return (
    <div className={`mb-2 flex flex-col gap-1 ${className}`}>
      <div className='flex items-center justify-between gap-3'>
        {widget?.showLabel && (
          <WidgetTitle
            titleText={titleText}
            widget={widget}
            hideTooltip={hideTooltip}
            isSharedDashboard={isSharedDashboard}
          />
        )}

        {dashboardAccess && (
          <div>
            {widget?.widgetData?.trend === 1 && (
              <span className='trending-icon flex h-[2.625rem] min-w-[2.625rem] items-center justify-center rounded-lg bg-TrendBgColor'>
                <TrendingUp color={COLORS.PRIMARY_GREEN_COLOR} className='h-6 w-6' />
              </span>
            )}
            {widget?.widgetData?.trend === -1 && (
              <span className='trending-icon flex h-[2.625rem] min-w-[2.625rem] items-center justify-center rounded-lg bg-TrendBgColor'>
                <TrendingDown color={COLORS.ERROR_COLOR} className='h-6 w-6' />
              </span>
            )}
            <WidgetMenu
              widget={widget}
              handleEditWidget={handleEditWidget}
              handleClone={handleClone}
              onRemoveClick={onRemoveClick}
              handleEditCommentMode={() => handleEditCommentMode(widget)}
              isSharedDashboard={isSharedDashboard}
              handleGoalTrackingMode={() => {
                setShowGoalDialog({ ...showGoalDialog, [widget?.i]: true });
                handleGoalTracking();
              }}
              isCommentMode={isCommentMode}
              setIsPopUpMenuOpen={setIsPopUpMenuOpen}
            />
          </div>
        )}
      </div>
      <div className={`flex items-center gap-1 ${widgetWrapper}`}>
        {widget?.showIntegrationIcon && (
          <span className={`mr-1 h-3.5 w-3.5 ${widgetImage}`}>
            <img src={widget?.logo || widget?.connectors?.logo?.image} alt='dataSource' className='h-full w-full' />
          </span>
        )}
        <p
          className={`leading-3.5 inline-flex items-center text-[0.625rem] text-mainText text-primary ${accountName}`}
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
              : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
          }
        >
          {widget?.dataSourceName || widget?.connectors?.sourceName}
          {widget?.connectorName !== WIDGETS_TYPE.GOAL &&
            !!DATE_RANGE_DROP_DOWN?.find(v => v.id === widget?.dateRange)?.name && (
            <span
              className={`ml-2 inline-flex gap-0.5 border-l border-l-secondaryStrokeButton pl-2 text-icon1 ${accountName}`}
              style={
                widget?.themeColor === DEFAULT_VALUE
                  ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                  : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.ICON_COLOR }
              }
            >
              <CalendarDays
                color={
                  widget?.themeColor === DEFAULT_VALUE
                    ? widget.themeStyle === THEME_STYLE.DARK
                      ? COLORS.WHITE_COLOR
                      : COLORS.DARK_SECONDARY
                    : widget.themeStyle === THEME_STYLE.DARK
                      ? COLORS.WHITE_COLOR
                      : COLORS.ICON_COLOR
                }
                className={`h-3.5 w-3.5 ${widgetImage}`}
              />{' '}
              {DATE_RANGE_DROP_DOWN?.find(v => v.id === widget?.dateRange)?.name === 'Custom' ? (
                <Popover
                  placement='topLeft'
                  overlayClassName='widget-popover'
                  className='cursor-pointer'
                  content={() => widgetContent(widget)}
                  arrow={true}
                  title={false}
                >
                  <span>{`(${DATE_RANGE_DROP_DOWN?.find(v => v.id === widget?.dateRange)?.name})`}</span>
                </Popover>
              ) : (
                `(${DATE_RANGE_DROP_DOWN?.find(v => v.id === widget?.dateRange)?.name})`
              )}
            </span>
          )}
        </p>
      </div>
    </div>
  );
}
