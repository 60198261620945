import { Tabs } from 'antd';
import { DataTab, DisplayTab, GeneralTab } from '@pages/dashboard';
import { EDIT_WIDGET_TABS } from '@constants';

export function EditWidgetsTabs({
  editWidget,
  setEditWidget,
  editWidgetDateRange,
  setEditWidgetDateRange,
  handleDateRangeChange,
  showDatePicker,
  setShowDatePicker,
  handleSelectEditWidgetDateRange,
  handleSelectEditWidgetCompareRange,
  handleEditClearRangeDateFilter,
  editWidgetDateRangeStart,
  editWidgetDateRangeEnd,
  editWidgetDateRangeSet,
  editWidgetCompareRangeStart,
  editWidgetCompareRangeEnd,
  editWidgetCompareRangeSet,
  setEditWidgetDateRangeStart,
  setEditWidgetDateRangeEnd,
  setEditWidgetCompareRangeStart,
  setEditWidgetCompareRangeEnd,
  handleEditWidgetRangeDateFilter,
  widgetDimension,
  widgetDimensionRefetch,
  handleCommentUpdate,
  editWidgetCurrentTab,
  setEditWidgetCurrentTab,
}) {
  return (
    <Tabs
      className={`custom-tabs ${!editWidget?.table ? 'edit-widget-two-tabs' : 'edit-widget-tabs'} px-8 py-6`}
      popupClassName='mb-6'
      activeKey={editWidgetCurrentTab}
      onChange={value => {
        value === EDIT_WIDGET_TABS.DATA && editWidget?.connectorId && !!editWidget?.table && widgetDimensionRefetch();
        setEditWidgetCurrentTab(value);
      }}
      items={[
        {
          label: 'General',
          key: EDIT_WIDGET_TABS.GENERAL,
          children: (
            <GeneralTab
              editWidget={editWidget}
              setEditWidget={setEditWidget}
              editWidgetDateRange={editWidgetDateRange}
              setEditWidgetDateRange={setEditWidgetDateRange}
              handleDateRangeChange={handleDateRangeChange}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
              handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
              handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
              editWidgetDateRangeStart={editWidgetDateRangeStart}
              editWidgetDateRangeEnd={editWidgetDateRangeEnd}
              editWidgetDateRangeSet={editWidgetDateRangeSet}
              editWidgetCompareRangeStart={editWidgetCompareRangeStart}
              editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
              editWidgetCompareRangeSet={editWidgetCompareRangeSet}
              setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
              setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
              setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
              setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
              handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
              handleCommentUpdate={handleCommentUpdate}
            />
          ),
        },
        {
          label: 'Data',
          key: EDIT_WIDGET_TABS.DATA,
          children: <DataTab editWidget={editWidget} setEditWidget={setEditWidget} widgetDimension={widgetDimension} />,
          disabled: !editWidget?.table,
        },
        {
          label: 'Display',
          key: EDIT_WIDGET_TABS.DISPLAY,
          children: <DisplayTab editWidget={editWidget} setEditWidget={setEditWidget} />,
        },
      ].filter(tab => (!editWidget?.table ? tab.key !== EDIT_WIDGET_TABS.DATA : tab))}
    />
  );
}
