import { useNavigate } from 'react-router-dom';
import { Switch } from 'antd';
import { ArrowUpRight } from 'lucide-react';
import {
  BaseLoader,
  Button,
  ColorPicker,
  FormInput,
  FormSelect,
  ImageCrop,
  Loader,
  LogoUpload,
  WhiteLabelPreview,
} from '@common/components';
import { DomainInstruction, useWhiteLabel } from '@pages/whiteLabel';
import { getFile, getLocalStorageItem, handleSave, normalizeOptionsArray } from '@utils';
import { COLORS } from '@constants';
export function WhiteLabel() {
  const {
    whiteLabelFetching,
    formik,
    mainLogoRef,
    sponsorLogoRef,
    mainLogoUpload,
    sponsorLogoUpload,
    setMainLogoUploaded,
    setSponsorLogoUploaded,
    handleWhiteLabelUpdateLoading,
    mainLogoUploaded,
    sponsorLogoUploaded,
    handleMainLogoUpload,
    handleSponsorLogoUpload,
    verifiedEmailDomain,
    mainLogoLoading,
    sponsorLogoLoading,
  } = useWhiteLabel();

  const navigate = useNavigate();

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');
  const { initialValues, values, handleChange, touched, errors, submitForm, setFieldValue, dirty } = formik;

  const isActionDisabled = handleWhiteLabelUpdateLoading;

  return whiteLabelFetching ? (
    <BaseLoader />
  ) : (
    <div className='p-8'>
      <div className='relative flex gap-10 overflow-y-auto rounded-xl border-secondaryStrokeButton bg-white p-12'>
        <div className='flex-[0_0_40%]'>
          <div className='flex flex-col'>
            <div className='mb-10 flex gap-6'>
              <LogoUpload
                logo={values.mainLogo?.image || mainLogoUpload?.image || values?.organizationLogo?.image}
                placeholder={<p className='text-sm'>Upload Logo</p>}
                labelText='Main Logo'
                imgRef={mainLogoRef}
                handleChange={e => getFile(e.target.files[0], 'mainLogo', setFieldValue, setMainLogoUploaded)}
                sizeCount='Max Size: 1MB'
              />
              <LogoUpload
                logo={values.sponsorLogo?.image || sponsorLogoUpload?.image}
                placeholder={<p className='text-sm'>Upload Logo</p>}
                labelText='Sponsored Logo'
                imgRef={sponsorLogoRef}
                handleChange={e => getFile(e.target.files[0], 'sponsorLogo', setFieldValue, setSponsorLogoUploaded)}
                sizeCount='Max Size: 1MB'
              />
            </div>
            <div>
              <div className='mb-3 flex gap-6'>
                <ColorPicker
                  name='headerColor'
                  handleChange={handleChange}
                  componentColor={values.headerColor}
                  labelText='Background Color'
                  showError={touched.headerColor && errors.headerColor}
                />
                <ColorPicker
                  name='headerTextColor'
                  handleChange={handleChange}
                  componentColor={values.headerTextColor}
                  labelText='Text Color'
                  showError={touched.headerTextColor && errors.headerTextColor}
                />
              </div>
              <div className='flex items-center gap-3'>
                <label htmlFor='isTextureApplied' className='text-xs font-medium leading-4 text-bodyText'>
                  Apply Texture
                </label>
                <Switch
                  id='isTextureApplied'
                  checked={values.isTextureApplied ?? false}
                  onChange={checked => setFieldValue('isTextureApplied', checked)}
                  className={`h-5.5 w-11 ${values.isTextureApplied ? '!bg-highlighterText1' : '!bg-secondaryStrokeButton'}`}
                />
              </div>
            </div>
            <div className='relative my-10'>
              <FormInput
                value={values.domain}
                name='domain'
                handleChange={handleChange}
                labelText='Domain'
                labelClassName='leading-5 font-semibold text-mainText'
                placeholder='Enter domain'
                showError={touched.domain && errors.domain}
              />
              <DomainInstruction />
            </div>
            {userOrganizationRole === 'OWNER' && (
              <div className='relative flex flex-col gap-3 rounded-xl bg-shade1 p-8'>
                <label className='text-lg font-semibold leading-[1.625rem] text-mainText'>Add Custom Email</label>
                <p className='text-sm leading-5 text-bodyText'>
                  The email address you enter will act as the sender email for all communications. Ensure it’s correct,
                  as all emails will be sent from this email address.
                </p>
                <Button
                  variant='ghost'
                  className='absolute right-4 top-4 inline-flex h-7 w-fit gap-1.5 text-xs leading-4'
                  onClick={() => navigate('/settings/domain-tab')}
                >
                  Add new domain
                  <ArrowUpRight color={COLORS.SECONDARY_GREEN_COLOR} className='h-4 w-4' />
                </Button>
                <div className='flex gap-2'>
                  <div className='flex flex-1 flex-col gap-2'>
                    <FormInput
                      id='customEmail'
                      placeholder='Email username'
                      value={values.customEmail}
                      handleChange={e => setFieldValue('customEmail', e.target.value)}
                      showError={errors.customEmail}
                      disabled={verifiedEmailDomain?.data?.results?.length === 0 || values?.verifiedDomain === ''}
                    />
                  </div>
                  <FormSelect
                    id='verifiedDomain'
                    placeholder='domain.com'
                    optionPlaceholder='Not Found'
                    value={values?.verifiedDomain}
                    options={normalizeOptionsArray(verifiedEmailDomain?.data?.results, 'domain', 'domain')}
                    handleChange={value => setFieldValue('verifiedDomain', value)}
                    disabled={verifiedEmailDomain?.data?.results?.length === 0}
                    capitalizeOptions={false}
                  />
                </div>
              </div>
            )}
            <div className='mt-14 flex justify-end'>
              <Button size='lg' className='w-[15rem]' disabled={isActionDisabled || !dirty} onClick={submitForm}>
                Save
                {isActionDisabled && <Loader />}
              </Button>
            </div>
          </div>
          {mainLogoUploaded && (
            <ImageCrop
              uploadedImage={values?.mainLogo}
              handleCancel={() => {
                setMainLogoUploaded(false);
                setFieldValue('mainLogo', initialValues.mainLogo);
                mainLogoRef.current.value = '';
              }}
              handleCloseDialog={() => setFieldValue('mainLogo', initialValues.mainLogo)}
              setIsUploaded={setMainLogoUploaded}
              imageRef={mainLogoRef}
              handleSave={file => handleSave(file, handleMainLogoUpload)}
              imageLoading={mainLogoLoading}
              isDialog
            />
          )}
          {sponsorLogoUploaded && (
            <ImageCrop
              cropShape='rect'
              uploadedImage={values?.sponsorLogo}
              handleCancel={() => {
                setSponsorLogoUploaded(false);
                setFieldValue('sponsorLogo', initialValues.sponsorLogo);
                sponsorLogoRef.current.value = '';
              }}
              handleCloseDialog={() => setFieldValue('sponsorLogo', initialValues.sponsorLogo)}
              setIsUploaded={setSponsorLogoUploaded}
              imageRef={sponsorLogoRef}
              handleSave={file => handleSave(file, handleSponsorLogoUpload)}
              imageLoading={sponsorLogoLoading}
              isDialog
            />
          )}
        </div>
        <div className='relative flex flex-1 items-center overflow-y-hidden rounded-xl bg-secondaryStrokeButton px-4 py-12'>
          <WhiteLabelPreview
            logo={values.mainLogo?.image || mainLogoUpload?.image || values.organizationLogo?.image}
            sponsorLogo={values.sponsorLogo?.image || sponsorLogoUpload?.image}
            headerTextColor={values.headerTextColor}
            headerColor={values.headerColor}
            isTextureApplied={values.isTextureApplied}
          />
        </div>
      </div>
    </div>
  );
}
