import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import { toast } from 'react-toastify';

import {
  CREATE_DASHBOARD_BUTTON_ID,
  CREATE_DASHBOARD_DATA_SOURCE_ID,
  CREATE_DASHBOARD_LOGO_ID,
  CREATE_DASHBOARD_LOGO_TYPE_ID,
  CREATE_DASHBOARD_LOGO_UPLOAD,
  CREATE_DASHBOARD_MODE_TYPE_ID,
  CREATE_DASHBOARD_NAME_ID,
  CREATE_DASHBOARD_STEP1_BUTTON,
  CREATE_DASHBOARD_TYPE_ID,
  SAVE_BTN_ID,
  SYSTEM_LOGO_ID,
} from '@constants';

export const useCreateDashboardTour = () => {
  const { createTour, createStep, createClickStep, moveToNextStep, setIsTourActive, isTourActive } = useTour();

  const getDashboardTourSteps = () => [
    createClickStep(CREATE_DASHBOARD_BUTTON_ID, 'Create new dashboard', 'Click here to create new dashboard.'),
    createClickStep(
      CREATE_DASHBOARD_LOGO_ID,
      'Upload dashboard logo',
      'Click the ' + ' icon to upload logo for the dashboard.'
    ),
    createStep(
      CREATE_DASHBOARD_LOGO_TYPE_ID,
      'Upload Logo',
      'You can upload the dashboard logo from your device or choose one from the system-generated options.',
      {
        showButtons: ['next', 'close'],
      }
    ),
    {
      ...createStep(CREATE_DASHBOARD_LOGO_UPLOAD, 'Upload Logo', 'Click here to upload the logo.'),
      onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
        safelyAddEventListener(element, 'change', e => {
          if (option?.state?.activeIndex === step.index && e.target.files && e.target.files.length > 0) {
            moveToNextStep();
          }
        });
      },
    },
    createStep(SAVE_BTN_ID, 'Adjust and Save Logo', 'Adjust the image as needed, then click "save" the logo.'),
    createClickStep(SYSTEM_LOGO_ID, 'Select System Logo', 'Select the system logo for your dashboard.'),
    createClickStep(CREATE_DASHBOARD_STEP1_BUTTON, 'Move to the next step'),
    {
      ...createStep(
        CREATE_DASHBOARD_NAME_ID,
        'Dashboard Name',
        'Enter a dashboard name. It must have at least 3 characters.',
        {
          showButtons: ['next', 'close'],
          onNextClick: element => {
            const inputField = element.querySelector('input');
            if (inputField) {
              const inputValue = inputField.value;
              if (inputValue.length >= 3) moveToNextStep();
              else toast.error('Invalid input: must be at least 3 characters');
            }
          },
        }
      ),
    },
    // createClickStep(CREATE_DASHBOARD_CURRENCY_ID, 'Select Currency', 'Select the currency for your dashboard.'),
    // createClickStep(
    //   CREATE_DASHBOARD_CURRENCY_DROPDOWN,
    //   'Select Currency',
    //   'Select the currency for your dashboard from here.'
    // ),
    createStep(CREATE_DASHBOARD_STEP1_BUTTON, 'Move to the next step'),
    createStep(CREATE_DASHBOARD_DATA_SOURCE_ID, 'Add Data Sources', 'Add the data sources you want on this dashboard.'),
    createStep(CREATE_DASHBOARD_STEP1_BUTTON, 'Move to the next step'),
    createClickStep(CREATE_DASHBOARD_TYPE_ID, 'Creation Mode', 'Choose one of the modes below for this dashboard.'),
    createClickStep(CREATE_DASHBOARD_STEP1_BUTTON, 'Move to the next step'),
    createClickStep(CREATE_DASHBOARD_MODE_TYPE_ID, 'Display Mode', 'Choose one of the modes below for this dashboard.'),
    createClickStep(CREATE_DASHBOARD_STEP1_BUTTON, 'Create', '', {
      showButtons: ['next', 'close'],
    }),
  ];

  const createDashboardTour = useCallback(
    () => createTour(getDashboardTourSteps()),
    [createTour, getDashboardTourSteps]
  );

  return {
    createDashboardTour,
    setIsTourActive,
    isTourActive,
  };
};
