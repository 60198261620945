import { Collapse } from 'antd';
import { GripVertical } from 'lucide-react';
import { BaseLoader, NoDataFoundScreen } from '@common/components';
import { useCustomWidgets } from '@pages/dashboard';
import { formatWidgetName } from '@utils';
import { COLORS, CUSTOM_WIDGETS_ID, CUSTOM_WIDGETS_TAB_ID_SELECT } from '@constants';

export function DashboardCustomWidgets({ handleOnDrag, isEditMode }) {
  const { customWidgets, customWidgetsFetching } = useCustomWidgets(isEditMode);

  if (customWidgetsFetching) {
    return (
      <div className='relative h-[calc(100vh-26rem)]'>
        <BaseLoader />
      </div>
    );
  }

  if (!customWidgets?.data?.results || customWidgets?.data?.results.length === 0) {
    return (
      <div className='text-center text-darkTextColor' id={CUSTOM_WIDGETS_ID}>
        <NoDataFoundScreen
          title='No custom widget available'
          message='It looks like there aren’t any widgets here at the moment.'
        />
      </div>
    );
  }

  const items = customWidgets?.data?.results?.map(customWidget => ({
    key: customWidget?.id,
    label: (
      <div className='flex items-center gap-2'>
        <div className='flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-shade3 p-2.5'>
          <img src={customWidget?.connector?.logo?.image} alt='Logo' />
        </div>
        <div className='flex flex-col'>
          <span className='text-sm font-bold leading-4 text-mainText'>{customWidget?.connector?.sourceName}</span>
          <span className='text-xs text-darkTextColor'>{customWidget?.name}</span>
        </div>
      </div>
    ),
    children: (
      <div className='flex flex-col gap-6 pb-6'>
        {customWidget?.dataSourceWidget?.map(({ id, widget }) => (
          <div
            key={id}
            className='flex cursor-pointer gap-6'
            draggable
            onDragStart={e => handleOnDrag(e, widget, customWidget)}
            id={CUSTOM_WIDGETS_ID}
          >
            <div className='flex items-center gap-4' id={CUSTOM_WIDGETS_ID}>
              <GripVertical color={COLORS.BUTTON_DISABLE} />
              <div className='flex items-center gap-3'>
                <span className='flex h-8 w-8 items-center justify-center'>
                  <img src={widget?.icon?.image} alt='Widget Icon' />
                </span>
                <div className='flex flex-col gap-0.5'>
                  <h3 className='text-sm font-semibold leading-5 '>{formatWidgetName(widget?.type)}</h3>
                  <p className='text-xs leading-4'>{widget?.label}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ),
  }));

  return (
    <div id={CUSTOM_WIDGETS_TAB_ID_SELECT}>
      <Collapse
        className='widget-accordion border-0 bg-white'
        items={items}
        accordion
        id={CUSTOM_WIDGETS_TAB_ID_SELECT}
      />
    </div>
  );
}
