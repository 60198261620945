import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { Loader } from '@common/components';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function PieChartWidget({ widget, isComparisonFilterApply, onSlideMode }) {
  const [chartAnimation, setChartAnimation] = useState(false);

  const labels = widget?.widgetData?.data?.map(xAxis => xAxis?.label);

  const data = widget?.widgetData?.data?.map(xAxis => xAxis?.data);

  const backgroundColors =
    widget?.themeColor !== DEFAULT_VALUE
      ? widget?.widgetData?.data
        ?.map((xAxis, index) => `${`${widget.foreground}${100 / (index + 1) - 1}`.slice(0, 9)}`)
        .reverse()
      : [
        'rgba(54, 162, 235)',
        'rgba(255, 99, 132)',
        'rgba(255, 206, 86)',
        'rgba(75, 192, 192)',
        'rgba(153, 102, 255)',
        'rgba(255, 159, 64)',
      ];

  const options = {
    chart: {
      type: 'pie',
      animations: {
        speed: 400,
      },
    },
    labels: labels,
    colors: backgroundColors,
    legend: {
      show: !onSlideMode,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 10,
      markers: {
        shape: 'circle',
      },
      labels: {
        colors:
          widget?.themeColor === DEFAULT_VALUE
            ? widget?.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : COLORS.DARK_SECONDARY
            : widget.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : COLORS.COLOR_PRIMARY,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        const label = w.globals.labels[seriesIndex];
        const value = series[seriesIndex];

        return `
          <div style="padding: 10px; color: #fff; background: #000; border-radius: 4px;">
            Label: ${label !== undefined ? label : 'N/A'}
            <br/>
            Value: ${value}
          </div>
        `;
      },
      followCursor: true,
    },
  };

  const series = data;

  const nullOptions = {
    ...options,
    labels: ['No Data'],
    colors: ['#D6D6DA'],
  };

  const nullSeries = [1];

  const widgetData = widget?.widgetData?.data;
  const hasValidData = !isEmpty(widgetData) && widgetData[0]?.label !== null;

  const allDataIsNull = widgetData?.every(item => item.data === null);

  const pieChartWidget = (chartOptions, chartSeries) => (
    <ReactApexChart options={chartOptions} series={chartSeries} type='pie' height='100%' />
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartAnimation(true);
    }, 1100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return chartAnimation ? (
    isEmpty(widgetData) ? (
      <EmptyWidget widget={widget} />
    ) : (
      <>
        {isComparisonFilterApply ? (
          <div className='flex h-full w-full flex-col items-center justify-between gap-1 overflow-y-auto scrollbar-none sm:flex-row'>
            <div className='flex h-full w-full items-center justify-center border-b-2 pb-2 sm:border-b-0 sm:border-r-2 sm:pb-0'>
              {pieChartWidget(options, series)}
            </div>
            <div className='flex h-full w-full items-center justify-center'>{pieChartWidget(options, series)}</div>
          </div>
        ) : (
          <div className='h-[590px]'>
            {pieChartWidget(
              hasValidData && !allDataIsNull ? options : nullOptions,
              hasValidData && !allDataIsNull ? series : nullSeries
            )}
          </div>
        )}
      </>
    )
  ) : (
    <div className='flex h-full w-full items-center justify-center'>
      <Loader className='h-8 w-8' strokeColorClass={COLORS.MAP_WIDGET_HOVER_COLOR} />
    </div>
  );
}
