import { useContext, useEffect, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Modal, Tabs } from 'antd';
import { MonitorCog, PenLine, X } from 'lucide-react';
import { AddEditOrgDialog, BaseSkeltonLoader, Button, DeleteDialog } from '@common/components';
import { InvitesTab, MembersTab, TeamsTab, useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';
import { AppContext } from '@useContext';
import { truncateString } from '@utils';
import { COLORS, MANAGE_ORGANIZATION_TABS, ROLES, SKELETON_TYPE } from '@constants';
import { EditIconWhite, OrganizationIcon } from '@assets/svgs';

export function ManageOrganization() {
  const [currentTab, setCurrentTab] = useState(MANAGE_ORGANIZATION_TABS.MEMBERS);

  const {
    manageOrganization,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    handleDeleteBtn,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    showDeleteOrganizationDialog,
    setShowDeleteOrganizationDialog,
    isUploadedLogo,
    manageOrganizationFetching,
    isEditOrganizationLoading,
    isDeleteOrgLoading,
    handleDeleteOrganizationBtn,
    userDetail,
    handleImageUpload,
    imageUploadLoading,
  } = useManageOrganization();

  const { isResponsive } = useContext(AppContext);

  const { organizationId } = useParams();
  const defaultOrganizationId = userDetail?.data?.defaultOrganization;
  const isMember = manageOrganization?.data?.userRole === ROLES.MEMBER;

  const data = [
    {
      label: 'Members',
      key: MANAGE_ORGANIZATION_TABS.MEMBERS,
      children: <MembersTab currentTab={currentTab} />,
    },
    {
      label: 'Teams',
      key: MANAGE_ORGANIZATION_TABS.TEAMS,
      children: <TeamsTab manageOrganization={manageOrganization} currentTab={currentTab} isMember={isMember} />,
    },
    {
      label: 'Invites',
      key: MANAGE_ORGANIZATION_TABS.INVITES,
      children: <InvitesTab currentTab={currentTab} />,
    },
  ];

  useEffect(() => {
    if (isMember) setCurrentTab(MANAGE_ORGANIZATION_TABS.TEAMS);
  }, [isMember]);

  return (
    <>
      <div className='flex items-center justify-between border-b border-secondaryStrokeButton bg-white px-8 py-4'>
        <div className='flex items-center gap-4'>
          <div className='h-12 w-12'>
            {manageOrganizationFetching ? (
              <div className='relative h-12 w-12'>
                <BaseSkeltonLoader type={SKELETON_TYPE.IMAGE_UPLOADER} width={48} heigth={48} />
              </div>
            ) : manageOrganization?.data?.logo?.image ? (
              <img
                src={manageOrganization?.data?.logo?.image}
                alt='organization-logo'
                className='h-full w-full rounded-full'
              />
            ) : (
              <OrganizationIcon className='h-full w-full bg-white p-1' />
            )}
          </div>
          {manageOrganizationFetching ? (
            <BaseSkeltonLoader type={SKELETON_TYPE.SINGLE_LINE} width={100} height={14} />
          ) : (
            <h2 className='text-lg font-semibold leading-[1.625rem] text-mainText' id='organizationName'>
              {truncateString(manageOrganization?.data?.name, isResponsive ? 15 : 60, 'text-darkSecondary')}
            </h2>
          )}
        </div>
        <div className='flex items-center gap-3'>
          {manageOrganization?.data?.userRole !== ROLES.MEMBER && (
            <>
              <Button
                variant='tertiary'
                size='lg'
                className='w-[12.5rem]'
                onClick={() => setShowAddEditOrgDialog(true)}
              >
                <PenLine className='h-5 w-5' color={COLORS.ICON_COLOR} />
                Edit Organization
              </Button>
              <Link to={generatePath(ROUTES.WHITE_LABEL, { organizationId })}>
                <Button variant='secondary' size='lg' className='w-[12.5rem]'>
                  <MonitorCog className='h-5 w-5' color='white' />
                  White Labelling
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
      <div className='h-[calc(100%-81px)] p-8'>
        <div className='relative'>
          <Tabs
            className='profile-tabs rounded-xl border border-secondaryStrokeButton  bg-white'
            activeKey={currentTab}
            onTabClick={value => {
              setCurrentTab(value);
            }}
            items={isMember ? data.filter(tab => tab.key === MANAGE_ORGANIZATION_TABS.TEAMS) : data}
          />
        </div>
      </div>

      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={showDeleteOrganizationDialog}
        onCancel={() => setShowDeleteOrganizationDialog(false)}
        centered={true}
      >
        <DeleteDialog
          text={manageOrganization?.data?.name}
          textClass='!mb-4'
          deleteBtnClick={handleDeleteOrganizationBtn}
          goBackBtnClick={() => setShowDeleteOrganizationDialog(false)}
          isLoading={isDeleteOrgLoading}
        />
      </Modal>

      {!manageOrganizationFetching && manageOrganization?.data?.userRole !== ROLES.MEMBER && (
        <Modal
          footer={null}
          width={420}
          closeIcon={<X color={COLORS.ICON_COLOR} />}
          open={showAddEditOrgDialog}
          onOpenChange={status => {
            setShowAddEditOrgDialog(status);
            setIsUploadedLogo(false);
          }}
          centered={true}
          onCancel={() => {
            setShowAddEditOrgDialog(false);
            setIsUploadedLogo(false);
            formik.setFieldValue('logo', formik.initialValues.logo);
          }}
        >
          <AddEditOrgDialog
            dialogTitle='Edit Organization'
            formik={formik}
            imageRef={imageRef}
            uploadedLogo={uploadedLogo}
            handleImageUpload={handleImageUpload}
            imageLoading={imageUploadLoading}
            setUploadedLogo={setUploadedLogo}
            setIsUploadedLogo={setIsUploadedLogo}
            icon={
              <div className='h-3.5 w-3.5 lg:h-2.5 lg:w-2.5 xxl:h-3.5 xxl:w-3.5'>
                <EditIconWhite />
              </div>
            }
            handleDeleteBtn={handleDeleteBtn}
            isUploadedLogo={isUploadedLogo}
            organizationLoading={isEditOrganizationLoading}
            organizationId={organizationId}
            defaultOrganizationId={defaultOrganizationId}
          />
        </Modal>
      )}
    </>
  );
}
