import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@constants';

export function CustomDatePicker({ value, handleChange, showError, disabled, scheduleClass, className }) {
  const disablePastDates = current => current && current < dayjs().startOf('day');
  return (
    <div className={`flex w-full flex-col gap-1.5 !rounded-full ${disabled ? 'disable-time' : ''} ${scheduleClass}`}>
      <div className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Date</div>
      <DatePicker
        value={value ? dayjs(value) : null}
        format={DATE_FORMAT.CUSTOM_DATE_FORMAT}
        placeholder='Select date'
        disabled={disabled}
        disabledDate={disablePastDates}
        onChange={(date, dateString) => handleChange(dateString)}
        className={`${className} hover:border hover:!border-highlighterText1 ${showError ? 'border border-errorColor' : ''} !rounded-full`}
        size='large'
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>{showError}</p>
      )}
    </div>
  );
}
