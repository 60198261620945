import { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCreateDashboardTour } from '@pages/dashboard/hooks/useCreateDashboardTour';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { ROUTES } from '@routes';
import { apiEndpoints, performGetRequest, useGetQuery, usePatchMutation, usePostMutation } from '@services';
import { AppContext } from '@useContext';
import { delay, doInfiniteScroll, getNextPage, setLocalStorageItem } from '@utils';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  SUCCESSFUL_MESSAGE,
  TEMPLATE_TAB,
} from '@constants';
import TemplateImage from '@assets/images/TemplateImage.png';

export function useCreateDashboard(open) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const imageRef = useRef(null);

  const { dashboardPage } = useContext(AppContext);

  const [currentStep, setCurrentStep] = useState(1);
  const [progressValue, setProgressValue] = useState(22.5);
  const [showCreateDashboardDialog, setShowCreateDashboardDialog] = useState(false);
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState([]);
  const [addedDataSource, setAddedDataSource] = useState([]);
  const [createdDashboardId, setCreatedDashboardId] = useState();
  const [selectDashboardType, setSelectDashboardType] = useState('');
  const [selectDashboardMode, setSelectDashboardMode] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploadedImage, setIsUploadedImage] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplateImg, setSelectedTemplateImg] = useState(TemplateImage);
  const [templateName, setTemplateName] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplateTab, setSelectedTemplateTab] = useState(TEMPLATE_TAB.SYSTEM_TEMPLATE);

  const handleBack = stepNumber => {
    if (stepNumber === 5) {
      setCurrentStep(currentStep - 2);
      setProgressValue(81.625);
    } else {
      setCurrentStep(currentStep - 1);
      setProgressValue(22.5);
    }
  };

  const dashboardInitialValues = {
    image: '',
    name: '',
    // currency: 'USD',
  };

  const dashboardValidationSchema = Yup.object({
    image: Yup.string().when({
      is: () => isEmpty(uploadedImage),
      then: rule => rule.required(FIELD_REQUIRED('Dashboard image')),
      otherwise: rule => rule.notRequired(),
    }),

    name: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Dashboard name'))
      .max(60, MAX_LENGTH_REQUIRED('Dashboard name'))
      .required(FIELD_REQUIRED('Dashboard name')),
    // currency: Yup.string().required(FIELD_REQUIRED('Currency')),
  });

  const handleSubmit = () => {
    let payload = {
      logo: uploadedImage?.id,
      name: values.name,
      // currency: values.currency,
    };
    handleCreateDashboard({ payload });
  };
  const { ...formik } = useFormikForm(dashboardInitialValues, handleSubmit, dashboardValidationSchema);

  const { values, resetForm, setErrors } = formik;
  const { createDashboardTour, isTourActive, setIsTourActive } = useCreateDashboardTour();

  const { mutate: handleImageUpload, isLoading: imageLoading } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    resp => {
      setUploadedImage(resp?.data);
      setIsUploadedImage(false);
      createDashboardTour().moveNext();
      createDashboardTour().moveNext();
      createDashboardTour().moveNext();
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : !isEmpty(data.image?.detail)
          ? toast.error(data.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedImage(false);
      resetForm();
    }
  );

  const { mutate: handleCreateDashboard, isLoading: createDashboardLoading } = usePostMutation(
    apiEndpoints.CREATE_DASHBOARDS(organizationId),
    async resp => {
      setCreatedDashboardId(resp?.data?.id);
      setCurrentStep(currentStep + 1);
      setProgressValue(51.625);
      setSelectDashboardType('custom');
      setSelectDashboardMode('NORMAL');
      resetForm();
      await delay(700);
      createDashboardTour().moveNext();
    },
    ({ response: { data } }) => {
      data?.detail && toast.error(data?.detail);
      setErrors({
        name: data.name[0] && data.name[0],
      });
      setErrors({
        domain: data.domain[0] && data.domain[0],
      });
      // setErrors({
      //   currency: data.currency[0] && data.currency[0],
      // });
    }
  );

  const { mutate: handleCreateTemplatedDashboard, isLoading: createTemplateDashboardLoading } = usePostMutation(
    apiEndpoints.CREATE_DASHBOARD_TEMPLATES(organizationId, selectedTemplateId),
    () => {
      setProgressValue(22.5);
      setShowCreateDashboardDialog(false);
      navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: createdDashboardId }));
      toast.success(SUCCESSFUL_MESSAGE('Dashboard created'));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
    }
  );

  const { refetch: dashboardsListingRefetch } = useGetQuery(
    'dashboards-listing',
    apiEndpoints.DASHBOARDS_LISTING(organizationId),
    { page: dashboardPage }
  );

  const { data: systemGeneratedImages } = useGetQuery(
    'system-generated-images',
    apiEndpoints.SYSTEM_GENERATED_LOGO,
    {},
    {
      enabled: open,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: addDataSourceListing } = useGetQuery(
    'dashboard-addDataSource-listing',
    apiEndpoints.DASHBOARD_DATA_SOURCE(organizationId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: currentStep === 2 }
  );

  const getTemplateCategory = ({ pageParam = 1 }) => performGetRequest(apiEndpoints.TEMPLATE_CATEGORY(pageParam));

  const {
    data: templateCategory,
    isFetching: templateCategoryFetching,
    fetchNextPage: templateCategoryNextPage,
    hasNextPage: templateCategoryHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['template-category'],
    queryFn: getTemplateCategory,
    getNextPageParam: getNextPage,
  });

  const getTemplateListing = ({ pageParam = 1 }) =>
    performGetRequest(
      apiEndpoints.DASHBOARD_TEMPLATES(
        organizationId,
        createdDashboardId,
        pageParam,
        templateName,
        selectedType,
        selectedCategory?.id,
        selectedTemplateTab === TEMPLATE_TAB.SYSTEM_TEMPLATE ? true : false
      )
    );

  const {
    data: templateListing,
    isFetching: templateListingFetching,
    refetch: templateListingRefetch,
    fetchNextPage: templateListingNextPage,
    hasNextPage: templateListingHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['template-listing'],
    enabled: currentStep === 3,
    queryFn: getTemplateListing,
    getNextPageParam: getNextPage,
    onError: ({ response: { data } }) => toast.error(data?.detail),
  });

  const handleScroll = e =>
    doInfiniteScroll(e, templateCategoryHasNextPage, templateListingFetching, templateListingNextPage);

  const handleCategoryScroll = e =>
    doInfiniteScroll(e, templateListingHasNextPage, templateCategoryFetching, templateCategoryNextPage);

  const handleSelectedDataSource = id => {
    setSelectedDataSourceIds(prevIds =>
      prevIds.includes(id) ? prevIds.filter(item => item !== id) : [...prevIds, id]
    );
  };

  const { mutate: handleAddDataSource, isLoading: addDataSourceLoading } = usePostMutation(
    apiEndpoints.ADD_DASHBOARD_DATA_SOURCE(organizationId, createdDashboardId),
    async () => {
      setCurrentStep(currentStep + 1);
      setProgressValue(81.625);
      await delay(100);
      createDashboardTour().moveNext();
    },
    ({ response: { data } }) =>
      toast.error(
        (data?.data_sources?.[0] && 'Please select any data source') || 'Something went wrong. Please try again.'
      )
  );

  const { mutate: handleDashboardUpdate, isLoading: dashboardUpdateLoading } = usePatchMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, createdDashboardId),
    () => {
      navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: createdDashboardId }));
      setLocalStorageItem('showEditMode', true);
      toast.success(SUCCESSFUL_MESSAGE('Dashboard created'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const handleCustomDashboardStep = () => {
    setCurrentStep(currentStep + 2);
    setProgressValue(90);
  };

  const createTemplateDashboardStep = () => {
    setCurrentStep(currentStep + 1);
    setProgressValue(100);
  };

  const handleSelectedTemplate = templateId => {
    const filteredData = templateListing?.pages[0]?.data?.results?.find(item => item.id === templateId);
    setSelectedTemplateImg(filteredData?.image?.image);
    setSelectedTemplateId(templateId);
  };

  const handleCreateDashboardStep = () => {
    resetForm();
    setUploadedImage(null);
    setSelectedDataSourceIds([]);
    setSelectDashboardType('');
    setSelectDashboardMode('');
    setSelectedTemplateId(null);
    setShowCreateDashboardDialog(true);
    setCurrentStep(1);
    setProgressValue(22.5);
  };

  useEffect(() => {
    const filteredData = addDataSourceListing?.data?.results?.filter(data => selectedDataSourceIds?.includes(data?.id));
    setAddedDataSource(filteredData);
  }, [selectedDataSourceIds]);

  useEffect(() => {
    currentStep === 4 && templateListingRefetch();
  }, [templateName, selectedType, selectedCategory, selectedTemplateTab]);

  const handleClearForm = () => {
    resetForm();
    setCurrentStep(1);
    setUploadedImage(null);
    setIsUploadedImage(null);
    setSelectedTemplateId(null);
    setCreatedDashboardId(null);
    setAddedDataSource([]);
    setSelectedDataSourceIds([]);
  };

  return {
    formik,
    currentStep,
    handleBack,
    progressValue,
    showCreateDashboardDialog,
    setShowCreateDashboardDialog,
    addDataSourceListing,
    handleSelectedDataSource,
    addedDataSource,
    handleAddDataSource,
    selectedDataSourceIds,
    selectDashboardType,
    selectDashboardMode,
    setSelectDashboardType,
    setSelectDashboardMode,
    handleDashboardUpdate,
    imageRef,
    uploadedImage,
    isUploadedImage,
    setIsUploadedImage,
    setUploadedImage,
    templateListing,
    templateListingFetching,
    templateListingRefetch,
    templateListingNextPage,
    templateListingHasNextPage,
    handleScroll,
    handleCategoryScroll,
    createTemplateDashboardStep,
    selectedTemplateId,
    handleSelectedTemplate,
    handleCreateTemplatedDashboard,
    createdDashboardId,
    createDashboardLoading,
    handleCreateDashboardStep,
    systemGeneratedImages,
    selectedTemplateImg,
    setTemplateName,
    handleImageUpload,
    dashboardsListingRefetch,
    selectedType,
    setSelectedType,
    handleCustomDashboardStep,
    selectedCategory,
    setSelectedCategory,
    templateCategory,
    selectedTemplateTab,
    setSelectedTemplateTab,
    createDashboardTour,
    handleClearForm,
    setIsTourActive,
    isTourActive,
    imageLoading,
    addDataSourceLoading,
    dashboardUpdateLoading,
    createTemplateDashboardLoading,
    setCurrentStep,
  };
}
