import { useState } from 'react';
import { NavLink, useParams, generatePath } from 'react-router-dom';
import { debounce } from 'lodash';
import { LayoutGrid, List, Plus, Search } from 'lucide-react';
import { Button, SearchInput } from '@common/components';
import { DataSourceGridView, DataSourceTabularView, useDataSource } from '@pages/dataSource';
import { ROUTES } from '@routes';
import { COLORS, DASHBOARD_VIEW_TYPE, DATA_SOURCE_NOT_CONNECTED } from '@constants';
import { DataSourceIntegrationIcon, EmptyDashboardScreenIcon } from '@assets/svgs';

export function DataSource() {
  const { organizationId } = useParams();
  const {
    connectedDataSource,
    showDeleteDialog,
    setShowDeleteDialog,
    handleDeleteDataSource,
    deleteDataSourceLoading,
    setDeleteDataSourceLoading,
    connectedDataSourceLoading,
    setSearch,
    search,
    dataSourcePage,
    setDataSourcePage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
  } = useDataSource();

  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  const [viewType, setViewType] = useState('grid');

  return (
    <div className='h-full p-8'>
      <div className='rounded-lg border border-secondaryStrokeButton bg-white'>
        <div className='flex h-[4.625rem] items-center justify-between border-b border-secondaryStrokeButton px-6 py-4'>
          <h3 className='font-Manrope text-lg font-semibold leading-[26px] text-mainText'>Connected Data Sources</h3>
          <div className='relative flex flex-col justify-end gap-6 md:flex-row lg:gap-4'>
            {(connectedDataSource?.data?.results.length > 0 || !!search) && (
              <>
                <SearchInput
                  onChange={handleSearchChange}
                  placeholder='Search'
                  className='pl-11'
                  leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                />
                <div className='ant-tabs-nav'>
                  <div className='ant-tabs-nav-list flex items-center'>
                    <div
                      onClick={() => {
                        setViewType('grid');
                      }}
                      className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.GRID ? 'ant-tabs-tab-active-left' : ''}`}
                    >
                      <div className='ant-tabs-tab-btn'>
                        <LayoutGrid
                          className='h-5 w-5'
                          color={viewType === DASHBOARD_VIEW_TYPE.GRID ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setViewType('table');
                      }}
                      className={`ant-tabs-tab cursor-pointer !px-4 ${viewType === DASHBOARD_VIEW_TYPE.TABLE ? 'ant-tabs-tab-active-right' : ''}`}
                    >
                      <div className='ant-tabs-tab-btn'>
                        <List
                          className='h-5 w-5'
                          color={viewType === DASHBOARD_VIEW_TYPE.TABLE ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <NavLink to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
                  <Button className='w-[10.75rem]'>
                    <Plus className='h-5 w-5' color='white' />
                    Add Data Source
                  </Button>
                </NavLink>
              </>
            )}
          </div>
        </div>
        {connectedDataSource?.data?.results.length === 0 ? (
          <div className='flex h-[calc(100vh-215px)] min-h-[23.25rem] flex-col items-center justify-center gap-6 overflow-y-auto rounded-b-xl scrollbar-none'>
            {search ? (
              <div className='flex flex-col items-center gap-10'>
                <EmptyDashboardScreenIcon />
                <div className='flex flex-col items-center gap-10'>
                  <div className='flex w-[25rem] flex-col items-center gap-3'>
                    <h3 className='font-Manrope text-2xl font-semibold text-mainText'>Invalid Search</h3>
                    <p className='text-center font-Manrope text-sm leading-5 text-bodyText'>
                      Please check your input and try again.
                    </p>
                  </div>
                  <Button className='w-[16.25rem]'>Submit Request for Integration</Button>
                </div>
              </div>
            ) : (
              <div className='flex flex-col items-center gap-10'>
                <DataSourceIntegrationIcon />
                <div className='flex flex-col items-center gap-10'>
                  <div className='flex w-[25rem] flex-col items-center gap-3'>
                    <h3 className='font-Manrope text-2xl font-semibold text-mainText'>Connect Data Sources</h3>
                    <p className='text-center font-Manrope text-sm leading-5 text-bodyText'>
                      {DATA_SOURCE_NOT_CONNECTED}
                    </p>
                  </div>
                  <NavLink to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
                    <Button className='w-[10.75rem]'>
                      <Plus className='h-5 w-5' color='white' />
                      Add Data Source
                    </Button>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='flex flex-col gap-8'>
            {viewType === DASHBOARD_VIEW_TYPE.TABLE && (
              <DataSourceTabularView
                connectedDataSourceFetching={connectedDataSourceLoading}
                connectedDataSource={connectedDataSource}
                handleDeleteDataSource={handleDeleteDataSource}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDataSourceLoading={deleteDataSourceLoading}
                setDeleteDataSourceLoading={setDeleteDataSourceLoading}
                page={dataSourcePage}
                setPage={setDataSourcePage}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            )}
            {viewType === DASHBOARD_VIEW_TYPE.GRID && (
              <DataSourceGridView
                connectedDataSourceFetching={connectedDataSourceLoading}
                connectedDataSource={connectedDataSource}
                handleDeleteDataSource={handleDeleteDataSource}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDataSourceLoading={deleteDataSourceLoading}
                setDeleteDataSourceLoading={setDeleteDataSourceLoading}
                page={dataSourcePage}
                setPage={setDataSourcePage}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            )}
            {/* TODO: Will be checked by Ayyan */}
            {/* {connectedDataSource?.data?.count > TOTAL_ITEMS_PER_PAGE && (
                    <CustomPagination page={dataSourcePage} setPage={setDataSourcePage} data={connectedDataSource} />
                  )} */}
          </div>
        )}
      </div>
    </div>
  );
}
