import { TimePicker } from 'antd';
import dayjs from 'dayjs';

export function CustomTimePicker({ value, handleChange, showError, disabled, scheduleClass, className }) {
  return (
    <div
      className={`flex h-11 w-full flex-col gap-1.5 !rounded-full ${disabled ? 'disable-time' : ''} ${scheduleClass}`}
    >
      <div className='text-sm font-medium leading-5 xxl:text-sm xxl:leading-5'>Time</div>
      <TimePicker
        value={value ? dayjs(value, 'h:mm A') : null}
        format='h:mm A'
        use12Hours={true}
        placeholder='Select time'
        disabled={disabled}
        onChange={(time, timeString) => handleChange(timeString)}
        className={`${className} hover:border hover:!border-highlighterText1 ${showError ? 'border border-errorColor' : ''} !rounded-full`}
        size='large'
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>{showError}</p>
      )}
    </div>
  );
}
