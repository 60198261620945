export function LogoUpload({ placeholder, labelText, logo, handleChange, imgRef, sizeCount }) {
  return (
    <div className='flex w-full flex-col gap-2'>
      <label className={'text-sm font-semibold leading-5 text-mainText'}>{labelText}</label>
      <div
        className={
          'relative flex h-[7.25rem] items-center justify-center rounded-lg border border-secondaryStrokeButton'
        }
      >
        <input
          type='file'
          accept='.jpg, .jpeg, .png'
          ref={imgRef}
          onChange={handleChange}
          className='absolute h-full w-full opacity-0'
        />
        {!logo && placeholder}
        {logo && (
          <div className='h-full w-full p-2'>
            <img src={logo} alt='Preview' className='h-full w-full object-contain' />
          </div>
        )}
      </div>
      <p className='mt-2 text-xs leading-4 text-bodyText'>{sizeCount}</p>
    </div>
  );
}
