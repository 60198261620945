import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { capitalizeString } from '@utils';

export function MultiSelect({
  labelText,
  placeholder,
  optionPlaceholder,
  options,
  editWidget,
  setEditWidget,
  disabled,
  ...restProps
}) {
  const [selected, setSelected] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState(options);

  const handleChange = values => setSelected(values);

  useEffect(() => {
    if (!isEmpty(selected)) {
      setEditWidget({ ...editWidget, metrics: selected });
    } else {
      setEditWidget({ ...editWidget, metrics: '' });
    }
    setDropDownOptions(() => options.filter(option => !selected.includes(option.id)));
  }, [selected]);

  useEffect(() => {
    setSelected(options.filter(option => editWidget?.metrics?.includes(option.id)).map(opt => opt.id));
  }, []);

  return (
    <div className={'relative flex flex-col gap-1.5'}>
      {!!labelText && <label className={'relative text-sm leading-5 text-labelText'}>{labelText}</label>}
      <Select
        mode='multiple'
        value={selected}
        onChange={handleChange}
        popupClassName='form-select-options p-2'
        className={`form-select ${selected?.length < 4 ? 'custom-select-height' : 'form-multi-select'}`}
        placeholder={placeholder}
        disabled={disabled}
        dropdownRender={menu => (
          <div>
            <div>
              {options.length === 0 ? (
                <div className='rounded-md bg-disableInput p-2 text-left text-sm leading-5 text-icon1'>
                  {optionPlaceholder || placeholder}
                </div>
              ) : (
                menu
              )}
            </div>
          </div>
        )}
        {...restProps}
      >
        {dropDownOptions?.map(option => (
          <Select.Option key={option?.id} value={option?.id}>
            <div className='!bg-[unset] p-3 text-left text-sm leading-5 text-bodyText hover:bg-shade4'>
              {capitalizeString(option?.name.replace('_', ' '))}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
